import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import SaveFab from "./SaveFab";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	loggingUser,
	toggleNotification,
	notificationMessage,
} from "../../actions/TestActions";
import { submitUserUpdate } from "../../../httpCalls/userHttp";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles(theme => ({
	button: {
		width: "150px",
		margin: theme.spacing(1)
	}
}))

function SaveSearchDialog(props) {
	const classes = useStyles();

	const { register, handleSubmit, reset, errors, control } = useForm({ searchName: "" })

	const [open, setOpen] = useState(false)


	const handleClickOpen = () => {
		setOpen(true)
	};

	const handleClose = () => {
		reset({ searchName: "" })
		setOpen(false)
	};



	function onSubmit(data) {
		let _user = { ...props.user };
		let searchbuilder = {};
		searchbuilder = { ...props.params };
		searchbuilder["labs"] = [...props.checkedlabs];
		let newSearch = {};
		newSearch[data.searchName] = searchbuilder;
		submitUserUpdate(newSearch).then(user => {
			if (user) {
				props.loggingUser(user);
				props.toggleNotification(true)
				props.notificationMessage(data.searchName + " is saved to your Acc.")
			}
		});
		handleClose();
	}


	if (props.user.uname) {
		return (
			<div>
				<SaveFab onClick={handleClickOpen} disable={props.search_mode} className={classes.button} />
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="form-dialog-title">
					<form method="post" onSubmit={handleSubmit(onSubmit)} id="save-search-form">
						<DialogTitle id="form-dialog-title">Save Search</DialogTitle>
						<DialogContent>
							<DialogContentText>
								You can save existing search, Name your search and press Save.
							</DialogContentText>
							<Controller
								name="searchName"
								control={control}
								defaultValue=""
								rules={{
									required: true,
									minLength: 2,
									pattern: /^[A-Za-z]+$/i
								}}
								render={({ field, fieldState }) => (
									<TextField
										{...field}
										autoFocus
										margin="dense"
										id="save-search"
										label="Save Search"
										type="text"
										error={!!fieldState.error}
										helperText={fieldState.error ? "Invalid name; avoid special characters and ensure more than two letters." : ""}
										fullWidth
									/>
								)}
							/>

						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} color="secondary">
								Cancel
							</Button>
							<Button type="submit" color="primary">
								Save
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</div>
		);
	} else {
		return <span />;
	}

}
const mapStateToProps = state => ({
	user: state.user,
	params: state.params,
	checkedlabs: state.checkedlabs,
	search_mode: state.search_mode
});
const mapDispatchProps = dispatch => {
	return bindActionCreators({ loggingUser }, dispatch);
};
export default connect(
	mapStateToProps,
	mapDispatchProps
)(SaveSearchDialog);
