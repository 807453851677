import React,{useState,useEffect,useLayoutEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleLabs } from "../../actions/LabActions";
import { addParams,toggleLoading } from "../../actions/TestActions";

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	}
  }));


let persistParams = {};
let persistLabs = [];

function checkForSearchs(user){
	if(user.searchs !== undefined){
		if(Object.keys(user.searchs).length >0){
			return true
		}
	}
	return false
}

function SearchTabs(props){
	const [value,setValue] = useState(0)
	const [searchName,setSearchName]= useState("current_search")
	const [showTabs,setShowTabs]=useState(false)

	useEffect(()=>{
		if(checkForSearchs(props.user)){
			setShowTabs(true)
		}else
		{
			setShowTabs(false)
		}

	},[props.user])

	function StateSetter(searchName,searchIndex){
		setValue(searchIndex)
		setSearchName(searchName)
	}

	function handleClick(searchName){		
		if (searchName === "current_search") {
			persistParams = { ...props.params };
			persistLabs = props.checkedLabs.length
				? [...props.checkedLabs]
				: [];
		} 
		let searchFields = { ...props.user.searchs[searchName] };
		let _params = { ...searchFields };
		delete _params.labs;
		let searchIndex=Object.keys(props.user.searchs).indexOf(searchName)+1;
		StateSetter(searchName,searchIndex)
		// updateTests(_params,searchFields.labs)
		props.addParams(_params);
		props.toggleLabs(searchFields.labs);
	
	}
	

	function currentSearch  (){
		props.addParams(persistParams);
		props.toggleLabs(persistLabs);
		let value = 0;
		let searchName = "current_search";
		StateSetter(searchName,value)
	};

	function renderTabs(){
		let tabs=[];
		if (showTabs && checkForSearchs(props.user) ){
			Object.keys(props.user.searchs).map((k)=>{
				tabs.push(<Tab key={k} label={k} onClick={ ()=>handleClick(k)} />)
			})
			return tabs
		}
			return <span/>;
	}

	function renderPreviousSearchs(){
		if(showTabs){
			return(
				<AppBar position="static" color="default">
							<Tabs
								value={value}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="auto">
								<Tab
									key={searchName}
									label={"current search"}
									onClick={currentSearch}
								/>
									{renderTabs()}
							</Tabs>
				</AppBar>
			)
		}
			return <span/>
		
		
	}


		const classes =useStyles();
		
			return (
				<div className={classes.root}>
					{renderPreviousSearchs()}
				</div>
			)
	
}




const mapStateToProps = state => ({
	user: state.user,
	params: state.params,
	checkedLabs: state.checkedlabs
});
const mapDispatchToProps = dispatch => {
	return bindActionCreators({ toggleLabs, addParams,toggleLoading }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchTabs);
