import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LabList from './labsList';

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

class LabDrawer extends React.Component {

  
  render() {
    const { classes } = this.props;
    return (
      <div>
     
        <Drawer open={this.props.flip} variant="temporary">
        <ClickAwayListener onClickAway={this.props.handleClose}>
            <LabList handleClose={this.props.handleClose} checked={this.props.checked}/>
            </ClickAwayListener>
        </Drawer>
       
      </div>
    );
  }
}

LabDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LabDrawer);
