import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Grid, Toolbar, Typography, NoSsr } from "@material-ui/core";
import Login from "./LoginForm";
import Menu from  "../menuLinks/linksMenu";
import HandbookMenu from "../menuLinks/handbookLinksMenu";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserAvatar from "../UserAvatar";
import HomeButton from "../HomeButton";
import { loggingUser } from 'actions/TestActions';
import { checkUserIsLoggedIn } from 'http_calls/userHttp'
import SearchBar from "./SearchBar";
import Loading from "../loading";
import OfflineModal from "../offlineModeDialog";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	justifyContent: {
		justifyContent: "space-between"
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20
	},
	labelFont: {
		color: theme.palette.common.white
	},
	appBar: {
		// zIndex: theme.zIndex.drawer + 1,
	}
}))

function notEmpty(user) {
	if (user.uname !== undefined) {
		if (Object.keys(user).length > 0) {
			return true
		}
	}
	return false
}

function getTitle() {
	if(__isBrowser__){	
		if(window.location.host.indexOf("midmichigan")> -1){
			return "";
		}
	}
	return "Lab Handbook";
}

function  getLogoWidth() {
	if(__isBrowser__){
	
		if(window.location.host.indexOf("midmichigan") > -1 ){
			
			return "400";
		}
	}
	return "60";
}

function getLogoHeight(){
	if(__isBrowser__){
		if(window.location.host.indexOf("midmichigan") > -1 ){
			return "50";
		}
	}
	return "40";
}

function NavBar(props) {
	const classes = useStyles();

	const [imageDimensions,setImageDimensions] = useState({"width":"60","height":"40"});
	const [title,setTitle] = useState("Lab Handbook");

	useEffect(()=>{
		if(__isBrowser__){
			if(window.location.host.indexOf("midmichigan") > -1 ){
				let _imageDimensions= {... imageDimensions};
				_imageDimensions.width= "400";
				_imageDimensions.height="50";
				setImageDimensions(_imageDimensions);
				setTitle("");
			}
		}
	},[])
	return (
		<div className={classes.root}>
			<AppBar color="primary" position="sticky" className={classes.appBar}>
				<Toolbar>
					<Grid
						container
						spacing={1}
						direction="row"
						justify="space-between"
						alignItems="center">
						<Grid item container xs={3} spacing={2} justify="flex-start" alignItems="center" direction="row">
							<Grid item >
								<HomeButton
									elem={
										<img
											alt="M Logo"
											src="/logo"
											width={imageDimensions.width}
											height={imageDimensions.height}
											style={{"margin":"1vh"}}
										/>
									}
								/>
							</Grid>
							<Grid item >
								<HomeButton
									elem={
										<Typography variant="h4" className={classes.labelFont}>
											{title}
										</Typography>
									}
								/>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							{props.showSearch ? <SearchBar /> : <span />}
						</Grid> 
						<Grid
							container
							item
							direction="row"
							justify="flex-end"
							alignItems="center"
							xs={3}
							spacing={3}
							>
							<Grid item xs={4}>
								<NoSsr>
									<Menu />
								</NoSsr>
							</Grid>
							<Grid item xs={4}>
								<NoSsr>
									<HandbookMenu />
								</NoSsr>
							</Grid>
							<Grid item xs={4}>
								{notEmpty(props.user) ? (
									<UserAvatar str={props.user.uname.charAt(0).toUpperCase()} />
								) : (
										<Login />
									)}
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<OfflineModal/>
		</div>
	);
}

NavBar.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchProps = dispatch => {
	return bindActionCreators({ loggingUser }, dispatch);
};



export default connect(
	mapStateToProps,
	mapDispatchProps
)(NavBar);
