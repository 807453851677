import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {
    usePopupState,
    bindTrigger,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import {  useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideLinks from '../index_components/SideLinks';
import { getLinks } from "http_calls/fetchLinks"
import { checkAccessForLayoutEdit } from "http_calls/userHttp"
import DraggableMenu from "./draggableMenu"
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { getAccess } from '../../../httpCalls/userHttp';
import { maxHeight } from '@material-ui/system';

const useStyles = makeStyles(theme => ({
    editButton: {
        margin: "10% 25%",
    },
    container: {
        width: "20vw",
        height :"60vh",
    },
    linksText :{
        color: theme.palette.common.white
    },
}));

const TriggerMenu = (props) => {
    const classes = useStyles()
    const [links, setLinks] = useState({ dynamicLinks: [], staticLinks: [] })
    const [hasAccess, setHasAccess] = useState(false)
    const [editView, setEditView] = useState(false)
    const popupState = usePopupState({ variant: 'popover', popupId: 'popoverId' })

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        let _links = { ...links }
        getLinks().then(res => {
           if (!res.msg.IsError){
            res.msg.Data.forEach(l => {
                if (l.linksName === "dynamicLinks") {
                    _links["dynamicLinks"] = l.linksData
                }
                if (l.linksName === "staticLinks") {
                    _links["staticLinks"] = l.linksData
                }
            });
            setLinks(_links)
           }
        }).catch(err => console.error(err))

        if (props.user.uname === undefined) {
            setHasAccess(false)
        } else {
            checkAccessForLayoutEdit().then(res => {
                setHasAccess(res.data.edit_page_layout)
            }).catch(err => {
                console.error(err)
            })
        }
    }, [, props.user, editView])

    return (
        <div>
            <Button variant={isMobile?"contained":"text"} color={isMobile?"primary":"default"} {...bindTrigger(popupState)} className={classes.linksText}>
              Handbook Links
      </Button>

            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.container}>
                    {hasAccess ? <Grid >
                        <center>
                            <Button variant="contained" color="primary" size="small" onClick={() => setEditView(!editView)} className={classes.editButton}>Toggle Edit Layout</Button>
                        </center>
                    </Grid> : <span />}
                    {editView ?
                        <Grid container direction={"column"} >
                            <Grid item justify="flex-start" alignItems="stretch">

                                <DndProvider backend={Backend}>
                                    <DraggableMenu links={links.dynamicLinks} name="dynamicLinks" />
                                </DndProvider>
                                
                            </Grid>

                        </Grid>
                        :
                        <Grid container direction="column" justify="flex-start" alignItems="center"> 
                            <Grid item > <SideLinks links={links.dynamicLinks} /></Grid>
                        </Grid>
                    }
                </div>
            </Popover>
        </div>
    )
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {

        }, dispatch
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TriggerMenu)