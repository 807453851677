import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    AppBar,
    Typography,
    Grid,
    Button,
    Toolbar,
    IconButton,
    Slide,
    TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    loggingUser,
    toggleNotification,
    notificationMessage,
    fieldTypes,
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import CategoryFields from "./categoryFields";
import {editFieldTypesCall} from "http_calls/fetchFuncs"

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        padding: 25,

    }

}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function EditFieldTypes(props){
    const classes=useStyles()

    const handleOpen = ()=>{
        setOpen(true)
    }

    useEffect(()=>{
    },[props.testFieldTypes])

    const handleFieldEdits = (k, obj)=>{
        editFieldTypesCall(`/test/field/types/edit/${k}`,obj).then(data=>{
            if(data.IsError===false){
                props.fieldTypes({...data.Data})

            }
        }).catch(err=>{console.error(err)})
    }
 
    return(
        <article>
            <Dialog fullScreen
                open={props.open}
                onClose={props.handleClose}
                TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h3" className={classes.title}>
                            Lab Handbook Edit field type change
                        </Typography>
                        <Button color="inherit" onClick={props.handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <section className={classes.container}>
                        <Grid container direction="column" justify="center" alignContent="center">
                            {Object.keys(props.categories).filter(c=>c!=="All").map(cat=>(
                                <Grid key={cat}>
                                    <Typography variant="h4"> <u>{cat}</u></Typography>
                                    <CategoryFields fields={props.categories[cat]} testFieldTypes={props.testFieldTypes} handleFieldEdits={handleFieldEdits} />
                                </Grid>
                            ))}
                        </Grid>
                    </section>

                </Dialog>
            </article>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
        testFieldTypes: state.test_field_types,
        categories:state.test_details_categories
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage,fieldTypes, },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(EditFieldTypes)