import React,{useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import {
	toggleNotification,
	notificationMessage
} from "../actions/TestActions";
import CloseIcon from "@material-ui/icons/Close";
import { bindActionCreators } from "redux";

const useStyles = makeStyles(theme => ({
	close: {
		padding: theme.spacing(0.5)
	}
}));

function NotificationSnackbar(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [msg,setMsg] = useState("")

	function handleClose(event, reason) {
		if (reason === "clickaway") {
			return;
		}

		props.toggleNotification(false);
	}

	useEffect(()=>{
		setOpen(props.notificationOpen)
		setMsg(props.notifyMsg)
	}, [props.notificationOpen,props.notifyMsg])

	return (
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				variant="success"
				ContentProps={{
					"aria-describedby": "message-id"
				}}
				message={<span id="message-id">{msg}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						className={classes.close}
						onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				]}
			/>
	);
}

const mapStateToProps = state => ({
	notificationOpen: state.notificationOpen,
	notifyMsg: state.notificationMessage
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{ toggleNotification, notificationMessage },
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotificationSnackbar);
