import React,{useState,useEffect} from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    IconButton,
    Button,
    Grid
  } from '@material-ui/core';
  import PlusIcon from '@material-ui/icons/Add';
  import SendIcon from '@material-ui/icons/Send';
  import { updateSectionsAndLabs , addSectionAPI, addLabAPI } from "http_calls/fetchFuncs"

  export default function (props){
    const [addDialog,setAddDialog] = useState(false)
    const [newLab,setNewLab] = useState("")
    useEffect(()=>{
        return ()=>{
            setNewLab("")
        }
    },[])
    const addNewLab =()=>{
        addLabAPI(newLab).then(res=>{
            props.setLabs({...res.Data})
        }).catch(err=>console.error(err))
    }

    const handleLabChange = (e)=>{
        setNewLab(e.target.value)
    }
    const handleClose = ()=>{
        setAddDialog(false);
    }
    const handleOpen = ()=>{
        setAddDialog(true)
    }
    return (
        <div>
            
             <Button variant="contained" size="large" color="primary" onClick={handleOpen}> <PlusIcon/> Add New Lab</Button>
             <Dialog open={addDialog} onClose={handleClose}>
             <DialogTitle >Add new Lab and hit Confirm </DialogTitle>
                <DialogContent >
                    <Grid container direction="row" spacing={1} justify="center" alignContent="center">
                        <Grid item xs={12}>
                             <TextField value={newLab} label={"Lab"} placeholder={"Enter new Lab Name "} variant="outlined" onChange={handleLabChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={addNewLab} disabled={newLab.length< 2}> confirm</Button>
                            <Button color="secondary" onClick={handleClose}> close</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
             </Dialog>
        </div>
    )
  }