import { ObjHasKey } from "helperFunctions"

const processTestObj = (test, formatter, fieldsInfo) => {
	let allowedKeys = Object.keys(formatter);
	let formattedTest = {};
	allowedKeys.forEach(k => {
		if (test.hasOwnProperty(k)) {
			formattedTest[formatter[k]] = test[k];
		} else {
			if(fieldsInfo[k]=== undefined){
				return;
			}
			if (fieldsInfo[k].hasOwnProperty("defaultValue")) {
				formattedTest[formatter[k]] = fieldsInfo[k].defaultValue.toString()
			} else if (fieldsInfo[k].hasOwnProperty("defaultText")) {
				formattedTest[formatter[k]] = fieldsInfo[k].defaultText
			}
			else {
				formattedTest[formatter[k]] = ""
			}

		}
	});
	return formattedTest;
};
export default processTestObj;
