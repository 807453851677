const defaultState = {
	user: {},
	tests: [],
	labs: [],
	checkedlabs: [],
	params: {
		start: 0,
		limit: 10,
	},
	loading_bool: false,
	notificationOpen: false,
	notificationMessage: "",
	draft: { "Synonyms": [] },
	test_details: {},
	num_records: 0,
	search_mode: false,
	search_text:"",
	test_details_categories: {},
	test_field_types: {},
	draftName: "",
	theme: {},
	containers: [],
	brief_fields:[],
	advanced_search_fields:[],
	page:0,
	rowsPerPage:10,
};

export default defaultState;
