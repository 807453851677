import React, { useState, useEffect } from "react";
import { Paper, Grid, Link, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddSharp";
import { IconButton, Fab, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditSharp";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import AddSynDialog from "../synonyms/AddSynonym";
import { NoSsr } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable from "material-table";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
	toggleNotification,
	notificationMessage,
	testDetails
} from "../../actions/TestActions";
import { patchSynonym, deleteSynonym } from "http_calls/fetchFuncs";
import { getAccess } from "http_calls/userHttp"
import { loggingUser } from "../../actions/TestActions"

const useStyles = makeStyles(theme => ({
	article: {
		padding: "2% 3% 1% 3%"
	},
	div: {
		border: "1px solid #000",
		padding: "1% 1% 1% 1%"
	},
	chip: {
		margin: theme.spacing(1)
	},
	fullwidth: {
		width: "100%"
	}
}));

function DisplaySynonyms(props) {
	const classes = useStyles();
	const [openAddSynDialog, setOpenAddSynDialog] = useState(false);
	const [openSynView, setOpenSynView] = useState(false);
	const [synDetails, setSynDetails] = useState({});
	const [hasEditAccess, setHasEditAccess] = useState(false);
	const [columns, setColumns] = useState([
		{ title: "Synonym Name", field: "SYNONYM_NAME" },
		{ title: "Mnemonic", field: "MNEMONIC" },
		// { title: "Order Section", field: "ORDER_SECTION" }
	]);

	useEffect(() => {
		if (props.user.uname !== undefined) {
			getAccess(props.lab, "synonym_ops").then(res => {
				if (res.data === true) {
					setHasEditAccess(true)
				}
			}).catch(err => {
				console.error(err)
			})
		} else {
			setHasEditAccess(false)
		}
	}, [])

	function handleAddIcon() {
		setOpenAddSynDialog(true);
	}
	function closeAddSynDialog() {
		setOpenAddSynDialog(false);
	}

	function handleSynClick(synonym) {
		setOpenSynView(true);
		setSynDetails(synonym);
	}

	function closeSynView() {
		setOpenSynView(false);
	}

	function editSynonymButtons() {
		if (hasEditAccess) {
			return (
				<div>
					<NoSsr>
						<AddSynDialog
							open={openAddSynDialog}
							onClose={closeAddSynDialog}
							testId={props.testId}
							updateTest={props.updateTest}
						/>
					</NoSsr>
					<Tooltip title="Add New Synonym" placement="top">
						<Fab
							color="primary"
							onClick={handleAddIcon}
							aria-label="add"
							size="small">
							<AddIcon />
						</Fab>
					</Tooltip>
				</div>
			);
		}
	}

	function filterUnPublishedSynonyms(synonyms) {
		return synonyms.filter(syn => (syn.PUBLISH))
	}

	function getOptions(synonyms) {
		let pageSizes = [];
		const synonymsLength = filterUnPublishedSynonyms(synonyms).length;
		let initPageSize = 5;
		for (let i = 1; i <= synonymsLength / initPageSize; i++) {
			pageSizes.push(Math.round(initPageSize * i))
		}
		if (pageSizes.length < 2) {
			if (pageSizes[0] !== synonymsLength) {
				pageSizes.push(synonymsLength)
			}
		}
		return { pageSizeOptions: pageSizes };
	}

	function tableActions() {
		if (hasEditAccess) {
			return {
				onRowUpdate: (newData, oldData) =>
					new Promise((resolve, reject) => {
						setTimeout(() => {
							{
								patchSynonym(props.testId, oldData.SYNONYM_NAME, newData)
									.then(res => {
										if (res.data.IsError === false) {
											props.toggleNotification(true);
											props.updateTest(res.data.Data);
											props.notificationMessage(res.data.Msg);
										} else {
											props.toggleNotification(true)
											props.notificationMessage(res.data.Msg)
										}


									})
									.catch(err => console.error(err));
							}
							resolve();
						}, 500);
					}),
				onRowDelete: oldData =>
					new Promise(resolve => {
						setTimeout(() => {
							deleteSynonym(props.testId, oldData.SYNONYM_NAME).then(res => {
								if (res.data.IsError === false) {
									props.updateTest(res.data.Data);
									props.toggleNotification(true);
									props.notificationMessage(res.data.Msg);
								} else {
									props.toggleNotification(true)
									props.notificationMessage(res.data.Msg)
								}

							})
							resolve();

						}, 600);
					})
			};
		}
		return {};
	}

	return (
		<Paper component="article" className={classes.article}>
			{/* <center>
				
			</center> */}
			<Grid container justify="flex-end" alignItems="center">
				<Grid item xs={11}>
					{/* <Typography variant="h6">
						<center>Synonyms</center>
					</Typography> */}
				</Grid>
				<Grid item xs={1}>
					{editSynonymButtons()}
				</Grid>
			</Grid>
			<Paper component="div" className={classes.div}>
				<Grid container spacing={1}>
					<MaterialTable
						title="Synonyms"
						columns={columns}
						data={filterUnPublishedSynonyms(props.synonyms)}
						style={{ width: "100%" }}
						editable={tableActions()}
						options={getOptions(props.synonyms)}
					/>
				</Grid>
			</Paper>
		</Paper>
	);
}

const mapPropsToState = state => ({
	test_details: state.test_details,
	user: state.user
});
const mapDispatchProps = dispatch => {
	return bindActionCreators(
		{ toggleNotification, notificationMessage, loggingUser, testDetails },
		dispatch
	);
};

export default connect(
	mapPropsToState,
	mapDispatchProps
)(DisplaySynonyms);
