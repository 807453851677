import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import SendIcon from "@material-ui/icons/SendSharp";
import DeleteIcon from "@material-ui/icons/DeleteSharp";
import OpenInBrowser from "@material-ui/icons/OpenInBrowserSharp";
import NewTestSubmitButton from "./NewTestSubmitButton"
import ViewDetailedDraft from "./ViewDetailedDraft";
import { height } from '@material-ui/system';
import AreYouSureDialog from '../components/areYouSureComponent'
import Divider from "@material-ui/core/Divider"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    toggleNotification,
    notificationMessage,
} from "../actions/TestActions"

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 24,
    },
    pos: {
        marginBottom: 12,
        marginTop: 4,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));
function createdDate(editDate) {
    var local_date = moment.utc(editDate).local().format('YYYY-MM-DD, HH:mm a');
    return local_date
}


function printDraftName(draftName) {
    if (draftName.length < 1) {
        return ""
    }
    if (draftName.match(/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i)) {
        return "Draft started at " + moment.utc(draftName).local().format('YYYY-MM-DD, HH:mm a')
    }
    return "Draft Name: " + draftName
}

function DraftCard(props) {
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openDetailedModal, setOpenDetailedModal] = useState(false)
    const classes = useStyles();

    const handleOpenDetailModal = () => {
        setOpenDetailedModal(true)
    }
    const handleOpenDetailModalClose = () => {
        setOpenDetailedModal(false)
    }
    const closeDeleteDialog = () => {
        setDeleteDialog(false)

    }

    const openDeleteDialog = () => {
        setDeleteDialog(true)
    }

    const openDraftView = (draftName) => {

        window.open(`/new/test/${draftName}`, "popup", "width=700", "height=500")
    }

    return (
        <Card className={classes.card}>
            <CardContent>

                <Typography className={classes.title} variant="h1" fontWeight="fontWeightMedium" fontSize={24} component="p">
                    {props.draft.TestName}
                </Typography>
                <Typography className={classes.pos} variant="body1" component="p">
                    {printDraftName(props.draft.DraftName)}
                </Typography>
                <Typography variant="body2" component="p" color="textSecondary">
                    draft saved at
          <br />
                    {createdDate(props.draft.EditDate)}
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                    created by {props.draft.Author}
                </Typography>
            </CardContent>
            <Divider />
            <CardActions>
                <Button size="small" color={"default"} variant="outlined" onClick={() => { openDraftView(props.draft.DraftName) }}> Open Draft <OpenInBrowser /></Button>
                <Button size="small" color={"secondary"} onClick={openDeleteDialog}>Delete Draft <DeleteIcon /></Button>
            </CardActions>
            <AreYouSureDialog
                open={deleteDialog}
                handleClose={closeDeleteDialog}
                title={`Confirmation for deleting Draft ${props.draft.DraftName}`}
                content={"You cannot retrieve saved information  if you delete this draft. \n Are you sure ?"}
                disAgreeButtonText={"Cancel"}
                agreeButtonText={"Delete Draft"}
                submit={() => { props.handleDelete(props.draft.DraftName) }}
            />
        </Card>
    );
}

const mapPropsToState = state => ({
    user: state.user,
});
const mapDispatchProps = dispatch => {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
};
export default connect(mapPropsToState, mapDispatchProps)(DraftCard)
