import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	Slide,
	IconButton,
	Grid,
	Button,
	Link,
} from "@material-ui/core";
import CustonExpansionPanel from "../customExpansionPanel/expansionPanel";
import OpenNewIcon from "@material-ui/icons/OpenInNewSharp";
import { connect } from "react-redux";
import { loadTests, toggleLoading, testDetails, numRecords, searchMode } from "../../actions/TestActions";
import { bindActionCreators } from "redux";
import useFetchTests from "hooks/useFetchTests";
import processTestObj from "../TestObjFormatter";
import TestPagination from './mainPagination';
import SearchTabs from "./tabs";

const styles = theme => ({
	root: {
		width: "100%",
		margin: "5px 0 5px 0"
	},
	heading: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: "500"
	},
	widthFitContent: {
		minWidth: "fit-content",
		margin: "0 1% 0 0"
	},
	row: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.grey[200]
		}
	},
	CellFont: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: "400"
	},
	testsDisplayContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	}
});
const timeout = {
	enter: 500,
	exit: 10000
};


function DisplayTestResults(props) {
	const [defaultExpanded, setDefaultExpanded] = useState(false);
	const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	function testDetails(test) {
		const { classes } = props;
		let formattedTest;
		if (props.test_details_categories.All !== undefined) {
			formattedTest = processTestObj(test, props.test_details_categories.All, props.test_field_types);
		} else {
			formattedTest = processTestObj(test, {});
		}

		let trimmedTest = pick(formattedTest, props.brief_fields);
		let i = 0
		return (
			<Table className={classes.table}>

				<colgroup>
					<col style={{ width: "20%" }} />
					<col style={{ width: "60%" }} />
				</colgroup>
				<TableBody>
					{props.brief_fields.map(key => (
						<TableRow key={i++} className={classes.row}>
							<TableCell align="left">{key + ":"}</TableCell>
							<TableCell align="left">{trimmedTest[key]}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	}
	function openTestDetailsTab(test, e) {
		event.stopImmediatePropagation()
		if (test.MidmTestId) {
			window.open(
				`/details/test/${test.MidmTestId}`,
				"_blank"
			)
			return;
		}

		window.open(
			`/details/test/${test.TestId}`,
			"_blank"
		)
		return;
	}

	//Renders Expansion panels
	function renderExpansionPanels() {
		let i = 0;
		const { classes } = props;
		return (
			<div>
				{props.tests.map(test => (
					<div className={classes.root} key={i++}>
						<CustonExpansionPanel
							header={<Link component="button" variant="body1" onClick={(e) => { openTestDetailsTab(test, e) }}>{test.TestName}</Link>}
							elevation={3}
							openLinkAction={<Tooltip title="Open Test in new Tab" interactive>
								<IconButton
									aria-label="Open Test in new Tab"
									className={classes.margin}
									onClick={(e) => { openTestDetailsTab(test, e) }}>
									<OpenNewIcon fontSize="small" />
								</IconButton>
							</Tooltip>} >
							{testDetails(test)}
						</CustonExpansionPanel>
					</div>
				))}
			</div>
		);
	}

	const { classes } = props;
	return (
		// <>
		// 
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			{isMobile? <></> :
				<Grid item >
					<TestPagination />
				</Grid>
			}
			<Grid item>
				{isMobile? <></>:
					<SearchTabs/>
				}
				{renderExpansionPanels()}
			</Grid>
			<Grid item>
				<TestPagination />
			</Grid>
		</Grid>

		// <div className={classes.testsDisplayContainer}>	
		// <TestPagination />
		// 	{renderExpansionPanels()}
		// <TestPagination />
		// </div>
		// </>
	);
}

function pick(obj, keys) {
	return keys
		.map(k => (k in obj ? { [k]: obj[k] } : {}))
		.reduce((res, o) => Object.assign(res, o), {});
}

DisplayTestResults.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	checkedlabs: state.checkedlabs,
	tests: state.tests,
	params: state.params,
	search_mode: state.search_mode,
	test_details_categories: state.test_details_categories,
	test_field_types: state.test_field_types,
	brief_fields: state.brief_fields,
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ loadTests, toggleLoading, testDetails, numRecords, searchMode }, dispatch);
};

const DisplayTestResultsWIthStyles = withStyles(styles)(DisplayTestResults);
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DisplayTestResultsWIthStyles);
