import React from 'react';
import ListAltIcon from '@material-ui/icons/ListAltSharp';

const staticLinks=[
		{
			id:1,
			Link:'http://www.mlabs.umich.edu/client-services/test-updates/',
			LinkName:'Test Updates',
			LinkIcon:<ListAltIcon fontSize="small"/>
		},
		{
			id:2,
			Link:'http://mlabs.umich.edu/client-services/submit-specimens/',
			LinkName:'Submit Specimens',
			LinkIcon:'/submit_icon.png'
		},
		{
			id:3,
			Link:'http://mlabs.umich.edu/client-services/formsreqssupplies/',
			LinkName:'Forms',
			LinkIcon:'/forms_icon.png'
		},
		{
			id:4,
			Link:'http://www.pathology.med.umich.edu',
			LinkName:'Pathology Home',
			LinkIcon:'/bigM.png'
		},
		{
			id:5,
			Link:'http://mlabs.umich.edu/',
			LinkName:'MLabs Home',
			LinkIcon:'/mlabs.png'
		},
		{
			id:6,
			Link:'https://www.pathology.med.umich.edu/index.php?t=page&id=1614',
			LinkName:'Directory'
		},
		{id:7,
			Link:'http://mlabs.umich.edu/client-services/blood-drawing-stations/',
			LinkName:'Blood Draw Locations'
		},
		{id:8,
			Link:'http://mlabs.umich.edu/files/pdfs/PRC-Clinical_Micro_Viro_%20Transport.pdf',
			LinkName:'Transport'
		},
		{id:9,
			Link:'http://mlabs.umich.edu/files/about_pdfs/POL-CRITICAL_VALUES%20Policy.pdf',
			LinkName:'Critical Values'
		},
		{id:10,
			Link:'https://www.pathology.med.umich.edu/index.php?t=page&id=977',
			LinkName:'POC Testing'
		},
		{id:11,
			Link:'https://www.pathology.med.umich.edu/client_manual',
			LinkName:'MLabs Client Manual'
		},
		{id:12,
			Link:'https://www.pathology.med.umich.edu/mlabs/internal/',
			LinkName:'MLabs Internal Portal'
		},

		{id:13,
			Link:'https://www.pathology.med.umich.edu/mlabs/internal/',
			LinkName:'MLabs Connect'
		},
	]

export default staticLinks;