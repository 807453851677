
function notEmpty(obj) {
    if (isNullOrUndefined(obj)) {
        return false
    }
    if (Object.keys(obj).length > 0) {
        return true
    }
    return false
}

function isEmpty(obj) {
    if (isNullOrUndefined(obj)) {
        return true
    }
    if (Object.keys(obj).length > 0) {
        return false
    }
    return true
}
//works only for objects ; return false for non objects.
function ObjectEqual(x, y) {
    if (x === y) {
        return true
    }
    if (!(x instanceof Object) || !(y instanceof Object)) {
        return false;
    }
    //ignoring constructor
    // if (x.constructor !== y.constructor) return false;

    for (var p in x) {
        if (!x.hasOwnProperty(p)) {
            continue
        }

        if (!y.hasOwnProperty(p)) {
            return false
        }
        if (x[p] === y[p]) { continue }

        if (typeof (x[p]) !== "object") {
            return false;
        }

        if (!ObjectEqual(x[p], y[p])) {
            return false;
        }
    }

    for (p in y) {
        if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    }
    return true;
}

function ObjectDifferences(obj1, obj2) {
    if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
        return obj1;
    }

    let diffs = {};
    let key;

    function arraysMatch(arr1, arr2) {

        // Check if the arrays are the same length
        if (arr1.length !== arr2.length) return false;

        // Check if all items exist and are in the same order
        for (var i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }

        // Otherwise, return true
        return true;

    };

    function compare(item1, item2, key) {

        // Get the object type
        var type1 = Object.prototype.toString.call(item1);
        var type2 = Object.prototype.toString.call(item2);

        // If type2 is undefined it has been removed
        if (type2 === '[object Undefined]') {
            diffs[key] = null;
            return;
        }

        // If items are different types
        if (type1 !== type2) {
            diffs[key] = item2;
            return;
        }

        // If an object, compare recursively
        if (type1 === '[object Object]') {
            var objDiff = ObjectDifferences(item1, item2);
            if (Object.keys(objDiff).length > 1) {
                diffs[key] = objDiff;
            }
            return;
        }

        // If an array, compare
        if (type1 === '[object Array]') {
            if (!arraysMatch(item1, item2)) {
                diffs[key] = item2;
            }
            return;
        }

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (type1 === '[object Function]') {
            if (item1.toString() !== item2.toString()) {
                diffs[key] = item2;
            }
        } else {
            if (item1 !== item2) {
                diffs[key] = item2;
            }
        }

    };


    //
    // Compare our objects
    //

    // Loop through the first object
    for (key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            compare(obj1[key], obj2[key], key);
        }
    }

    // Loop through the second object and find missing items
    for (key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (!obj1[key] && obj1[key] !== obj2[key]) {
                diffs[key] = obj2[key];
            }
        }
    }

    // Return the object of differences
    return diffs;

}
function isEmptyOrSpaces(str) {
    if (isNullOrUndefined(str)) {
        return true
    }
    return str === null || str.match(/^ *$/) !== null;
}

function makeArrayUnique(arr) {
    let mergedSet = new Set(arr)
    return Array.from(mergedSet)
}

function removeElementFromArray(arr, ele) {
    arr.splice(arr.indexOf(ele), 1)
    // return [...arr]
}

function getLargestKey(obj) {
    if (obj === null || obj === undefined) {
        return ""
    }
    let _largestKey = 0;

    let _keys = Object.keys(obj);
    if (_keys.length === 1) {
        return _keys[0]
    }
    _largestKey = _keys.sort()[_keys.length - 1]
    return _largestKey;
}

function isNullOrUndefined(obj) {
    if (obj === null || obj === undefined) {
        return true
    }
    return false
}
function ObjHasKey(obj, key) {
    if (obj === undefined || obj === null) return false;
    return obj.hasOwnProperty(key);
}

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

function IsObject(obj) {
    return typeof obj === 'object'
}

function GetMinorFields(fields) {
    let minorFields = []
    Object.keys(fields).forEach(key => {
        if (!fields[key]["majorChange"]) {
            minorFields.push(key)
        }
    })
    return minorFields

}

function getSubdomain(host) {
    if(host){

        var subdomain = host.split(".")[0];
        return subdomain;
    }
    return "";
}

function FilterTestByFieldsArray(test, arr) {
    let filteredTest = {}
    Object.keys(test).forEach(key => {
        if (arr.indexOf(key) >= 0) {
            filteredTest[key] = test[key];
        }
    })
    return filteredTest;
}

export {
    notEmpty,
    isEmpty,
    isNullOrUndefined,
    ObjectEqual,
    getLargestKey,
    ObjHasKey,
    getKeyByValue,
    IsObject,
    ObjectDifferences,
    makeArrayUnique,
    GetMinorFields,
    FilterTestByFieldsArray,
    removeElementFromArray,
    isEmptyOrSpaces,
    getSubdomain,
}