import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    loggingUser,
    toggleNotification,
    notificationMessage,
} from "../../actions/TestActions";
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Edit'
import {
    Button,
    Modal,
    Typography,
    Paper,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    makeStyles
} from "@material-ui/core";
import { DeleteSaveSearch } from "http_calls/userHttp";

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: 14,
    },
    DialogContent: {
        width: "80vw",
        height: "80vh",
    },
    paper: {
        position: 'absolute',
        width: "80vw",
        Height: "80vh",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
	button:{
		width: "150px",
		margin: theme.spacing(1)
	}
}))

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


function EditSearchDialog(props) {
    const classes = useStyles()

    const [state, setState] = useState({
        open: false,
        searchName: ""
    })

    const handleClickOpen = () => {
        setState({ open: true });
    };

    const handleClose = () => {
        setState({ open: false });
    };


    const onDelete = (filterName) => {
        DeleteSaveSearch(props.user.uname, filterName).then(data => {
            props.toggleNotification(true)
            if(data.user){
                props.loggingUser(data.user)
                props.notificationMessage(`${filterName} is removed from user Acc.`)
            }
            else{
                props.notificationMessage("Failed to remove filter , please try again after logging out and logging in")
            }
                
            
        });
    }

    const isDisabled = (searchs) => {
        if (searchs) {
            if(Object.keys(searchs).length < 1) {
                return true
            }
        }
        return false
    }
    const getSearchsArr = (searchs) => {
        if(searchs){
            return Object.keys(searchs)
        }
        return []
    }

   
        return (
            <div>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    size="medium" 
                    onClick={handleClickOpen} 
                    className={classes.button}
                    disabled={isDisabled(props.user.searchs)}>
                        Edit Filters
                    </Button>
                <Modal open={state.open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                    <Paper style={getModalStyle()} className={classes.paper}>
                        <center>
                            <Typography component="h1">
                                Saved Filters
                            </Typography>
                        </center>

                        <Grid container justify="space-around" spacing={2} alignItems="flex-start">
                            {getSearchsArr(props.user.searchs).map(search => (
                                <Grid item xs={3}>
                                    <Card >
                                        <CardContent>
                                            <center>
                                                <Typography className={classes.title} gutterBottom>
                                                    {"Name of the saved filter: "} <u><b>{search}</b></u>
                                                </Typography>
                                            </center>
                                            <Grid container direction="column">
                                                {getSearchsArr(props.user.searchs[search]).map(key => (
                                                    <Grid item>
                                                        {key + " : " + props.user.searchs[search][key]}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Button color="secondary" size="small" variant="contained" onClick={() => { onDelete(search) }}>
                                                Delete saved filter
                                                <DeleteIcon className={classes.rightIcon} />
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </Paper>

                </Modal>
            </div >
        );
   

}

const mapStateToProps = state => ({
    user: state.user,
    params: state.params,
    checkedlabs: state.checkedlabs,
    search_mode: state.search_mode
});
const mapDispatchProps = dispatch => {
    return bindActionCreators({
        loggingUser, toggleNotification,
        notificationMessage,
    }, dispatch);
};
export default connect(
    mapStateToProps,
    mapDispatchProps
)(EditSearchDialog);
