import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import {  useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
    usePopupState,
    bindTrigger,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideLinks from '../index_components/SideLinks';
import { getLinks } from "http_calls/fetchLinks"

const useStyles = makeStyles(theme => ({
    editButton: {
        margin: "10% 25%",
    },
    container: {
        maxWidth: "15vw",
        height:"27vh",
    },
    linksText :{
        color: theme.palette.common.white
    },
}));

const TriggerMenu = (props) => {
    const classes = useStyles()
    const [links, setLinks] = useState({ dynamicLinks: [], staticLinks: [] })
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const popupState = usePopupState({ variant: 'popover', popupId: 'popoverId' })
    useEffect(() => {
        let _links = { ...links }
        getLinks().then(res => {
            if(!res.msg.IsError){
                res.msg.Data.forEach(l => {
                    if (l.linksName === "dynamicLinks") {
                        _links["dynamicLinks"] = l.linksData
                    }
                    if (l.linksName === "staticLinks") {
                        _links["staticLinks"] = l.linksData
                    }
                });
                setLinks(_links)
          }
           
        }).catch(err => console.error(err))
    }, [])

    return (
        <div>
            <Button variant={isMobile?"contained":"text"} color={isMobile?"primary":"default"} {...bindTrigger(popupState)} className={classes.linksText}>
                Links
      </Button>

            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.container}>
                   <SideLinks links={links.staticLinks}  />
                </div>
            </Popover>
        </div>
    )
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {

        }, dispatch
    )
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TriggerMenu)