import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    select: {
        width: "180px"
    }
}));


function TimeTakenSelectComponent(props) {

    const classes = useStyles();
    const [tat, setTat] = useState(props.time);
    const [selectedValue, setSelectedValue] = useState("minutes")

    useEffect(() => {
        props.onChange(props.field, tat)
    }, [tat])

    const handleChange = (e) => {
        setSelectedValue(e.target.value)
    }
    const handleDurationChange = (e) => {
        let _state = { ...tat }
        _state[selectedValue] = parseInt(e.target.value)

        setTat(_state)

    }

    return (
        <div>
            <TextField
                id="Time-input"
                label="Time"
                value={tat[selectedValue]}
                onChange={handleDurationChange}
                type="number"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                margin="normal"
                variant="outlined"
            />
            <TextField
                id="standard-select-currency"
                select
                label="Select"
                fullWidth={true}
                variant="outlined"
                className={classes.select}
                value={selectedValue}
                onChange={handleChange}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                helperText="Please select the time in Day or Hours"
                margin="normal"
            >
                {Object.keys(tat).map(key => (
                    <MenuItem key={key} value={key}>
                        {key}
                    </MenuItem>
                ))}
            </TextField>
        </div>

    );
}

export default TimeTakenSelectComponent;
