import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles"
import { LinearProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const useStyles = makeStyles({
  stickyDiv: {
    // flexGrow: 1,
    // top:0.1,
    // position: '-webkit-sticky',
    // position: 'sticky',
    // height:"50%",
    
    // position: 'fixed', // Change this to 'fixed' to position it relative to the viewport
    width: '100%', // Ensure it spans the full width
    zIndex: 1000, 
    position: 'fixed', 
    bottom: 0,
    padding: 1.5,
    // width: '100%', 
    
    // backgroundColor: useTheme().palette.primary.light,
    // zIndex: 1000 
  },
});

function CustomLinearProgress(props) {
  const classes = useStyles();
  const theme = useTheme();
  

  return (
    <div >
      {props.loading_bool ===true ?<LinearProgress color="primary" className={classes.stickyDiv} />:null}
    </div>
  );
}

const mapStateToProps = state => ({
  loading_bool:state.loading_bool
})

const mapDispatchProps = dispatch => {
  return bindActionCreators({}, dispatch);
};



export default connect(mapStateToProps, mapDispatchProps)(CustomLinearProgress);