import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import SendIcon from "@material-ui/icons/SendSharp";
import clsx from 'clsx'
import diff from "shallow-diff";
import EffectiveDateFormDialog from "../components/formDialogComponent";
import DatePickerField from "../components/DatePickerField"
import moment from "moment"
import { isEmptyOrSpaces } from "helperFunctions"
import { submitDraft } from "http_calls/userHttp";



function NewTestSubmitButton(props) {
    const [effectiveDateOpen, setEffectiveDateOpen] = useState(false)
    const [effectiveDate, setEffectiveDate] = useState(props.test_details.EffectiveDate)
    const [viewSynonyms, setViewSynonyms] = useState(false)

    function isMinorOrMajor(draft, test) {
        let typeOfChange = "minor"
        let diffs = diff(draft, test)
        if (diffs.updated.length > 0) {
            diffs.updated.forEach(k => {
                if (k !== "Synonyms") {
                    if (props.test_field_types[k].majorChange === true) {
                        typeOfChange = "major"
                    }
                }
            })
        }
        if (diffs.added.length > 0) {
            diffs.added.forEach(k => {
                if (props.test_field_types[k].majorChange === true) {
                    typeOfChange = "major"
                }
            })
        }
        return typeOfChange
    }

    function handleEffectiveClose() {
        setEffectiveDateOpen(false)
    }

    const handleEffectiveDateChange = (e) => {
        setEffectiveDate(e.target.value)
    }
    function handleEffectiveDateSubmit() {
        let _draft = { ...props.formData }
        let changeType = "major"
        if (props.checkChanges === true) {
            changeType = isMinorOrMajor(_draft, props.test_details)
        }
        _draft.Container = ""
        _draft.Author = props.user.uname
        _draft["EffectiveDate"] = moment(effectiveDate).format()
        if (isEmptyOrSpaces(_draft.DraftType)) {

            _draft.DraftType = "major"
        }
        submitDraft(props.user.uname, _draft, "major").then(res => {
            props.toggleNotification(true);
            props.notificationMessage(res.Msg)


        });
        handleEffectiveClose()
    }

    function handleSubmit() {
        let diffs = diff(props.formData, props.test_details)
        if (props.checkChanges === true) {
            if (diffs.updated.length > 0 || diffs.added.length > 0) {
                setEffectiveDateOpen(true)
            } else {
                props.toggleNotification(true);
                props.notificationMessage("Please make your changes before submitting.")
            }
        } else {
            setEffectiveDateOpen(true)
        }


    }

    return (
        <div>
            <Button variant={props.variant} color={props.color} className={props.classes.button} onClick={handleSubmit}>
                <SendIcon className={clsx(props.classes.leftIcon, props.classes.iconSmall)} />
                Submit
	    </Button>
            <EffectiveDateFormDialog
                open={effectiveDateOpen}
                handleClose={handleEffectiveClose}
                content={"please confirm effective data."}
                handleSubmit={handleEffectiveDateSubmit}
                confirmButtonText={"Submit"}
                cancelButtonText={"Cancel"} >
                    <DatePickerField effectiveDate={effectiveDate} onChange={handleEffectiveDateChange} />
                </EffectiveDateFormDialog>

        </div>

    )
}

export default NewTestSubmitButton