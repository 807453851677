import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import { Search, Clear } from "@material-ui/icons";
import { connect } from "react-redux";
import {
	addParams,
	numRecords,
	searchMode,
	searchText,
} from "../../actions/TestActions";
import { useDebounce } from "react-use";
import { bindActionCreators } from "redux";
import { SearchTests } from "../../../httpCalls/fetchFuncs";
import Divider from '@material-ui/core/Divider';

const styles = {
	root: {
		padding: "2px 2px",
		display: "flex",
		alignItems: "center",
		width: "27vw"
	},
	input: {
		marginLeft: "10%",
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4,
	}
};
const WAIT_INTERVAL = 2000;
const ENTER_KEY = 13;

function SearchBar(props) {
	const [value, setValue] = useState("");
	const [debouncedValue, setDebouncedValue] = useState("");

	

	function handleChange(e) {
		
		setValue(e.target.value);
	}

	function handleKeyDown(e) {
		if (e.keyCode === ENTER_KEY && value.length > 1) {
			triggerChange(value);
		}
	}

	function triggerChange(val) {
		props.searchText(val)
	}
	function handleClearSearch() {
		setValue("")
		props.searchText("");
	}

	const { classes } = props;
	return (
		<Paper className={classes.root} elevation={1}>

			<IconButton
				className={classes.iconButton}
				aria-label="Search"
				onClick={() => {
					triggerChange(value);
				}}>
				<Search />
			</IconButton>
			<Divider className={classes.divider} />
			<InputBase
				className={classes.input}
				placeholder="Search Handbook for Tests"
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				value={value}
			/>
			<Divider className={classes.divider} />
			<IconButton color="secondary" className={classes.iconButton} aria-label="clear search" onClick={handleClearSearch}>
				<Clear />
			</IconButton>

		</Paper>
	);
}

SearchBar.propTypes = {
	classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
	tests: state.tests,
	params: state.params,
	search_mode: state.search_mode,
	search_text: state.search_text
});
const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{  addParams, numRecords, searchMode ,searchText},
		dispatch
	);
};

const SearchBarWithStyles = withStyles(styles)(SearchBar);
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchBarWithStyles);
