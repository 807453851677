import React from "react";
import processTestObj from "../TestObjFormatter";
import RenderTestDetails from "./RenderTestDetails"
import { Grid } from "@material-ui/core"
import { connect } from "react-redux";
import ContainerImages from "./ContainerImages"
import ViewImages from "../../components/viewImages";
import DisplaySynonyms from "./displaySynonyms";

const OverviewDetails = props => {
    const test = props.test;
    const formattedTest = processTestObj(test, props.test_details_categories['Overview Details'], props.fieldTypes);
    const title = "Overview Details"
    const fieldKeys = Object.keys(props.test_details_categories['Overview Details'])

    return (
        <Grid container spacing={1} justify={"space-around"}>
            <Grid container row justify ={"space-around"}>
                <Grid item xs={7}>
                    {console.log(reverseObject(formattedTest))}
                    <RenderTestDetails obj={reverseObject(formattedTest)} classes={props.classes} title={"Overview Details"} />
                </Grid>
                <Grid item xs={4}>
                    <ViewImages images={props.test.Containers} />
                </Grid>
            </Grid>
            <Grid row xs={12}>
                 <RenderTestDetails obj={processTestObj(test, props.test_details_categories['Specimen Details'], props.fieldTypes)} classes={props.classes} title={"Specimen Details"} />
            </Grid>
            <Grid row xs={12}>
                <DisplaySynonyms
				    synonyms={props.test.Synonyms}
				    testId={props.test.TestId}
				    lab={props.test.Lab}
				    updateTest={props.changeTestDetailsState}
			    />
            </Grid>
        </Grid>
    )
};

const reverseObject= (obj) => {
    const entries = Object.entries(obj);    
    entries.sort((a, b) => b[0].localeCompare(a[0]));
    const sortedJsonObject = Object.fromEntries(entries);   
    return sortedJsonObject; 
}

const mapPropsToState = state => ({
    test_details_categories: state.test_details_categories,
    fieldTypes: state.test_field_types,
})
export default connect(mapPropsToState, {})(OverviewDetails);
