import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({}));

function LabListItem(props) {
	const classes = useStyles();


	const handleListItemToggle = value => () => {
		const currentIndex = props.checkedlabs.indexOf(value);

		let _labs = [...props.checkedlabs];
		if (currentIndex === -1) {
			_labs.push(value);
			props.toggleLabs(_labs);
		} else {
			_labs = [...props.checkedlabs];
			_labs.splice(currentIndex, 1);
			props.toggleLabs(_labs);
		}

	};

	return (
		<MenuItem
			key={props.lab}
			button
			onClick={handleListItemToggle(props.lab)}
			data-lab={props.lab}>
			<Switch checked={props.checked} color="primary" />
			<ListItemText primary={props.lab} />
		</MenuItem>
	);
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LabListItem);
