import React, { useState, useEffect } from "react";

import { Paper, Grid, ButtonBase, IconButton, Typography } from "@material-ui/core";
import { KeyboardArrowLeftSharp, KeyboardArrowRightSharp } from "@material-ui/icons"
import propTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import ImageViewer from "./ImageViewer";
import is from "is_js"

const useStyles = makeStyles(theme => ({
    root: {
        // marginTop: "3vh",
        padding: "1%",
    },
    border: {
        borderWidth: "0.2px",
        borderStyle: "solid",
    }

}))

const getUrl = (file_name,base_url) => {
    if (is.not.ie()) {
        return base_url+file_name

    }
    return base_url + file_name.replace(".webp", ".jpg")

}


const returnImageCSS = (file_name,base_url) => {
    let _fileName;
    let width;
    if (file_name.trim().length < 1) {
        _fileName = "/no_image.png";
        width = "auto"
    } else {
        _fileName = getUrl(file_name,base_url)
        width = "10vw"
    }
    
    return {
        height: "40vh",
        width,
        backgroundImage: `url("${_fileName}")`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        cursor: "zoom-in"
    }
}

function ViewImages(props) {
    const classes = useStyles()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [zoomedPic, setZoomedPic] = useState(false)
    const handleLeftClick = () => {
        setCurrentIndex(currentIndex - 1)
    }

    const handleRightClick = () => {
        setCurrentIndex(currentIndex + 1)
    }
    const openBigPicture = () => {
        setZoomedPic(true)
    }

    const closeBigPicture = () => {
        setZoomedPic(false)
    }


    const GetLeftArrow = (i, handleClick) => {
        let disabled = true;
        if (i > 0) {
            disabled = false
        }
        return (
            <Grid item xs={2} container justify="center" alignItems="center">
                <Grid item direction="row" justify="center" alignItems="center" >
                    <IconButton onClick={handleClick} disableRipple={false} disabled={disabled} >  <KeyboardArrowLeftSharp /></IconButton>
                </Grid>
            </Grid>
        )

    }

    const GetRightArrow = (i, length, handleClick) => {
        let disabled = true
        if (i < length) {
            disabled = false
        }
        return (
            <Grid item xs={2} container justify="center" alignItems="center">
                <Grid item direction="row" justify="center" alignItems="center" >
                    <IconButton onClick={handleClick} disableRipple={disabled} disabled={disabled} > <KeyboardArrowRightSharp /></IconButton>
                </Grid>
            </Grid>
        )

    }

    return (
        <Paper elementType="div" elevation={1} className={classes.root}>
            <center><Typography variant="h6" align="center">Containers </Typography></center>
            <Grid container direction row spacing={0} justify="space-between" alignItems="center" className={classes.border}>
                {props.images.length > 0 ?
                <>
                    {GetLeftArrow(currentIndex, handleLeftClick)}
                    <Grid item xs={6} container direction="column" spacing={0} justify={"space-between"} alignItems="stretch">
                        <Grid item >
                            <div style={returnImageCSS(props.images.length >0 ?props.images[currentIndex].file_name:"",props.images[currentIndex].base_url)} onClick={openBigPicture}>

                            </div>
                        </Grid>

                        <Grid item >
                            <center><Typography variant="overline" align="center" ><u>{props.images.length >0 ?props.images[currentIndex].container_name:"No container Image uploaded"}</u> </Typography></center>
                        </Grid>

                        <ImageViewer open={zoomedPic} image={getUrl(props.images.length >0 ?props.images[currentIndex].file_name:"",props.images[currentIndex].base_url)} onClose={closeBigPicture} left={GetLeftArrow(currentIndex, handleLeftClick)} right={GetRightArrow(currentIndex, props.images.length - 1, handleRightClick)} />
                    </Grid>
                    {GetRightArrow(currentIndex, props.images.length - 1, handleRightClick)}
                </>
                :
                <>
                      <Grid item container direction="column" spacing={0} justify={"space-between"} alignItems="stretch">
                      <Grid item >
                            <div style={returnImageCSS("","")} >

                            </div>
                        </Grid>

                        <Grid item >
                            <center><Typography variant="overline" align="center" ><u>{"No container Image uploaded"}</u> </Typography></center>
                        </Grid>
                      
                      </Grid>
                </>
                }
            </Grid>
        </Paper>
    )
}


ViewImages.propTypes = {
    images: propTypes.array
}

export default ViewImages