import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SynonymInput from "./TagsInputForSynonyms";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleLabs, getLabs } from "../../actions/LabActions";
import { postSynonym } from "../../../httpCalls/fetchFuncs";
import {
	toggleNotification,
	notificationMessage,
	testDetails
} from "../../actions/TestActions";

function isBlank(str) {
	return (!str || /^\s*$/.test(str));
}

function AddSynDialog(props) {
	const [synInput, setSynInput] = useState("");
	const [formData, setFormData] = useState({});
	function resetState() {
		setSynInput("")
		setFormData({})
	}
	function handleInputField(e, key, value) {
		let _formData = formData;
		if (e) {
			if (e.target.name === "PUBLISH") {
				_formData[e.target.name] = e.target.checked;
			} else {
				_formData[e.target.name] = e.target.value;
			}
		} else {
			let _tags = new Set([...value]);
			_formData[key] = Array.from(_tags).join(",");
		}

		setFormData(_formData);
	}



	function handleSynInput(synInput) {
		setSynInput(synInput);
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (props.updateTest) {			
			postSynonym(props.testId, formData)
				.then(res => {
					if (res.data.IsError === false) {
						props.toggleNotification(true);
						props.updateTest(res.data.Data);
						props.notificationMessage(res.data.Msg);
						resetState();
					} else {
						props.toggleNotification(true)
						props.notificationMessage(res.data.Msg)
					}
				})
				.catch(err => console.error(err));
			props.onClose();
		} else {
			props.addSynonym(formData)
			resetState();
			props.onClose()
		}

	}

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.onClose}
				aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">ADD Synonym</DialogTitle>
				<form method="post" onSubmit={handleSubmit}>
					<DialogContent>
						{__isBrowser__ ? (
							<SynonymInput handleInputField={handleInputField}/>
						) : (
								<span />
							)}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={props.onClose}
							color="secondary"
							variant="contained">
							Cancel
						</Button>
						<Button type="submit" color="primary" variant="contained">
							Add
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	);
}

const mapPropsToState = state => ({
	test_details: state.test_details
});
const mapDispatchProps = dispatch => {
	return bindActionCreators(
		{ toggleNotification, notificationMessage, testDetails },
		dispatch
	);
};

export default connect(
	mapPropsToState,
	mapDispatchProps
)(AddSynDialog);
