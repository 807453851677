import axios from "axios";

const jsonConfig = {
	headers: {
		"Content-Type": "application/json"
	}
}

const getLinks = async ()=>{
    let result;
    result = await axios.get("/links").then(res=>{
        return res.data
    }).catch(err=>{return err})
    return result
}

const postLinks = async (linkName, links) =>{
    let result ;
    result = await axios.post(`/links/${linkName}` , links , jsonConfig).then(res=>{
        return res.data
    }).catch(err => {return err})
    return result
}
export {getLinks,postLinks}