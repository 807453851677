import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

function FormDialog(props) {

    return (
        <div>

            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.content}
                    </DialogContentText>
                   { props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="secondary">
                        {props.cancelButtonText}
                    </Button>
                    <Button onClick={props.handleSubmit} color="primary">
                        {props.confirmButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

FormDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string
}

export default FormDialog;
