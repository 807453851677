/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import Layout from "./Layout";
import reducer from "../../reducers/Reducer";
import { Provider } from "react-redux";
import axios from "axios";
import { createMuiTheme } from "@material-ui/core/styles";
import Theme from "../../../theme";
import { CookiesProvider } from "react-cookie";
import { ServerStyleSheets, ThemeProvider } from "@material-ui/styles";
import { Scrollbars } from "react-custom-scrollbars";
import buildStore from '../../buildStore';


const preloadedstate = typeof window !== "undefined" && window && window.__PRELOADED_STATE__;
const store = buildStore(reducer, preloadedstate)



axios.get("/theme").then(themeRes => {
	ReactDOM.hydrate(
		<CookiesProvider>
			<ThemeProvider theme={createMuiTheme({ ...themeRes.data })}>
				<Provider store={store}>
					<Layout />
				</Provider>
			</ThemeProvider>
		</CookiesProvider>,
		document.getElementById("app")
	);
}).catch(err => {
	console.error(err)
	ReactDOM.hydrate(
		<CookiesProvider>
			<ThemeProvider theme={Theme}>
				<Provider store={store}>
					<Layout />
				</Provider>
			</ThemeProvider>
		</CookiesProvider>,
		document.getElementById("app")
	);
})
