import React,{useState,useEffect} from 'react';
import axios from "axios";

function useFetchTests(params,checkedLabs, full_url){
	const [tests,setTests] = useState([]);
	const [num_records,setNumTests] = useState(0);

	useEffect(()=>{
		const fetchTests = async() =>{
			let arg_str = "";

		if (checkedLabs) {
			arg_str += checkedLabs.map(lab => "Lab=" + lab).join("&");;
		}
		if (!params.hasOwnProperty("limit")) {
			params["limit"] = 10;
		}
		if (!params.hasOwnProperty("start")) {
			params["start"] = 0;
		}
		arg_str += "&" + Object.keys(params).filter(key => params[key] !== undefined)
		                                    .map(key => key + "=" + params[key])
		                                    .join("&");
		let url = "/api/v1/internal/tests?" + arg_str;

		//full_url is a optional parameter to send custom url.
		if (full_url !== undefined) {
			url = full_url;
		}

		await axios.get(url).then(res => {
			setNumTests(res.headers.num_records);
			if (res.data.IsError===false){
				setTests(res.data.Data);
			}		
		}).catch(err=>console.error(err));
		}

		fetchTests();
	},[params,checkedLabs,full_url]);
	return {tests, num_records};
}






export default useFetchTests;