import React, { useState } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Button, TextField, List, ListItem,IconButton } from '@material-ui/core';
import LabsMenu from "./LabsMenu";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loadTests, addParams, searchMode,toggleLoading } from "../../actions/TestActions";
import { toggleLabs } from "../../actions/LabActions";
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from "@material-ui/icons/Close";
import { TuneOutlined } from '@material-ui/icons';



const useStyles = makeStyles(theme => ({
	root: {
		// flexGrow: 1
	},
	control: {
		padding: theme.spacing(2)
	},
	button:{
		width: "150px",
		margin: theme.spacing(1)
	},
	label: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	  }
}))

function MobileFilters(props) {
    const { control, handleSubmit, register, errors, reset } = useForm({ ...props.params })
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const applyFilters = (params) => {
		const _params = cleanParams(params);
		_params["start"] = 0;
		props.toggleLoading(true)
		props.addParams({ ...props.params, ..._params });
		
	};
	const onSubmit = (data) => {

		applyFilters(data);
	};
	const cleanParams = (params)=> {
		let _cleanedParams = {};
		Object.keys(params).forEach(k => {
			if (params[k] === "" || params[k] === null || !params.hasOwnProperty(k)) {
				delete params[k];
			} else {
				_cleanedParams[k] = params[k];
			}
		});
		return _cleanedParams;
	};

	const removeStateFields = (params, stateParams)=>{
		let _filteredParams = {};
		Object.keys(params).forEach(k => {
			if (props.handbookFilters.includes(k)) {
				delete params[k];
			} else {
				_filteredParams[k] = params[k];
			}
		});
		return _filteredParams;
	};
	
	const clearFilters = () => {
		
		let _params = cleanParams(
			removeStateFields(
				{ ...props.params },
				{}
			)
		);
		if (props.search_mode === false) {
			_params["start"] = 0;
		}
		let emptyObj ={};
		props.handbookFilters.forEach(f =>{
			emptyObj[f]="";
		})
		reset(emptyObj);
		props.addParams(_params);
		props.toggleLoading(true)
		props.toggleLabs([]);
	};

	const handleLabs= (labs) =>{
		props.toggleLabs(labs)
		let _params = {...props.params}
		_params["start"] = 0;
		_params["limit"]=10;
		props.addParams(_params)
	}

	const getLabel = filter =>{
		// let label ="";
		// Object.keys(props.test_details_categories.All).forEach(k=>{
		// 	if(props.test_details_categories.All[k] === filter){
		// 		label = k
		// 	}
		// })
		// return label;
		if (filter == "synonymName") {
			return "Synonym Name";
		}
		return props.test_details_categories.All[filter];
	}
    const handleToggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    const classes = useStyles();
    return (
        <div>
            <IconButton onClick={handleToggleDrawer}>
                {isDrawerOpen ? <TuneOutlined color='secondary'/> : <TuneOutlined color='primary'/> }
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} style={{width:"400px"}}>
			<IconButton aria-label="delete" onClick={()=>{setDrawerOpen(false)}}>
 				 <CloseIcon />
			</IconButton>
            <form method="post" onSubmit={handleSubmit(onSubmit)} >
                <List>
                    <ListItem>
                        <LabsMenu toggleLabs={handleLabs} labs={props.labs} checkedlabs={props.checkedlabs}/>
                    </ListItem>
                    {props.advanced_search_fields.map(filter=>(
						<ListItem>
							<Controller as={
								<TextField
								id="outlined-soft-order-input"
								label={getLabel(filter)}
								type="text"
								name={filter}
								ref={register}
								className={classes.label}
								variant="outlined"
								helperText={`Filter by ${getLabel(filter)}`}
							/>
							}	
							name={filter}
							type="text"
							control={control}
							defaultValue=""/>
						</ListItem>
					))}
                    <ListItem>
                            <Button
									variant="contained"
									type="submit"
									size="medium"
									color="primary"
									className={classes.button}
									onClick={handleSubmit(onSubmit)}>
									Apply Filters
							</Button>
                    </ListItem>
                    <ListItem>
                            <Button
									variant="contained"
									size="medium"
									color="secondary"
									className={classes.button}
									onClick={clearFilters}>
									Clear Filters
							</Button>
                    </ListItem>
                    {/* Add more text fields as needed */}
                </List>
                </form>
            </Drawer>
        </div>
    );
}

MobileFilters.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	user:state.user,
	params: state.params,
	checkedlabs: state.checkedlabs,
	labs: state.labs,	
	search_mode: state.search_mode,
	advanced_search_fields:state.advanced_search_fields,
	test_details_categories: state.test_details_categories,
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ loadTests, addParams, toggleLabs, searchMode,toggleLoading }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileFilters);
