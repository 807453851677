import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip"
import RejectIcon from "@material-ui/icons/ClearSharp"
import CheckIcon from "@material-ui/icons/CheckSharp"
import EditIcon from "@material-ui/icons/EditSharp";

import VisibiltyIcon from "@material-ui/icons/VisibilitySharp";
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ViewChangesDialog from "./viewChangesDialog"
import { connect } from "react-redux";
import { getAccess } from "http_calls/userHttp"
import { bindActionCreators } from "redux";
import EditPendingTestsModal from "./editPendingTests";
import FormDialog from "../formDialogComponent"
import DatePickerField from "../DatePickerField"
import { updatePendingTests, approveTest, rejectTest } from "http_calls/fetchFuncs";
import CustomTextField from '../TextFieldComponent';
import {
    toggleNotification,
    notificationMessage,
} from "actions/TestActions";
import CLIAStatusIndicator from "../cliaStatusIndicator"
import { PortableWifiOff } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    card: {
        width: "26vw",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    deleteMsg: {
        color: "red",
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    margin: {
        margin: theme.spacing(1),
    },
}));
function createdDate(editDate) {
    var local_date = moment.utc(editDate).local().format('YYYY-MM-DD, HH:mm a');
    return local_date
}


function printDraftName(draftName) {
    if (draftName.length < 1) {
        return ""
    }
    if (draftName.match(/^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i)) {
        return "Draft started at " + moment.utc(draftName).local().format('YYYY-MM-DD, HH:mm a')
    }
    return "Draft Name :" + draftName
}

function TestPreviewCard(props) {

    const classes = useStyles()
    const [openModal, setOpenModal] = useState(false)
    const [effectiveDate, setEffectiveDate] = useState(props.test.EffectiveDate)
    const [testPerformedLocations, setTestPerformedLocations] = useState({})
    const [confirmationState, setConfirmationState] = useState({
        open: false,
        title: 'Are you sure you want to Approve ?',
        content: 'You cannot undo this action, Are you sure you '
    })

    const [reasonFieldErr, setReasonFieldError] = useState(false)

    let reason = "";

    useEffect(() => {
        if (props.test.PerformedLocations) {
            setTestPerformedLocations(props.test.PerformedLocations)
        }

    }, [props.test])

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const handleEffectiveDateChange = (e) => {
        setEffectiveDate(e.target.value)
    }
    const submitEffectiveDate = () => {
        let editedTest = { ...props.test }
        editedTest["EffectiveDate"] = moment(effectiveDate).format()
        submitPendingTestChange(editedTest, 'staging')
        handleCloseModal();
        props.refresh();
    }
    const handlePendingTestChange = (editedTest) => {
        let type = "lab_approval"
        if (props.category === "Pending CLIA approval") {
            type = "clia_approval"
        }
        submitPendingTestChange(editedTest, type)
        handleCloseModal();
        props.refresh();
    }

    const handleOpenConfirmation = (stateType) => {
        if (stateType === "approve") {
            setConfirmationState({
                open: true,
                handleClose: handleCloseConfirmation,
                title: `Confirmation for approving test ${props.test.TestName}`,
                content: ` you cannot undo this process once the change is approved.`,
                disAgreeButtonText: "cancel",
                agreeButtonText: "Approve",
                submit: approvePendingTest,
            })
        }
        if (stateType === "reject") {
            setConfirmationState({
                open: true,
                handleClose: handleCloseConfirmation,
                title: `Are you sure? you want to reject  test ${props.test.TestName}`,
                content: ` you cannot undo this process once the change is rejected.`,
                disAgreeButtonText: "cancel",
                agreeButtonText: "Reject",
                submit: rejectPendingTest,
                field: <CustomTextField
                    error={reasonFieldErr}
                    callback={handleReasonChange}
                    placeholder={"Please enter reason to reject."}
                    multiline={true}
                    rows={2}
                    label={"Reason for Rejecting"}
                />,
            })
        }


    }
    const handleReasonChange = (val) => {
        reason = val;
    }


    const handleCloseConfirmation = () => {
        setConfirmationState({
            open: false,
        });
    }

    const approvePendingTest = () => {

        let type = ""
        if (props.category === "Lab Manager Approval") {
            type = "lab_approval"

        }
        if (props.category === "Pending CLIA approval") {
            type = "clia_approval"
        }
        approveTest(`/pending/tests/approval/${props.user.uname}/${props.test.TestId}/${type}`).then(res => {

            if (res) {
                props.toggleNotification(true)
                props.notificationMessage(res.data.Msg)
                props.refresh()
            }

        }).catch(err => {
            console.log(err);
        })


        handleCloseConfirmation();
    }

    const rejectPendingTest = () => {
        let char_length = 10
        if (reason.length > char_length) {
            let type = ""
            if (props.category === "Lab Manager Approval") {
                type = "lab_approval"

            }
            if (props.category === "Pending CLIA approval") {
                type = "clia_approval"
            }

            let jsonData = { reason }
            rejectTest(`/pending/tests/reject/${props.user.uname}/${props.test.TestId}/${type}`, jsonData).then(res => {
                props.refresh()
                props.toggleNotification(true)
                props.notificationMessage(res.data.Msg)

            }).catch(err => {
                console.log(err);
            })


            handleCloseConfirmation();
        } else {
            props.toggleNotification(true)
            props.notificationMessage(`Please enter reason for rejection, reason should be more than ${char_length} characters. `)
        }


    }



    const submitPendingTestChange = (editedTest, type) => {
        updatePendingTests(`/pending/tests/edit/${props.user.uname}/${props.test.TestId}/${type}`, editedTest).then(res => {
            props.toggleNotification(true)
            props.notificationMessage(res.data.Msg)
        }).catch(err => { console.log(err) })
    }

    const renderEditPendingTests = () => {
        if (props.category === "Waiting for Effective date") {
            return (
                <FormDialog
                    open={openModal}
                    title={"Please enter Effective"}
                    handleClose={handleCloseModal}
                    content={""}
                    handleSubmit={submitEffectiveDate}
                    confirmButtonText={"Submit"}
                    cancelButtonText={"Cancel"}
                >
                    <DatePickerField effectiveDate={props.test.EffectiveDate} onChange={handleEffectiveDateChange} />
                </FormDialog>
            )
        }
        return (
            <EditPendingTestsModal
                test={props.test}
                open={openModal}
                closeModal={handleCloseModal}
                handlePendingTestChange={handlePendingTestChange} />
        )

    }

    const getApprovalAccess = () => {
        if (props.category === "Pending CLIA approval") {
            let access = false
            
            Object.keys(testPerformedLocations).forEach(location => {
                console.log("inside get approval access", testPerformedLocations[location] , props.userApprovalLocations.indexOf(location))
                if (testPerformedLocations[location] === true) {
                   
                    if (props.userApprovalLocations.indexOf(location) > -1) {
                        access = true
                    }
                }
            })
            return access
        }
        if (props.category === "Lab Manager Approval") {
            return props.labApprovalAccess
        }
    }

    const GetApproveButton = () => {
        return <Button size="small" color={"primary"} variant="outlined" className={classes.margin} onClick={() => { handleOpenConfirmation("approve") }}><CheckIcon /> Approve</Button>
    }

    const GetRejectButton = () => {
        return <Button size="small" color={"secondary"} variant="outlined" className={classes.margin} onClick={() => { handleOpenConfirmation("reject") }}><RejectIcon /> Reject</Button>
    }

    const getViewChangesDialog = () => {
        if (props.test.Deleted_at) {
            return <span />
        }
        return <ViewChangesDialog test={props.test} approveButton={GetApproveButton()} rejectButton={GetRejectButton()} access={getApprovalAccess()} />



    }


    return (
        <Card className={classes.card}>
            <CardHeader
                title={props.test.TestName}
                subheader={props.test.Lab}
                action={getViewChangesDialog()}
            />

            {props.test.Deleted_at ?
                <CardContent>
                    {props.category === "Pending CLIA approval" ? <CLIAStatusIndicator testPerformedLocations={testPerformedLocations} /> : <span />}
                    <Typography className={classes.deleteMsg} coloe="secondary" variant="h5" component="p">
                        Request for Deleting this Test from Handbook.
                    </Typography>

                    <Typography className={classes.title} variant="h5" component="p">
                        Edited at :  {createdDate(props.test.EditDate)}
                    </Typography>
                    <Typography variant="body2" component="p" color="textSecondary">
                        created by {props.test.Author}
                    </Typography>
                </CardContent>
                :
                <CardContent>
                    {props.category === "Pending CLIA approval" ? <CLIAStatusIndicator testPerformedLocations={testPerformedLocations} /> : <span />}


                    <Typography className={classes.title} variant="h5" component="p">
                        Effective Date: {props.test.EffectiveDate}
                        <br />
                        Edited at :  {createdDate(props.test.EditDate)}
                    </Typography>
                    <Typography variant="body2" component="p" color="textSecondary">
                        created by {props.test.Author}
                    </Typography>
                </CardContent>
            }

            <Divider />
            {props.test.Deleted_at ? <span /> : <CardActions>
                {getApprovalAccess() ? <div>
                    {GetApproveButton()}
                    {GetRejectButton()}
                </div> : <span />}
                {props.labApprovalAccess ? <Button size="small" color={"primary"} variant="contained" onClick={handleOpenModal} ><EditIcon /> Edit</Button> : <span />}
            </CardActions>}


            <FormDialog
                open={confirmationState.open}
                title={confirmationState.title}
                handleClose={confirmationState.handleClose}
                content={confirmationState.content}
                handleSubmit={confirmationState.submit}
                confirmButtonText={confirmationState.agreeButtonText}
                cancelButtonText={confirmationState.disAgreeButtonText}>
                {confirmationState.field}
            </FormDialog>
            {renderEditPendingTests()}

        </Card>
    );
}

const mapPropsToState = state => ({
    user: state.user,
});
const mapDispatchProps = dispatch => {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
};
export default connect(mapPropsToState, mapDispatchProps)(TestPreviewCard)
