import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment, { isMoment } from "moment"
const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function DatePickerField(props) {
    const classes = useStyles();

    return (
        <TextField
            id="date"
            label="Effective date"
            type="date"
            defaultValue={props.effectiveDate}
            className={classes.textField}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={props.onChange}
        />
    );
}
