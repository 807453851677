import React,{useState,useEffect} from "react";
import { Avatar,makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import deepOrange from "@material-ui/core/colors/deepOrange";
import ProfileMenu from "./userProfileMenu";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	loggingUser,
	toggleNotification,
	notificationMessage
} from "../actions/TestActions";
import { logoutUser } from "../../httpCalls/userHttp";
import { IconButton } from "@material-ui/core";


const useStyles = makeStyles(theme => {
	return {
		orangeAvatar: {
			margin: 10,
			color: theme.palette.common.white,
			backgroundColor: deepOrange[500]
		},
		ProfileContainer:{
			paddingRight:"30px",
			marginRight:"12px	"
		}
	}
})
function UserAvatar(props){
	const classes = useStyles();
	const [open,setOpen] = useState(false)

	const handleClick = () => {
		setOpen(true)
	};

	const handleClose = () => {
		setOpen(false)
	};

const handleLogout = () => {
		logoutUser().then(msg => {
			props.toggleNotification(true);
			props.notificationMessage(msg)
			props.loggingUser({})
			window.location.reload(false);
		})
		handleClose();
	};

		return (
			<div>
				<IconButton onClick={handleClick}>
					<Avatar className={classes.orangeAvatar}>{props.str}</Avatar>
				</IconButton>
				< div className={classes.ProfileContainer}>
					<ProfileMenu
						open={open}
						close={handleClose}
						logout={handleLogout}
					/>
				</div>
			</div>
		);

}


const mapStateToProps = state => ({});

const mapDispatchProps = dispatch => {
	return bindActionCreators(
		{ loggingUser, toggleNotification, notificationMessage },
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchProps
)(UserAvatar);
