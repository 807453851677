import React from "react";
import ReactDOM from "react-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import propTypes from "prop-types";
import blue from "@material-ui/core/colors/blue";
import { withStyles } from "@material-ui/core";
import SearchBar from "./SearchBar";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandIcon from "@material-ui/icons/ExpandMoreRounded";
import Button from "@material-ui/core/Button";
import LabDrawer from "./LabDrawer";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import { Component } from "react";
import LabsMenu from "./LabsMenu";
import AdvancedSearch from "./AdvancedSearch";
import Typography from '@material-ui/core/Typography';
import Loading from "../loading";

const styles = theme => ({
	cardBackgroundColor: {
		backgroundColor: theme.palette.primary.light,
		minHeight: "10vh",
        minWidth: "100vw",
		height: "15vh",
        padding:'2%',
	},
	button: {
		margin: theme.spacing(1)
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
});

class Hero extends React.Component {
	constructor(props) {
		super(props);

		// this.handleOpenLabsList=this.handleOpenLabsList.bind(this);
	}

	render() {
		const { classes } = this.props;
		return (
			<Card>
				
				<CardContent
					className={classnames(
						classes.cardBackgroundColor,
						classes.flexRowJustify
					)}>
					<AdvancedSearch handbookFilters={["CoePublish","CoeMichart","synonymName"]} />
				
				</CardContent>
				{/* <Loading/> */}
			</Card>
		);
	}
}

Hero.propTypes = {
	classes: propTypes.object.isRequired
};

export default withStyles(styles)(Hero);
