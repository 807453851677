import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
 List,
 ListItemText,
 ListItem,
 Link,
 Typography
} from "@material-ui/core";


function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  
function DocViewComponent(props){

    return (
        <List component='nav'>
            {props.docs?props.docs.map(doc => (
                <Typography>

                    <Link href={`/api/v1/document/view?uname=${props.user.uname}&lab=${props.lab}&fileName=${doc.fileName}`} target="_blank">
                        {doc.fileName}
                    </Link>
                </Typography>
            )):<span/>}
        </List>
    )
}


export default DocViewComponent