import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    makeStyles,
    Button,
    TextField,
    Tooltip,
    Typography,
    Paper,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import AttachIcon from "@material-ui/icons/AttachFileSharp"
import DetachIcon from "@material-ui/icons/RemoveCircleSharp"
import is from "is_js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { makeArrayUnique } from "helperFunctions"
import ImageViewer from "./ImageViewer"


const useStyles = makeStyles(theme => ({

    gridScroll: {
        overflowY: "scroll",
        height: "60vh",
        width:"90vw",
    },
    icon: {
        color: theme.palette.common.white,
    },
    title: {
        color: theme.palette.common.white,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    marginTopDiv: {
        // marginTop: "35vh"
    },
    Image: {
        height: "300px",
        objectFit: "contain",
        cursor: "zoom-in",
    }
}));


function SelectContainerImages(props) {
    const [AllContainers, setAllContainers] = useState(props.containers)
    const [searchValue, setSearchValue] = useState("")
    const [zoomedPic, setZoomedPic] = useState(false)
    const [bigImage, setBigImage] = useState("")
    const classes = useStyles();

    const getUrl = (file_name,base_url) => {
        if (is.not.ie()) {
            return base_url+file_name

        }
        return base_url+file_name.replace(".webp", ".jpg")

    }

    useEffect(() => {
        let _containers = props.containers.filter(container => {
            return (container.container_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        })
        setAllContainers(_containers)
    }, [searchValue])

    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    const openBigPicture = (img) => {
        setBigImage(img)
        setZoomedPic(true)
    }

    const closeBigPicture = () => {
        setZoomedPic(false)
    }

    const attach = (container) => {
        let _containers = [...props.testContainers].filter(c => {
            return container.container_name !== c.container_name
        })

        _containers.push(container)
        props.changeContainers(makeArrayUnique(_containers))

    }

    const detach = (container) => {
        let _containers = [...props.testContainers]
        _containers.splice(_containers.indexOf(container), 1)
        props.changeContainers(makeArrayUnique(_containers))
    }

    return (
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
            <Grid item container direction="column" justify="center" alignItems="stretch" spacing={1} xs={12}>
                <div >
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            <center>
                                Current Test container
                            </center>

                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GridList cellHeight={300} cols={3} spacing={3} >
                            {props.testContainers.map(container => (
                                <GridListTile key={container.container_name}>
                                    <img src={getUrl(container.file_name)} alt={container.container_name} className={classes.Image} loading="lazy" onClick={() => { openBigPicture(container.file_name) }} />
                                    <GridListTileBar
                                        title={container.container_name}
                                        classes={{ root: classes.titleBar, title: classes.title }}
                                        actionIcon={
                                            <Tooltip title={"Detach this container from the test"}>
                                                <Button size="small" variant="contained" color="secondary" onClick={() => { detach(container) }}> <DetachIcon /> Detach</Button>
                                            </Tooltip>
                                        } />
                                </GridListTile>
                            ))}
                        </GridList>
                    </Grid>

                </div>
            </Grid>
            <Grid item container direction="column" spacing={3} justify={"space-around"} alignItems={"stretch"} xs={12} >

                <Grid item >
                    <TextField label="Search Containers" variant="filled" fullWidth="true" value={searchValue} onChange={handleChange} placeholder={"Start typing container name here ......"} />
                </Grid>
                                        <Grid item>
                                        <Typography variant="h4" gutterBottom>
                        All Containers
                    </Typography>
                                        </Grid>
                <Grid item container direction="row" justify="center" alignItems={"center"} spacing={2}>
                    
                    <GridList cellHeight={400} cols={3} spacing={2} className={classes.gridScroll}>
                        {AllContainers.map(container => (
                            <GridListTile key={container.container_name}  >
                                <img src={getUrl(container.base_url+container.file_name)} alt={container.container_name} className={classes.Image} loading={"lazy"} onClick={() => { openBigPicture(container.file_name) }} />
                                <GridListTileBar
                                    title={container.container_name}
                                    classes={{ root: classes.titleBar, title: classes.title }}
                                    actionIcon={
                                        <Tooltip title="Attach this container to the Test ">
                                            <Button size="small" variant={"contained"} color="primary" onClick={() => { attach(container) }}> <AttachIcon /> Attach</Button>
                                        </Tooltip>
                                    }
                                />
                            </GridListTile>
                        ))}
                    </GridList>

                </Grid>
            </Grid>
            <ImageViewer open={zoomedPic} image={getUrl(bigImage)} onClose={closeBigPicture} />

        </Grid>
    )
}


const mapPropsToState = state => ({
    containers: state.containers
})

const mapDispatchProps = dispatch => {
    return bindActionCreators(
        {  }, dispatch
    )
}


export default connect(mapPropsToState, mapDispatchProps)(SelectContainerImages);
