import { all, fork } from 'redux-saga/effects';
import  watchActionsAndFetchTests  from './watchParamsSaga';
import watchSearchSaga from './watchSearchSaga';

function* rootSaga() {
  yield all([
    // Fork other sagas here
    fork(watchActionsAndFetchTests),
    fork(watchSearchSaga),
    // You can add more sagas here using fork
  ]);
}

export default rootSaga;
