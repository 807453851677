export const loadTests = payload => ({
	type: "loadTests",
	payload: payload
});

export const loadParams = payload => ({
	type: "loadParams",
	payload: payload
});

export const addParams = payload => ({
	type: "addParams",
	payload: payload
});

export const toggleLoading = payload => ({
	type: "toggleLoading",
	payload: payload
});

export const loggingUser = payload => ({
	type: "loggingUser",
	payload: payload
});

export const toggleNotification = payload => ({
	type: "toggleNotification",
	payload: payload
});

export const notificationMessage = payload => ({
	type: "notificationMessage",
	payload: payload
});

export const addDraft = payload => ({
	type: "addDraft",
	payload: payload
});

export const testDetails = payload => ({
	type: "testDetails",
	payload: payload,
})
export const numRecords = payload => ({
	type: "numRecords",
	payload: payload,
})

export const searchMode = payload => ({
	type: "searchMode",
	payload: payload,
})
export const searchText = payload => ({
	type: "searchText",
	payload: payload,
})
export const fieldTypes = payload => ({
	type: "fieldTypes",
	payload: payload,
})
export const setPage = payload => ({
	type: "setPage", 
	payload: payload,
})
export const setRowsPerPage = payload =>({
	type: "setRowsPerPage",
	payload: payload,
})

