import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    AppBar,
    Typography,
    Grid,
    Button,
    Slide,
    FilledInput,
    MenuItem,
    IconButton,
    DialogActions,
    DialogContent,
    TextField,
    Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { getUserLabs } from "http_calls/userHttp";
import { getLabSectionsJson } from "http_calls/fetchFuncs";
import FormDialog from "../formDialogComponent";
import { DropzoneArea } from "material-ui-dropzone";
import {
    loggingUser,
    toggleNotification,
    notificationMessage,
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment';
import SectionList from './sectionList';


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        padding: 25,

    },
    Button:{
        margin:"2px",
    }

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let prePopulatedData={};

function EditDialog(props) {
    const [search, setSearch] = useState("");
    const [sectionsLabs,setSectionsLabs] = useState({})

  const classes= useStyles();

    useEffect(()=>{
        getLabSectionsJson().then(data=>{
            prePopulatedData = {...data.Data}
       setSectionsLabs(prePopulatedData)
       })
    },[])

    const handleSearchChange = (e)=>{
       let _search = e.target.value;
        setSearch(_search)
        if (_search.length > 1){
            let _keys = Object.keys(sectionsLabs)
            let _sectionsLab ={}
            _keys.filter(k=>{
                return k.toLowerCase().indexOf(_search.toLowerCase()) > -1
            }).forEach(k=>{
                _sectionsLab[k] = sectionsLabs[k]
            })
            setSectionsLabs(_sectionsLab)


        }
        else{
            setSectionsLabs(prePopulatedData)
        }
}
    

    return (
        <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Edit Sections and Labs
                </Typography>
                    <Button color="inherit" onClick={props.handleClose}>
                        Close
                </Button>
                </Toolbar>
            </AppBar>
            <DialogContent>

                <Grid container direction="row" alignContent="center" justify="center" spacing={4}>
                    <Grid item xs={12}>
<TextField value={search} onChange={handleSearchChange} fullWidth variant="outlined" placeholder="Type here to search sections" label="Search"/>
                        </Grid>
                    <Grid item xs={12}>
                        <SectionList labs={sectionsLabs} setLabs={setSectionsLabs}/>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
       
    )
}
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDialog)