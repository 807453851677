import React,{useState} from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {makeStyles} from "@material-ui/core/styles";
import LabListItem from "./labsList";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import SortComponents from "../../components/SortComponents"

const useStyles = makeStyles(theme=>({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	menu: {
		width: "100%"
	}
})) 

function LabsMenu(props) {
	const classes = useStyles();

	const [open,setOpen] = useState(false)

	const handleToggle = () => {
		setOpen(!open)
	};

	const handleClose = event => {
		if (this.anchorEl.contains(event.target)) {
			return;
		}
		setOpen(false)
	};

	const renderLabsList = () => {
		let labs = [];
		props.labs.map(lab => {
			labs.push(<LabListItem checked={props.checkedlabs.indexOf(lab) !== -1} lab={lab} key={lab} toggleLabs={props.toggleLabs} checkedlabs={props.checkedlabs} />)
		})
		return labs;
	}

		return (
			<TextField
				select
				label="Select Labs"
				SelectProps={{
					MenuProps: {
						className: classes.menu
					},
					value: ""
				}}
				variant="filled"
				helperText="Choose labs to filter tests">
				{/* {this.props.labs.map(lab => (
					<LabListItem  lab={lab} key={lab}/>
				))} */}
				<SortComponents by="lab">
					{renderLabsList()}
				</SortComponents>

			</TextField>
		);
	
}

export default LabsMenu;
