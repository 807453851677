import React,{useState} from 'react';
import {Switch} from "@material-ui/core"

function CustomSwitch(props){
    const [checked,setChecked] = useState(props.checked)

    const onChange=()=>{
        let _val= !checked
        let _fieldObj= {...props.fieldObj}
        
        _fieldObj[props.switchKey]= _val
        props.handleFieldEdits(props.fieldName, _fieldObj)
    }
    return(
        <Switch
            checked={checked}
            onChange={onChange}
            color="primary"

        />
    )
}

export default CustomSwitch