import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import EditIcon from "@material-ui/icons/EditSharp"
import EditPermissionsForm from "./editPermissionForm"
/*
   role:{
        clia_approval: false
        edit_page_layout: false
        finalize_request_change: false
        labs: []
        major_request_change: false
        name: "lab_tech"
        request_change: true
        synonym_ops: false
        view_all_lab_tests: false
    }
*/

export default function EditPermissions(props) {
    const [open, setOpen] = useState(false)

    const handleClose= ()=>{
        setOpen(false)
    }

    return (
        <div>
            <Button variant="contained" size="small" color="primary" onClick={() => { setOpen(true) }} ><EditIcon /> Edit permissions </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
                <DialogTitle id="form-dialog-title">{`Permissions of ${props.user.FirstName} ${props.user.LastName} `}</DialogTitle>
                <DialogContent>
                    <EditPermissionsForm {...props} handleClose={handleClose}/>
                </DialogContent>
              
            </Dialog>
        </div >
    )
}


