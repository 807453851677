import React, { useEffect, useState } from 'react'
import { Grid, Avatar, Typography, Link, IconButton, TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormDialog from "../formDialogComponent";


const useStyles = makeStyles(theme => {
  return {
    avatar: {
      margin: 10,
    },
    greenAvatar: {
      margin: 10,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
    textField:{
      margin:" 3% 0"
    },
    buttons:{
      marginLeft : "15%",
    }
  }

});

export default function Card(props) {
  const [link, setLink] = useState({
    name: props.name,
    url: props.url,
    pos: props.id,
  })
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    // props.handleLinkEdit
    props.handleLinkEdit(link)
    handleClose();
  }

  const handleUrlChange= (e) => {
    setLink({ ... link, url:e.target.value})
  }
  const handleLinkName  = (e) => {
    setLink({...link, name : e.target.value})
  }
  const handleDetele =()=>{
    props.handleLinkDelete(link)
  }
  const classes = useStyles();
  return (
    <Grid container item direction="row" justify="flex-start" alignItems="center">
        {props.icon}
        <Link variant="a" href={props.url} rel="noopener" target="_blank"> {props.name}</Link>
      {props.editAble ? 
        <div className={classes.buttons}>
            <IconButton color="primary" size="small" onClick={handleOpen}><EditIcon /></IconButton>
            <IconButton color="secondary" size="small" onClick={handleDetele}><DeleteIcon/></IconButton>
        </div> : 
        <span />} 
      <FormDialog
        open={open}
        title={"Edit Link information"}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        confirmButtonText={"Save"}
        cancelButtonText={"Cancel"}
      >
        <TextField
          id="link-edit-form-name"
          label="Link Display Name"
          helperText="Enter Name of the Link, this will be displayed to users"
          type="text"
          className={classes.textField}
          fullWidth={true}
          value={link.name}
          onChange={handleLinkName}
        />

        <TextField
          id="link-edit-form-url"
          label="Link URL"
          helperText="Please enter or paste full URL here."
          fullWidth={true}
          className={classes.textField}
          type="text"
          value={link.url}
          onChange={handleUrlChange}
        />

      </FormDialog>
    </Grid>
  )
}