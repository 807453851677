import React,{useState,useEffect} from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Avatar,
    Typography,
    makeStyles,
    Button,
    Grid,
} from '@material-ui/core';
import deepOrange from "@material-ui/core/colors/deepOrange";
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import FormDialog from "../formDialogComponent";
import CustomDropDown from "./dropdown";
import CustomSwitch from "./switch"


const useStyles = makeStyles(theme => {
    return {
        orangeAvatar: {
            margin: 10,
            color: theme.palette.common.white,
            backgroundColor: deepOrange[500]
        },
        cardContentStyles:{
            minWidth:"70vw",
            padding:"2px",
        },
        cardStyles :{
            margin:"3px"
        }

    }
})

function FieldCard(props){
    const classes= useStyles();

    return(
        <div>
                {Object.keys(props.fields).map(k=>(
                    <Card key={k} className={classes.cardStyles}>
                    <CardContent  className={classes.cardContentStyles}>
                     <Grid container 
                         direction="row" 
                         justify="space-between"
                         alignItems="stretch"
                         spacing={3}>  
                        <Grid item container sm={4} justify="center" alignContent="center" alignItems="center">   
                            <Grid item >
                            <Typography variant="subtitle1">
                                {props.fields[k]}
                            </Typography>
                            </Grid>
                        </Grid>
                         <Grid item sm={2}> 
                            <CustomDropDown type={props.testFieldTypes[k].type} handleFieldEdits={props.handleFieldEdits} fieldName={k} fieldObj={props.testFieldTypes[k]}/>
                        </Grid>
                        <Grid item container sm={2} justify="center" alignContent="center" alignItems="center">
                            <Grid item>
                            <Typography variant="body1" justify="center">
                                { "Public field"}
                                <CustomSwitch checked={props.testFieldTypes[k].public} switchKey={"public"} handleFieldEdits={props.handleFieldEdits} fieldName={k} fieldObj={props.testFieldTypes[k]}/>
                            </Typography>   
                            </Grid>
                        </Grid>
                        <Grid item container sm={2} justify="center" alignContent="center" alignItems="center">
                            <Grid item>
                            <Typography variant="body1" justify="center">
                                { "Major Change" }
                                {k === "Document"? console.log(props.testFieldTypes[k]):<span/>}
                                <CustomSwitch checked={props.testFieldTypes[k].majorChange} switchKey={"majorChange"} handleFieldEdits={props.handleFieldEdits} fieldName={k} fieldObj={props.testFieldTypes[k]}/>
                            </Typography>   
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                </Card>
                ))}
            </div>
    )
}

export default FieldCard;
