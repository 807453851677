import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { FetchSectionForLab } from "http_calls/fetchFuncs"
const useStyles = makeStyles(theme => ({
	labSelect: {
		width: "50%",
		height: "200%",
		padding: "1% 5% 1% 5%"
	}
}));
const ITEM_HEIGHT = 108;
const ITEM_PADDING_TOP = 8;

function SelectComponent(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [selectedoption, setSelectedoption] = useState(props.selectedValue);

	function handleChange(event) {
		setSelectedoption(event.target.value);
		props.onChange(props.field, event.target.value)
	}

	return (
		<Select
			value={selectedoption}
			className={classes.labSelect}
			onChange={handleChange}
			variant="outlined"
			inputProps={{
				name: "lab",
				id: "lab-name"
			}}>
			{props.options.map(lab => (
				<MenuItem value={lab}>{lab}</MenuItem>
			))}
		</Select>
	);
}

export default SelectComponent;
