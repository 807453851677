import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas/rootSaga";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const buildCompose = () => {
    const composeEnhancers = 
        typeof window === 'object' && 
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose;

    // Combine middlewares
    const enhancer = composeEnhancers(
        applyMiddleware(sagaMiddleware)
        // other store enhancers if any
    );

    return enhancer;
};

export default function buildStore(reducer) {
    const preloadedState = typeof window !== "undefined" && window && window.__PRELOADED_STATE__;
    
    // Create store
    const store = createStore(
        reducer,
        preloadedState,
        buildCompose()
    );

    // Then run the saga middleware
    sagaMiddleware.run(rootSaga);

    return store;
}
