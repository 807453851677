import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { Radio, Grid, Typography, FormControlLabel } from '@material-ui/core';
import { POINT_CONVERSION_COMPRESSED } from 'constants';



export default function RadioButtons(props) {
    const [selectedValue, setSelectedValue] = React.useState(props.option1);

    function handleChange(event) {
        setSelectedValue(event.target.value);
        props.onChange(props.field, event.target.value)
    }


    return (
        <Grid container direction="row" justify="center">
            <Grid item xs={6}>
                <FormControlLabel
                    value="top"
                    control={<Radio
                        checked={selectedValue === props.option1}
                        onChange={handleChange}
                        value={props.option1}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />}
                    label={props.option1}
                    labelPlacement="end"
                />
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    value="top"
                    control={<Radio
                        checked={selectedValue === props.option2}
                        onChange={handleChange}
                        value={props.option2}
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />}
                    label={props.option2}
                    labelPlacement="end"
                />
            </Grid>
        </Grid>
    );
}