import React, { useEffect,useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleLabs } from "../../actions/LabActions";
import { loadTests, addParams } from "../../actions/TestActions";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing(1)
	}
}));

//chips
function LabChips(props) {
	const classes = useStyles();
	const [labs,setLabs]= useState([])

	useEffect(()=>{
		if(props.selectedLabs){
			setLabs(props.selectedLabs)
		}else {
			setLabs(props.labs)
		}
	},[props.labs,props.selectedLabs])

	function handleDelete(data) {
		let _labs = [...labs];
		let currentIndex = _labs.indexOf(data);
		if (currentIndex > -1) {
			_labs.splice(currentIndex, 1);
			//remove labs on delete
			props.toggleLabs(_labs);
		}
	}

	function renderChip(data, classes, handleDelete) {
		return (
			<Chip
				avatar={<Avatar>LB</Avatar>}
				key={data}
				label={data}
				color={"primary"}
				onDelete={()=>{props.removeSelectedLabs? props.removeSelectedLabs(data):handleDelete(data)}}
				className={classes.chip}
				data-chip={data}

			/>
		);
	}
	return (
		<div className={classes.root}>
			{labs.map(data => {
				return renderChip(data, classes, handleDelete);
			})}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		labs: state.checkedlabs,
		params: state.params
	};
};
const mapDispatchToProps = dispatch => {
	return bindActionCreators({ toggleLabs, loadTests, addParams }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LabChips);
