import React,{useState,useEffect} from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Avatar,
    Typography,
    makeStyles,
    Button,
    Grid,
} from '@material-ui/core'
import deepOrange from "@material-ui/core/colors/deepOrange";
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import FormDialog from "../formDialogComponent";
import EditPermissions from "./editPermissionsDialog"

const useStyles = makeStyles(theme => {
    return {
        orangeAvatar: {
            margin: 10,
            color: theme.palette.common.white,
            backgroundColor: deepOrange[500]
        }
    }
})
function UserCard(props) {

    const classes = useStyles();
    const[open,setOpen] = useState(false)

    const getUserAvatar = () => {
        return (
            <Avatar className={classes.orangeAvatar}>{props.user.Username.charAt(0).toUpperCase()}</Avatar>
        )
    }
    return (
        <Card>
            <CardContent>
                <Grid container 
                    direction="row" 
                    justify="space-evenly"
                    alignItems="stretch"
                    spacing={2}>   
                    <Grid item xs={3} container direction="row" justify="center" alignItems="center">
                        <Grid item>
                            {getUserAvatar()}
                        </Grid>
                        <Grid item>
                            <Typography> {props.user.FirstName + " " + props.user.LastName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography>{props.user.Username}</Typography>
                    </Grid>
                    <Grid item>
                       <EditPermissions user={props.user} allLabs={props.allLabs} refreshUsers={props.refreshUsers}/>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" size="small" color="secondary" onClick={()=>{setOpen(true)}}><DeleteIcon /> Delete user </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <FormDialog
                open={open}
                handleSubmit={()=>{props.onDelete(props.user.Username)}}
                handleClose={()=>{setOpen(false)}}
                title={`Are you sure you want to delete ${props.user.FirstName} ${props.user.LastName} from handbook?  `}
                confirmButtonText={"Delete"}
                cancelButtonText={"Cancel"}/>

        </Card>
    )
}



export default UserCard;