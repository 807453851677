import { takeEvery, call, put, all, fork , select} from 'redux-saga/effects';
import { SearchTests } from '../../httpCalls/fetchFuncs';


const SET_TESTS = 'loadTests';
const SET_NUM_RECORDS = 'numRecords';
const GET_SEARCH_MODE = 'searchMode';
const GET_SEARCH_TEXT = 'searchText';
const SEARCH_MODE = 'search_mode'

const getSearchMode = state => {
    return state.searchMode;
};

function* getTests(){
    const searchMode = yield select(getSearchMode);
    
}

export default function* watchActionsAndFetchTests() {
    yield takeEvery([SEARCH_MODE], getTests);
}
