import axios from "axios"; 
import https from "https";

const apiclient = axios.create({
    
		timeout: 10000,
		httpsAgent: new https.Agent({
			rejectUnauthorized: false,
			keepAlive: true,
		}),
        headers: {
            'Content-Type': 'application/json'
        }
})
 
const getAllCLIALocations = async({cancelToken, timeout} = {})=>{
    let result = []; 
    await apiclient.get("/clia/location/all", {cancelToken, timeout}).then(res=>{
        result = [... res.data.Data];
    }).catch(err=>{
        console.error(err);
    })
    return result;
}


// update cliaLocation with user 


const addNewCLIADirector =  async(locationObj) => {
    let result ;
    await apiclient.post("/clia/location", locationObj).then(res=>{
        result = res.data.Msg;
    }).catch(err => {
        console.error(err);
    })
    return result;

} 

/*
update clia location request object 
     {
        "clia_director": "clia_director",
        "old_location": "old_location",
        "new_location": "new_location"
    }
*/



const updateCLIALocation = async(locationObj)=> {
    let result ;
    await apiclient.put("/clia/location", locationObj).then(res=> {
        result = res.data.msg.Data;
    }).catch(err=> {
        console.error(err)
    })
    return result;
}

/*
 delete clia location object 
     {
        "clia_director": "clia_director",
        "location": "location"
    }
*/

const deleteCLIALocation = async(locationObj) => {
    console.log(locationObj);
    let result ; 
    await axios.delete(`/clia/location/${locationObj.location}/${locationObj.clia_director}`).then(res=> {
        console.log(res);
        result = res.data.Msg;
    }).catch(err=>{
        console.error(err)
    })
    return result;
}

export {getAllCLIALocations, addNewCLIADirector, updateCLIALocation, deleteCLIALocation};
