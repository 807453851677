import React from "react";
import Typography from "@material-ui/core/Typography";
import propTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditSharp";

const TestNameLabel = props => {
return (
		<div>
			<center>
				<Typography variant="h6" id="tableTitle">
					{props.testName}
				</Typography>
			</center>
		</div>
	);
};
TestNameLabel.propTypes = {
	testName: propTypes.string.isRequired
};

export default TestNameLabel;
