import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import TestNameLabel from "./TestNameLabel";
import ImageGallery from "react-image-gallery";
import { NoSsr } from "@material-ui/core";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 400,
		flexGrow: 1
	},
	header: {
		display: "flex",
		height: 40,
		justifyContent: "space-between",
		// paddingLeft: theme.spacing(4),
		backgroundColor: theme.palette.background.default
	},
	img: {
		// height: 349,
		display: "block",
		// width: 640,
		overflow: "hidden",
		width: "100%"
		// cursor:'zoom-in',
	}
}));

function ContainerImages(props) {

	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [openLightbox, setOpenLightbox] = useState(false);
	const maxSteps = props.images.length;

	function handleNext() {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	}

	function handleBack() {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	}

	function handleStepChange(step) {
		setActiveStep(step);
	}

	function handleZoomClick() {
		setOpenLightbox(true);
	}

	function renderLightbox() {
		return;
	}
	function getImagesList() {
		let imagesList = [];
		props.images.map((step, index) => {
			imagesList.push(step.FileName);
		});
		return imagesList;
	}

	function makeImages(images) {
		let _images = [];
		images.forEach(element => {
			let _image = {}
			if (element.file_name.length > 1) {
				_image['original'] = element.base_url + element.file_name
				_image['thumbnail'] = element.base_url+ element.file_name
				_image['sizes'] = "(max-height: 45vh) 25vw "
				_images.push(_image)
			}

		});
		if (_images.length < 1) {
			let _image = {}
			_image['original'] = "/no_image.png",
				_image["thumbnail"] = "/no_image.png"
			_images.push(_image)
			return _images
		}
		return _images
	}
	function checkImagesAndRender() {

		return (
			<div>
				<NoSsr>
					<ImageGallery showPlayButton={false} autoplay={true} lazyLoad={true} items={makeImages(props.images)} />
				</NoSsr>
			</div>
		);

	}

	return (
		<div className={classes.root}>
			<Typography variant="h5"></Typography>
			<Paper square elevation={0} className={classes.header}>
				{/* <Typography>{props.images[activeStep].label}</Typography> */}
				<TestNameLabel testName={"Container Images"} />
			</Paper>
			{checkImagesAndRender()}
		</div>
	);
}

export default ContainerImages;
