import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import {
    Dialog,
    AppBar,
    Typography,
    Grid,
    Button,
    Toolbar,
    IconButton,
    Slide,
    TextField
} from '@material-ui/core';
import {
    loggingUser,
    toggleNotification,
    notificationMessage
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCLIALocations } from '../../../httpCalls/fetchLocations';
import LocationCard from '../locationCard';
import AddNewLocation from './AddNewLocation';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
        position: 'relative',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
        padding: 25,

    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    content: {
      flex: '1 0 auto',
    },
    section:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100vh', 
        padding: 25,
    }
  }));



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function getDirectorsData(setLocations ) {
    getAllCLIALocations().then(res=>{
        const locationBased = res.reduce((acc, curr) => {
            curr.Locations.forEach(location => {
                if (!acc[location]) {
                    acc[location] = { location, CLIA_directors: [] };
                }
                if (!acc[location].CLIA_directors.includes(curr.CLIA_Director)) {
                    acc[location].CLIA_directors.push(curr.CLIA_Director);
                }
            });
            return acc;
        }, {})
        setLocations(Object.values(locationBased));
    }).catch(err=>{
        console.error("error occured while fetching data for the locations.")
        console.error(err)
    })
}

function getALLDirectors(locationBasedObj) {
    const users = [];
    Object.keys(locationBasedObj).forEach(k=>{
        users.push([... locationBasedObj[k]])
    })

    return new Set(users);
}

function HandbookLocations(props){
    const [locations, setLocations] = useState([]);
    useEffect(()=>{
        getDirectorsData(setLocations);
    },[])

    const refreshData = () =>{
        getDirectorsData(setLocations);
    }

    const classes = useStyles();
    return(
        <article>
            <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Lab Handbook locations
                        </Typography>
                        <Button color="inherit" onClick={props.handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <section className={classes.section} spacing={2}>
                    <Grid container spacing={2} justifyContent="space-around" alignContent="center">
                        <Grid container item direction='row' >
                                <AddNewLocation  refreshData={refreshData}/>
                        </Grid>
                        <Grid item container direction="row" spacing={10} justifyContent="space-around" alignItems="flex-start" xs={12}>
                            {locations.map(loc => (
                                <LocationCard locationName={loc.location} CLIA_directors={loc.CLIA_directors} refreshData={refreshData}/>
                            ))}
                        </Grid>
                    </Grid>
                </section>
            </Dialog>
        </article>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}



export default connect(mapStateToProps, mapDispatchToProps)(HandbookLocations)