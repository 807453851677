import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { WithContext as ReactTags } from "react-tag-input";
import { Grid, TextField, Button, Switch, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputTags from "../inputTags/inputTags"

const useStyles = makeStyles(theme => ({
	
}));

export default function SynonymInput(props) {
	
	const [tags,setTags] = useState([])
	const [publish, setPublish] = useState(false);
	const [inputFieldVal, setInputFieldVal] = useState("")
	const name = "SYNONYM_NAME";

	useEffect(() => {
		if (inputFieldVal !== "" ) {
			props.handleInputField(null, name, [...new Set([... tags, inputFieldVal])])
		}else {
			props.handleInputField(null, name, tags);
		}
		
	}, [tags]);

	function handleCheck(e) {
		setPublish(e.target.checked);
	}

	function handleSynChange(val) {
		props.handleSynInput(val);
	}

	const classes = useStyles();
	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={12} className={classes.margin}>
					<InputTags tags={tags} setTags={setTags} setInputFieldVal= {setInputFieldVal}/>
					<Typography variant="caption" display="block" gutterBottom>
						<u>Note:</u> To add multiple Synonyms separate Synonym Names with either <q>,</q> or <q>;</q>
					</Typography>
				</Grid>
				<Grid item xs={12} className={classes.margin}>
					<TextField
						name="MNEMONIC"
						placeholder="Enter Mnemonic"
						label="Mnemonic"
						type="string"
						fullWidth={true}
						onChange={props.handleInputField}
					/>
				</Grid>
				<Grid item xs={12} className={classes.margin}>
					<FormControlLabel
						control={
							<Switch
								checked={publish}
								value={publish}
								name="PUBLISH"
								color="primary"
								inputProps={{ "aria-label": "primary checkbox" }}
								onChange={props.handleInputField}
								onClick={handleCheck}
							/>
						}
						label="Publish"
					/>
				</Grid>
			</Grid>
		</div>
	);
}
