
import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Typography,
    Input,
    InputAdornment,
    InputLabel,
    Switch,
    Card,
    CardContent,
    FormControl,
    FormGroup,
    Button,
    FormControlLabel,
    makeStyles,
    TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchSharp'
import PersonIcon from '@material-ui/icons/PersonSharp'
import GroupIcon from '@material-ui/icons/GroupSharp'
import LayersClearIcon from '@material-ui/icons/LayersClear';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { getUserLabs, checkAccessForLayoutEdit } from "http_calls/userHttp";
import { getAllLabs } from "http_calls/fetchFuncs"
import LabsMenu from "../index_components/LabsMenu";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LabChips from "./labChips"

const useStyles = makeStyles(theme => ({
    searchBar: {
        minWidth: '25vw',
        margin: '7% 0 0 0',
    }
}))

function FilterPendingTestsComponent(props) {
    const [labs, setLabs] = useState([])
    const [access, setAccess] = useState(false)
    const classes = useStyles()
    useEffect(() => {

        getUserLabs().then(res => {
            setLabs([...res.data.labs])
        })

        checkAccessForLayoutEdit().then(res => {
            setAccess(res.data.edit_page_layout)
        }).catch(err => {
            console.error(err)
        })
    }, [])

    const renderUserOrLabCheckbox = () => {

        return (
            <Box component='div'>
                <Typography variant='h6' display='block' component='p' align='inherit'>View Pending tests from other users in your lab.</Typography>
                <FormGroup row>
                    <FormControlLabel control={
                        <Switch checked={props.display} onClick={props.onToggleDisplayLabs} />
                    }
                        label={props.display ? <GroupIcon /> : <PersonIcon />}
                    />
                </FormGroup>
            </Box>
        )
    }
    const renderLabToggleButtons = () => {
        if (labs.length > 0 && labs.length < 6) {
            return (
                <Box component='div'>
                    <Typography variant='h6' display='block' component='p' align='inherit'>Choose Lab</Typography>
                    <ToggleButtonGroup value={props.selectedLabs} onChange={props.handleSelectedLabs}>
                        {labs.map(lab => (
                            <ToggleButton key={lab} value={lab}>
                                {lab}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
            )
        } else {
            return (
                <Grid item container justify="center" alignItems="center" direction="column">
                    <Grid>
                        <LabsMenu toggleLabs={props.handleToggleLabs} labs={labs} checkedlabs={props.selectedLabs} />
                    </Grid>

                    <Grid>
                        <LabChips labs={props.selectedLabs} removeSelectedLabs={props.removeSelectedLabs} />
                    </Grid>
                    {props.selectedLabs.length > 4 ?
                        <Grid>
                            <Button variant="outlined" startIcon={<LayersClearIcon />} color="secondary" onClick={() => { props.handleToggleLabs([]) }}>
                                {"Unselect all Labs"}
                            </Button>
                        </Grid> : <></>}

                </Grid>
            )
        }
    }
    return (
        <Grid container direction='column' justify='space-between' alignItems='center' spacing={1}>
            <Grid item container>

                {props.showToggle ? renderUserOrLabCheckbox() : ""}
            </Grid>
            <Grid item>
                {props.display ? renderLabToggleButtons() : <span />}
            </Grid>
            <Grid item>
                {props.showSearch ? <Box component='div' className={classes.searchBar}>
                    <TextField
                        fullWidth
                        id="search-pending-tests-id"
                        variant="standard"
                        helperText=" start typing test Name ..."
                        placeholder="start typing test Name ..."
                        InputProps={{ startAdornment: < InputAdornment position="end" > <SearchIcon /></InputAdornment> }}
                        value={props.searchValue}
                        onChange={props.onSearchChange}
                    />
                </Box> : <span />}

            </Grid>
        </Grid>



    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
        labs: state.labs,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPendingTestsComponent)