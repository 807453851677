import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    IconButton,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    Table,
    TextField,
    Link,
    Grid,
    Typography,
    Tooltip,
    Box
} from "@material-ui/core";
import { getAllUploadedDocuments } from "http_calls/fetchFuncs";
import LinkIcon from "@material-ui/icons/Link";
import LinkOff from "@material-ui/icons/LinkOff"

function DocLinker(props) {

    const [labDocs, setLabDocs] = useState([])
    useEffect(() => {
        getAllUploadedDocuments(`/api/v1/document/all?lab=${props.lab}`).then(res => {
            setLabDocs([...res.data.Data]);
        })
    }, [])

    const attached = (d) => {
        let _hasAttached = false;
            if(props.docs.length>0){

            props.docs.forEach(ad => {
                if (d.fileName === ad.fileName) {
                    _hasAttached=true
                
            }}
            )
        }
        return _hasAttached;
    }
    return (
        <Grid 
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start">

            {labDocs.map(d=>(

            <Grid item xs={12}>
            <div>
               <LinkSwitch checked={attached(d)}  file ={d} {...props}/> 
            </div>
        </Grid>
            ))}
        </Grid>
    )
}


function LinkSwitch(props) {
    
   const attach= ()=>{
    let _attachedDocs = [...props.docs]
    _attachedDocs.push(props.file)
    props.onChange(props.field,_attachedDocs)
   }

   const dettach = ()=>{

   }

    return (
        <div>
            <Typography variant="body1">
            {props.checked ?  <IconButton color="secondary">
                <LinkOff /> 
            </IconButton>: 
            <IconButton color="primary" onClick={attach}>
            <LinkIcon />
            </IconButton>} 
            <Link href={`/api/v1/document/view?uname=${props.user.uname}&lab=${props.lab}&fileName=${props.file.fileName}`} target="_blank">{props.file.fileName}</Link>
                </Typography>
        </div>
    )
}

export default DocLinker;