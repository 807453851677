import React, { useState, useEffect } from 'react';
import {    Dialog,
    AppBar,
    Typography,
    Grid,
    Button,
    Toolbar,
    IconButton,
    Slide,
    TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import propTypes from 'prop-types'
import MaterialTable from "material-table";
import CloseIcon from '@material-ui/icons/Close';
import  useFetch from "hooks/useFetch";
import NavBar from "../index_components/header";
import axios from "axios";


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "4vh",
        marginBottom: "6vh",
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    listHeader: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,

    }

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TestReports(props) {
    const classes = useStyles();
    const testReports = useFetch("/reports");

    const columns = [{
        title:"Soft Order Code",
        field:"CoePublish"
    },{
        title:"Test ID",
        field:"TestId",
    },{
        title:"Test Name",
        field:"TestName",
    },{
        title:"Approved By",
        field:"ApprovedBy",
    },{
        title:"TimeStamp",
        field:"ApprovedOn",
    },{
        title:"Change Type",
        field:"ChangeType",
    }];


    function getOptions(reports) {
		let pageSizes = [];
		const reportsLength = reports.length;
        // inital page Size
		let pageSize = 15;
		for (let i = 1; i <= reportsLength / pageSize; i++) {
			pageSizes.push(Math.round(pageSize * i))
		}
		if (pageSizes.length < 2) {
			if (pageSizes[0] !== reportsLength) {
				pageSizes.push(reportsLength)
			}
		}
		return { pageSize,pageSizeOptions: pageSizes };
	}

    return (
        <>
            <Dialog fullScreen
                open={props.open}
                onClose={props.handleClose}
                TransitionComponent={Transition}>
                      <AppBar className={classes.appBar}>
                      <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Lab Handbook Reports
                        </Typography>
                        <Button color="inherit" onClick={props.handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                      </AppBar>
                      <section className={classes.container}>
                      <MaterialTable
						title="Test Reports"
						columns={columns}
						data={testReports}
						style={{ width: "100%" }}
                        options={ getOptions(testReports)}
					    />
                    </section>

            </Dialog>
        </>
    )


}


TestReports.propTypes = {
    open: propTypes.bool,
    handleClose: propTypes.func,
}

export default TestReports;