import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TestNameLabel from "./TestNameLabel";
import HorizontalStepper from "./HorizontalStepper";
import {
	IconButton,
	Paper,
	TableRow,
	TableCell,
	TableBody,
	Table,
	TextField,
	Grid,
	Typography,
	Tooltip,
	Box
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
	addDraft,
	toggleNotification,
	notificationMessage
} from "../../actions/TestActions";
import MyEditor from "../richTextEditor/Editor";
import { postDraft } from "../../../httpCalls/userHttp";
import { useDebounce } from "react-use";
import InputTextField from "./InputField";
import { KeyboardArrowLeft } from "@material-ui/icons/KeyboardArrowLeft";
import SelectComponent from "index_components/selectComponent";
import SelectMultipleOptions from "index_components/selectMultipleOptions"
import SelectAutocompleteComponent from "index_components/selectAutocomplete"
import SwitchComponent from "index_components/SwitchComponent"
import { notEmpty, isEmpty, getKeyByValue, IsObject, makeArrayUnique } from "helperFunctions";
import SliderComponent from "index_components/SliderComponent";
import ThumbsUp from "@material-ui/icons/ThumbUp";
import ThumbsDown from "@material-ui/icons/ThumbDown"
import TimeTakenSelectComponent from "index_components/TimeTakenSelectComponent"
import RadioButtons from "index_components/RadioButtons";
import InfoIcon from "@material-ui/icons/InfoSharp";
import SortComponents from "../SortComponents"
import DocLinkComponent from "../DocLinkComponent"
import DocViewComponent from "../DocViewComponent";

const styles = theme => ({
	root: {
		// marginTop: "3vh",
		padding: "10px"
	},
	table: {
		tableLayout: "fixed",
		border: "1px solid grey",
	}, custom: {
		maxWidth: 500,
		fontSize: "0.8rem"
	},
});

function RenderTestDetails(props) {
	const { classes } = props;
	const [debouncedFormData, setDebouncedFormData] = useState({})
	const printDefaultValue = key => {
		return (
			<Typography component="p">
				<Box
					textAlign="center"
					fontWeight="fontWeightLight"
					fontSize="fontSize"
					fontStyle="normal">
					<p>
						<Typography component={"span"}>{String(props.obj[key])}</Typography>
					</p>
				</Box>
			</Typography>
		);
	};
	const handleEditorChange = (key, value) => {
		let _formData = { ...props.formData };
		_formData[key] = value
		props.setFormData(_formData)
	};
	const handleMultipleSelect = (key, value) => {
		let _formData = { ...props.formData }
		_formData[key] = value
		props.setFormData(_formData)
		setDebouncedFormData(_formData)
	}
	const handleSelectOnChange = (key, value) => {
		let _formData = { ...props.formData };
		if (typeof value === "object") {
			_formData[key] = value[0]
		} else {
			_formData[key] = value
		}
		props.setFormData(_formData)
		setDebouncedFormData(_formData)
	}
	const handleRadioChange = (key, value) => {
		let _formData = { ...props.formData };
		_formData[key] = value
		props.setFormData(_formData)
		setDebouncedFormData(_formData)
	}


	const handleDocChange = (key, value) => {
		let _formData = { ...props.formData };
		_formData[key] = value
		props.setFormData(_formData)
		setDebouncedFormData(_formData)
	}


	useEffect(() => {
		if (notEmpty(debouncedFormData)) {

			let _draft = { ...props.draft, ...debouncedFormData }
			props.addDraft(_draft);
			if (notEmpty(props.user)) {
				props.addNewTestDraft(props.user.Username, props.draftName, debouncedFormData)
			}
		}

	}, [debouncedFormData])

	useDebounce(() => {
		setDebouncedFormData(props.formData)
	}, 10000, [props.formData])



	function renderMyEditor(key) {
		switch (props.fieldTypes[key].type) {
			case "WYSIWYIG":
				return (
					<TableCell align="center">
						<textarea
							className={props.editorSelector}
							id={key}
							value={props.formData[key] ? props.formData[key] : props.fieldTypes[key].defaultText}
							name={key}
						/>
						<MyEditor
							field={key}
							handleEditorChange={handleEditorChange}
							container={props.editorSelector}
						/>
					</TableCell>
				);
				break;
			case "SELECT":
				return (
					<TableCell align="center">
						<SelectComponent
							field={key}
							options={makeArrayUnique([...props.fieldTypes[key].defaultOptions, ...[props.formData[key]]])}
							selectedValue={props.formData[key]}
							onChange={handleSelectOnChange} />
					</TableCell>);
			case "SELECT_AUTOCOMPLETE":
				return (
					<TableCell align="center">
						<SelectAutocompleteComponent
							field={key}
							options={makeArrayUnique([...props.fieldTypes[key].defaultOptions, ...[props.formData[key]]])}
							selectedValue={props.formData[key]}
							dependsOn={props.fieldTypes[key].dependsOn}
							onChange={handleSelectOnChange} />
					</TableCell>
				)
			case "SELECT_MULTIPLE":
				return (
					<TableCell align="center">
						<SelectMultipleOptions
							field={key}
							defaultOptions={props.fieldTypes[key].defaultOptions}
							options={props.formData[key]}
							onChange={handleMultipleSelect} />
					</TableCell>
				);
			case "DOC_LINKER":
				return (
					<TableCell>
						<DocLinkComponent lab={props.formData.Lab} docs={props.formData.Documents ? props.formData.Documents : []} user={props.user} field={key} onChange={handleDocChange} />
					</TableCell>
				)
			case "SWITCH":
				return (
					<TableCell align="center">
						<SwitchComponent
							field={key}
							checked={props.formData[key] !== "" ? props.formData[key] : props.fieldTypes[key].defaultValue}
							onChange={handleEditorChange}
						/>
					</TableCell>
				);
			case "RADIO":
				return (
					<TableCell align="center">
						<RadioButtons
							field={key}
							option1={props.fieldTypes[key].option1}
							option2={props.fieldTypes[key].option2}
							onChange={handleRadioChange}>
						</RadioButtons>
					</TableCell>
				)
			case "TAT_SELECT":
				return (
					<TableCell align="center">
						<TimeTakenSelectComponent
							field={key}
							time={props.formData[key] ? props.formData[key] : props.fieldTypes[key].defaultTime}
							onChange={handleEditorChange} />
					</TableCell>
				)
			default:
				return (
					<TableCell align="center">
						<InputTextField
							field={key}
							onChange={handleEditorChange}
							defaultValue={props.formData[key] ? props.formData[key] : props.fieldTypes[key].defaultText}
						/>
					</TableCell>
				);
		}
	}
	const renderLabels = (key) => {
		if (props.fieldTypes[key].desc !== "") {
			return (
				<TableCell align="left">
					<Typography>
						{props.obj[key]}
						<Tooltip title={props.fieldTypes[key].desc} classes={{ tooltip: classes.custom }} interactive={true}>
							<InfoIcon fontSize='small' color="primary" />
						</Tooltip>
					</Typography>

				</TableCell>

			)
		}
		return (
			<TableCell align="left">
				<Typography>
					{props.obj[key]}
				</Typography>
			</TableCell>
		)
	}

	const viewInternalFields = (field, user) => {

		if (notEmpty(user) && user.uname !== "") {
			return true
		}
		if (field !== undefined) {
			return field.public
		}
		return false
	}

	const displayValue = (val, key) => {
		let html_match_regex = /<*>(.*?)<*>/g

		if (key === "Locations Test will be performed") {
			let _val = []
			Object.keys(val).map(k => {
				_val.push(k)
			})
			return _val.join(" , ")
		}
		if (key === "Attached Documents") {
			if (val) {
				if (val.length < 1) {
					return "No attached Documents"
				}
			}
			return <DocViewComponent docs={val} lab={props.obj["Lab Name"]} user={props.user} />
		}
		switch (typeof val) {
			case "string":
				if (val.match(html_match_regex)) {
					return <div dangerouslySetInnerHTML={{ __html: val }}></div>
				}
				return val
				break;
			case "object":
				let _val = []
				Object.keys(val).map(key => {
					_val.push(`${key}:${val[key]}`)
				})
				return _val.join(", ")
				break;
			case "boolean":
				if (val === true) {
					return <ThumbsUp color="primary" />
				}
				return <ThumbsDown color="secondary" />
			default:
				return val
				break;

		}
	}

	const renderDataFields = (inputMode, key) => {

		let _fieldKey = getKeyByValue(props.test_details_categories.All, key)
		if (inputMode) {
			return (
				<TableRow key={key} hover={false} orderBy={props.fieldTypes[key].order}>
					{renderLabels(key)}
					{renderMyEditor(key)}
				</TableRow >
			);
		}

		else if (viewInternalFields(props.fieldTypes[_fieldKey], props.user)) {
			return (
				<TableRow key={key} hover={true}>
					<TableCell align="left">
						<Typography component={"span"}>{key}</Typography>
					</TableCell>
					<TableCell align="left">
						<Typography component={"p"}>{displayValue(props.obj[key], key)}</Typography>
					</TableCell>
				</TableRow>
			);
		} else {
			return <span />
		}

	}





	return (
		<Grid item ><Paper className={classes.root}>
			<TestNameLabel testName={props.title} user={props.user} />
			<Table
				className={classes.table}
				size={"medium"}
				aria-labelledby="tableTitle">
				<colgroup>
					<col style={{ width: "40%" }} />
					<col style={{ width: "60%" }} />
				</colgroup>

				<TableBody>
					<SortComponents by='order'>
						{Object.keys(props.obj).map(key =>
							renderDataFields(props.inputMode, key)
						)}
					</SortComponents>
				</TableBody>
			</Table>
		</Paper></Grid>
	);
}



RenderTestDetails.propTypes = {
	classes: PropTypes.object.isRequired
};
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			addDraft,
			toggleNotification,
			notificationMessage
		},
		dispatch
	);
const mapStateToProps = state => ({
	user: state.user,
	draft: state.draft,
	fieldTypes: state.test_field_types,
	test_details_categories: state.test_details_categories,
});

const RenderTestDetailsWithStyles = withStyles(styles)(RenderTestDetails);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RenderTestDetailsWithStyles);
