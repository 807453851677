import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ItemTypes from './itemTypes';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Avatar, IconButton } from '@material-ui/core'
import EditIcon from "@material-ui/icons/Edit"
import TrackIcon from "@material-ui/icons/ArtTrack"
import Card from "./card";


const useStyles = makeStyles(theme => ({
    draggable: {
        border: '0.5px dashed #0d46a1',
        padding: '3%',
        margin: '3%',
        width:"110%",
        backgroundColor: 'white',
        borderRadius:"30px",
        cursor: 'move',

    },
    dragIcon: {
        cursor: 'move',
    }

}))


const MenuCard = (props) => {
    const ref = useRef(null)
    const classes = useStyles()
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = props.index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            props.moveLink(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id : props.id, index: props.index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        <div>

        <div ref={ref} className={classes.draggable}>
             <Card url={props.url} name={props.text} editAble={true} id={props.id} handleLinkEdit={props.handleLinkEdit}  handleLinkDelete={props.handleLinkDelete}/>
        </div>
        </div>
    )
}
export default MenuCard
