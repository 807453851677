import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Modal, Button, Grid } from "@material-ui/core"


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: "90vw",
        Height: "90vh",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    Image: {
        width: "70vw",
        height: "80vh",
        objectFit: "contain",
    }
}));

const returnImageCSS = (file_name) => {

    return {
        height: "80vh",
        width: "70vw",
        backgroundImage: `url("${file_name}")`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }
}

function ImageViewer(props) {

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);


    const classes = useStyles();

    return (

        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.open}
            onClose={props.onClose}
        >

            <div style={modalStyle} className={classes.paper}>
                <Grid container direction={"row"} spacing={0} justify="flex-end" alignItems="center">
                    <Button size="medium" variant="contained" onClick={props.onClose} color={"secondary"}>close</Button>
                </Grid>

                <Grid container direction={"row"} spacing={0} justify="space-between" alignItems="center">
                    {props.left}
                    <Grid xs={8}>
                        <div style={returnImageCSS(props.image)}>

                        </div>
                        {/* <img src={props.image} className={classes.Image} /> */}
                    </Grid>
                    {props.right}


                </Grid>


            </div>

        </Modal >
    );
}

export default ImageViewer;
