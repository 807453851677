import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector'

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
  },
}));

function getSteps(props) {
  return props.state.steps

}

function getLocalTime(timestamp_str) {
  return new Date(timestamp_str).toLocaleDateString() + " , " + new Date(timestamp_str).toLocaleTimeString(); 

}


function getStepContent(label) {
  return label[Object.keys(label)[0]]
}


function HorizontalStepper(props) {
  const classes = useStyles();
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps(props);

  function isStepOptional(step) {
    return step === 1;
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }
  function initialStep() {
    let _stepsArr = []
    if (props.state.steps) {
      _stepsArr = [...props.state.steps]
    }
    let _activeStep = 0
    _stepsArr.forEach((step, i) => {
      if (Object.keys(step)[0] === props.state.status) {
        _activeStep = i + 1
      }

    })
    return _activeStep



  }
  const connector = (
    <StepConnector classes={{
      active: classes.connectorActive,
      completed: classes.connectorCompleted,
      disabled: classes.connectorDisabled,
      line: classes.connectorLine,
    }} />
  )
  const activeStep = initialStep()
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel connector={connector}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{getStepContent(label)}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(steps[activeStep - 1]) + " at " + getLocalTime(props.state.timestamp)}
            </Typography>
          </div>
        ) : (
            <div>
              {/* <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography> */}
            </div>
          )}
      </div>
    </div>
  );
}

export default HorizontalStepper;
