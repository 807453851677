import React from 'react';
import OneIcon from "@material-ui/icons/Filter1TwoTone";
import TwoIcon from "@material-ui/icons/Filter2TwoTone";
import ThreeIcon from "@material-ui/icons/Filter3TwoTone";
import FourIcon from "@material-ui/icons/Filter4TwoTone";
import FiveIcon from "@material-ui/icons/Filter5TwoTone";
import SixIcon from "@material-ui/icons/Filter6TwoTone";
import SevenIcon from "@material-ui/icons/Filter7TwoTone";


export default function GetIcon(index) {
    const Icons = [<OneIcon />, <TwoIcon />, <ThreeIcon />, <FourIcon />, <FiveIcon />, <SixIcon />, <SevenIcon />];
    return Icons[index]
}


