import React from 'react';
import { makeStyles } from "@material-ui/styles"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/CloseSharp'
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from "@material-ui/icons/EditSharp";
import ForwardIcon from "@material-ui/icons/ForwardSharp";
import VisibiltyIcon from "@material-ui/icons/VisibilitySharp";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import diff from "shallow-diff";
import moment from "moment";
import useFetchTests from "hooks/useFetchTests";
import { fetchTests } from '../../../httpCalls/fetchFuncs';
import { notEmpty, removeElementFromArray } from "helperFunctions";
import { Grid, Typography, AppBar } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    paper: {
        minWidth: "80vw"
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
     tableStyle : {
        width: '100%',
        borderCollapse: 'collapse',
    },
     thTdStyle :{
        border: '1px solid black',
        textAlign: 'center',
        padding: '8px',
    }
}))

function ViewChangesDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [testChanges, setTestChanges] = React.useState([])
    const [currentTest, setCurrentTest] = React.useState({})
    const [allergenChanges, setAllergenChanges] = React.useState(
        {
            "original": [],
            "changed": [],
        })

    const classes = useStyles();

    function handleClose() {
        setOpen(false);
    }
    function handleOpen() {
        getTheTest(props.test)
        setOpen(true);
    }

    function checkForAllergenChanges(changedAllergens, allergens) {

        function comparer(arr1) {
            return function (current) {
                return arr1.filter(function (other) {
                    return other.CoeMichart === current.CoeMichart && other.CoePublish == current.CoePublish && other.TestName === current.TestName
                }).length == 0;
            }
        }

        let changed = changedAllergens.filter(comparer(allergens))
        let original = allergens.filter(comparer(changedAllergens))

        setAllergenChanges({ original, changed })

    }

    async function getTheTest(test) {
        let _params = {};
        _params["TestId"] = props.test.TestId;
        const { tests } = await fetchTests(_params, []);
        if (props.test.Allergen) {
            checkForAllergenChanges(props.test.Allergen, tests[0].Allergens)
        }
        let diffs = diff({ ...tests[0] }, test)
        if (tests[0] === undefined) {
            let addedFields = [...diffs.added]
            setCurrentTest(null)
            removeElementFromArray(addedFields, "Synonyms")
            removeElementFromArray(addedFields, "Author")
            removeElementFromArray(addedFields, "State")
            removeElementFromArray(addedFields, "_id")
            removeElementFromArray(addedFields, "EditDate")
            setTestChanges(addedFields)
        } else {
            setCurrentTest(tests[0])
            let changedFields = [...diffs.updated]
            removeElementFromArray(changedFields, "Synonyms")
            removeElementFromArray(changedFields, "Author")
            removeElementFromArray(changedFields, "State")
            removeElementFromArray(changedFields, "EditDate")
            removeElementFromArray(changedFields, "EditDate")
            setTestChanges(changedFields)
        }
    }


    const getChangeString = (val) => {
        if (val === undefined || val === null) return "<empty>";

        let _str = val.toString();
        if (_str.length > 0) {
            return _str
        }
        return "<empty>"


    }

    const displayChange = (value) => {
        let str = ""
        if (Object.prototype.toString.call(value) == '[object Array]') {
            str = ""
            value.forEach(v => {
                Object.keys(v).forEach(k => {
                    str += v[k].toString() + "<br/>"

                })
            })
            return str
        }

        if (Object.prototype.toString.call(value) == '[object Object]') {
            str = ""
            Object.keys(value).forEach(k => {
                if (value[k]) {
                    str += k + " : " + value[k].toString() + " <br/>"
                }

            })
            return str;
        }
        return getChangeString(value)
    }

    function renderDifferenceBetweenTests(test) {
        if (currentTest === null) {
            return (
                <div>
                     <table className={classes.tableStyle}>
                        <thead>
                            <tr>
                                <th className={classes.thTdStyle}>Field Name</th>
                                <th className={classes.thTdStyle}>New Value </th>
                            </tr>
                        </thead>
                        <tbody>
                            { testChanges.map(change => (
                                <tr>
                                    <td className={classes.thTdStyle}> <b>{change}</b></td>
                                    <td className={classes.thTdStyle}>
                                        <p dangerouslySetInnerHTML={{ __html: displayChange(props.test[change]) }}></p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   
                </div>
            )
        }
        return (
            <div>
               
                    <table className={classes.tableStyle}>
                        <thead>
                            <tr>
                                <th className={classes.thTdStyle}>Field Name</th>
                                <th className={classes.thTdStyle}>Current</th>
                                <th className={classes.thTdStyle}>Changed</th>
                            </tr>
                        </thead>
                        <tbody>
                            { testChanges.map(change => (
                                <tr>
                                    <td className={classes.thTdStyle}> <b>{change}</b></td>
                                    <td className={classes.thTdStyle}>
                                        <p dangerouslySetInnerHTML={{ __html: displayChange(currentTest[change]) }}></p> 
                                    </td>
                                    <td className={classes.thTdStyle}>
                                        <p dangerouslySetInnerHTML={{ __html: displayChange(props.test[change]) }}></p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   
            </div>
        );
    }

    function renderAllergenChanges() {
        if (allergenChanges.changed.length > 0) {
            return (
                <div>
                     <table className={classes.tableStyle}>
                        <thead>
                            <tr>
                                <th className={classes.thTdStyle}>Allergen </th>
                                <th className={classes.thTdStyle}>Current</th>
                                <th className={classes.thTdStyle}>Changed</th>
                            </tr>
                        </thead>
                        <tbody>
                            { allergenChanges.changed.map((a, i) => (
                                <tr>
                                    <td className={classes.thTdStyle}> <b>{a}</b></td>
                                    <td className={classes.thTdStyle}>
                                        <p dangerouslySetInnerHTML={{ __html: displayChange(allergenChanges.original[i]) }}></p>
                                    </td>
                                    <td className={classes.thTdStyle}>
                                        <p dangerouslySetInnerHTML={{ __html: displayChange(allergenChanges.changed[i]) }}></p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    return (
        <div>
            <Tooltip title="View Changes"><IconButton color={"primary"} onClick={handleOpen}><VisibiltyIcon /> </IconButton></Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={"body"}
                aria-labelledby="Test-changes-title"
                fullWidth={true}
                maxWidth={"lg"}
            >


                <DialogTitle id="scroll-dialog-title" className={classes.title}>
                    <center>
                        {props.test.TestName + " Changes will be applied on effective date: " + moment(props.test.EffectiveDate).format("MM/DD/YYYY")}
                    </center>
                </DialogTitle>

                <DialogContent dividers={false}>
                    <article className={classes.Dialog}>
                        {/* show Allergen changes  here  */}
                        {renderAllergenChanges()}
                        {renderDifferenceBetweenTests(props.test)}
                    </article>
                </DialogContent>
                <DialogActions>
                    {props.access ? props.approveButton : <span />}
                    {props.access ? props.rejectButton : <span />}
                    <Button onClick={handleClose} variant={"contained"} color="secondary">
                        <Close /> {" close"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}




export default ViewChangesDialog;
