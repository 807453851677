import React, { useState, useEffect } from "react"
import Switch from "@material-ui/core/Switch";

export default function SwitchComponent(props) {
    const [checked, setChecked] = useState(props.checked)

    function handleChange() {
        var _checkBool = !checked
        setChecked(_checkBool)
        props.onChange(props.field, _checkBool)

    }

    return (<Switch
        checked={checked}
        onChange={handleChange}
        color="primary"
        inputProps={{ "aria-label": "checkbox" }}
    />)
}