import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import RenderSideMenu from '../RenderSideMenu';
import RenderTestDetails from "test_components/RenderTestDetails";
import OneIcon from "@material-ui/icons/Filter1TwoTone";
import TwoIcon from "@material-ui/icons/Filter2TwoTone";
import ThreeIcon from "@material-ui/icons/Filter3TwoTone";
import FourIcon from "@material-ui/icons/Filter4TwoTone";
import FiveIcon from "@material-ui/icons/Filter5TwoTone";
import SixIcon from "@material-ui/icons/Filter6TwoTone";
import SevenIcon from "@material-ui/icons/Filter7TwoTone";
import CloseIcon from "@material-ui/icons/CloseSharp"
import { bindActionCreators } from "redux";
import { GetMinorFields, makeArrayUnique } from "helperFunctions";
import { connect } from 'react-redux';
import { toggleNotification, notificationMessage, fieldTypes } from "../../actions/TestActions";
import GetIcon from "global_components/SideMenuIcons"
import { updatePendingTests } from "http_calls/fetchFuncs";
import {
    AppBar,
    Toolbar,
    IconButton,
    Dialog,
    Grid,
    Typography,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Slide
} from "@material-ui/core";
import diff from "shallow-diff";


const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    title: {
        color: theme.palette.common.white,
        marginLeft: theme.spacing,
        flex: 1
    },
    marginLeftButton: {
        marginLeft: "2%"
    },
    root: {
        backgroundColor: "whitesmoke"
        // display: 'flex',
    },
    container: {
        padding: theme.spacing(1)
        // backgroundImage:"url('/background_lab.jpg')"
    },
    drawer: {
        width: "300px",
        flexShrink: 0
    },
    drawerPaper: {
        width: "300px"
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        // padding: theme.spacing(3)
    },
    button: {
        margin: theme.spacing(1),
    },
    successColor: {
        backgroundColor: theme.palette.success["800"],
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    }
}))


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function EditPendingTests(props) {
    const classes = useStyles();
    const [category, setCategory] = useState("Overview Details");
    const [formData, setFormData] = useState({ ...props.test });
    const [viewSynonyms, setViewSynonyms] = useState(false)
    const [displayFields, setDisplayFields] = useState([])



    const handleSubmit = () => {
        let diffs = diff(props.test, formData);
        if (diffs.updated.length > 0) {
            let changedData = {};
            let changedFields = makeArrayUnique([...diffs.added, ...diffs.updated, ...diffs.deleted])
            changedFields.forEach(field => {
                changedData[field] = formData[field];
            })
            changedData["TestId"] = props.test["TestId"];
            props.handlePendingTestChange(changedData)
        }

    }


    const SwitchCategory = (text) => {
        if (viewSynonyms) {
            setViewSynonyms(false)
        }
        setCategory(text)
    }



    return (
        <Dialog
            fullScreen
            open={props.open}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        onClick={props.closeModal}
                        aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Edit Pending Request Change , {" " + props.test.TestName}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={false}
                        className={classes.marginLeftButton}>
                        Apply Change
					</Button>

                </Toolbar>
            </AppBar>
            <section>

                <Grid container direction="row" justify="center" alignItems="flex-start" >
                    <Grid item xs={3}>
                        <List>
                            {Object.keys(props.test_details_categories).filter(text => text !== "All").map((text, index) => (
                                <RenderSideMenu text={text} index={index} icon={GetIcon(index)} onClick={() => SwitchCategory(text)} selected={category === text ? true : false} />
                            ))}


                        </List>
                    </Grid>
                    <Grid item xs={9}>
                        <main >
                            <RenderTestDetails
                                obj={props.test_details_categories[category]}
                                test_details={formData}
                                title={""}
                                inputMode={true}
                                editorSelector={category.split(" ").join("")}
                                formData={formData}
                                setFormData={setFormData}
                                addNewTestDraft={() => { }}
                            />
                        </main>
                    </Grid>

                </Grid>
            </section>
        </Dialog>
    )

}

const mapPropsToState = state => ({
    user: state.user,
    test_details_categories: state.test_details_categories,
    test_field_types: state.test_field_types,
})

const mapDispatchProps = dispatch => {
    return bindActionCreators({ toggleNotification, notificationMessage, fieldTypes }, dispatch)
}



export default connect(mapPropsToState, mapDispatchProps)(EditPendingTests)