import React, { useState, useEffect } from 'react';

import { Select, MenuItem, Input, Typography } from "@material-ui/core";

function DropDown(props) {

    return (
        <div>
            <Typography variant="caption"> Predefined User roles</Typography>
            <Select
                value={props.type}
                onChange={props.handleChange}
                input={<Input fullWidth color="primary" name="Type" id="filled-type-dropdown" />}
            >

                <MenuItem value={"lab_tech"}>Lab Tech</MenuItem>
                <MenuItem value={"lab_manager"}>Lab Manager</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
            </Select>
        </div>
    )
}

export default DropDown