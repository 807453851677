import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import NewWindow from "react-new-window";
import RenderTestWithSideMenu from "./RenderTestWithSideMenu"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
}));
const features = {
    innerWidth: "600px",
    location: false,


}

function ViewDetailDraftModal(props) {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    return (
        <NewWindow copyStyles={true} title={"View or Edit Draft"} name={"Saved Draft"} features={features}>
            <div >
                <RenderTestWithSideMenu draft={props.draft} user={props.user} draftName={props.draft.draftName} user={props.user} />
            </div>
        </NewWindow >
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

const mapStateToProps = state => ({
    user: state.user,
    test_details_categories: state.test_details_categories,
    draftName: state.draftName,
    test_field_types: state.test_field_types,

});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailDraftModal)