import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    AppBar,
    Typography,
    Grid,
    Button,
    Slide,
    FilledInput,
    MenuItem,
    IconButton,
    DialogActions,
    DialogContent,
    Link,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Select,
    Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { getUserLabs } from "http_calls/userHttp";
import { docUpload,getAllUploadedDocuments,deleteDocument } from "http_calls/fetchFuncs";
import FormDialog from "../formDialogComponent";
import { DropzoneArea } from "material-ui-dropzone";
import {
    loggingUser,
    toggleNotification,
    notificationMessage,
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment';


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        padding: 25,

    },
    Button:{
        margin:"2px",
    }

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function UploadForm(props) {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [userLabs,setUserLabs] = useState([])
    const [uploadedFiles,setUploadedFiles]= useState([])
    const [lab, setLab] = useState("")
    const [dupDialog,setDupDialog] = useState(false)

    const handleLabChange = (e) => {
        setLab(e.target.value)
    }
    const handleFileChange = (_files) => {
            setFiles(_files)
    }

    useEffect(()=>{
        getUserLabs().then(res => {
            setUserLabs([...res.data.labs])
            setLab[[...res.data.labs][0]]
        }) 
        
        fetchAllDocuments()
       
        return ()=>{
            setLab("");
            setFiles([])
            setDupDialog(false)
        }
    },[])

    const fetchAllDocuments=()=>{
        getAllUploadedDocuments('/api/v1/document/all').then(res=>{
            setUploadedFiles([... res.data.Data]);
        })
    }

    const handleSubmit= (e)=>{
        let formData = new FormData();
        formData.append("uploadedFile", files[0])
        formData.append("labName",lab)
        formData.append("force",false)

            docUpload( formData).then(res => {
                    if (res.Msg==="duplicate"){
                        setDupDialog(true)
                    }else{
                        props.toggleNotification(true)
                        props.notificationMessage(res.Msg)
                    }
                    fetchAllDocuments();
            })
        
        e.preventDefault()
    }

    const handleForceSubmit = e =>{
        let formData = new FormData();
        formData.append("uploadedFile", files[0])
        formData.append("labName",lab)
        formData.append("force",true)

            docUpload( formData).then(res => {      
                console.log(res)       
                    props.toggleNotification(true)
                    props.notificationMessage(res.Msg)
                    fetchAllDocuments();
            })
        
        e.preventDefault();
    }

    const canDelete=(_lab )=>{
        if (userLabs.indexOf(_lab) > -1 ){
            return false
        }
        return true

    }
    const handleDelete = (fileName,lab)=>{
        deleteDocument(fileName,lab).then(res => {
            if (res.IsError === false){
                setUploadedFiles([... res.Data])
            }
            else{
                props.toggleNotification(true)
                props.notificationMessage("Failed to delete.")
            }
        }) 

    }

    return (

        <article>
            <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Upload Test Documents
                        </Typography>
                    <Button color="inherit" onClick={props.handleClose}>
                        Close
                        </Button>
                        </Toolbar>
                </AppBar>
                <DialogContent>
            <section>
                <Grid container direction="column" spacing={4}>
                    <Grid item container direction="column" spacing={3}>
                    <form onSubmit={handleSubmit} enctype="multipart/form-data" method="post">
                    <Grid item xs={12}>
                    <DropzoneArea
                            onChange={handleFileChange}
                            filesLimit={1}
                            showFileNames={true}
                            dropzoneText={"Drag and Drop file here or Click here"}
                        />
                    </Grid>
                    <Grid item xs={12} >
                    <Typography variant="subtitle1"> Select the Lab</Typography>
                    {userLabs.length > 1 ? <Select
                            value={lab}
                            onChange={handleLabChange}
                            fullWidth
                            input={<FilledInput fullWidth color="primary" name="Type" id="filled-type-dropdown" />}
                        >
                            {userLabs.map(lab=>(
                                <MenuItem key={lab} value={lab}>{lab}</MenuItem>
                            ))}
                        </Select> :<Select
                            value={lab}
                            fullWidth
                            input={<FilledInput fullWidth color="primary" name="Type" id="filled-type-dropdown" />}
                        >                           
                                <MenuItem key={lab} value={lab}>{lab}</MenuItem>
                        </Select> }
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={props.handleClose} color="secondary" className={classes.Button}>
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained" type="submit" className={classes.Button}>
                            upload
                        </Button>
                    </Grid>

                </form>
                    </Grid>
                    <Grid item >
                        <Typography variant="subtitle1"> List of Uploaded Documents</Typography>
                                <List>
                                    {uploadedFiles.map(f=>(
                                        <ListItem>
                                            <ListItemIcon><FileIcon/></ListItemIcon>
                                            <ListItemText primary={<Link href={`/api/v1/document/view?uname=${props.user.uname}&lab=${f.lab}&fileName=${f.fileName}`} target="_blank">{f.fileName}</Link>} secondary={f.lab + " "+ moment.utc(f.uploadTime).format("YYYY-MM-DD HH:mm:ss") }/>
                                           <ListItemSecondaryAction><IconButton disabled={canDelete(f.lab)} color="secondary" onClick={()=>{handleDelete(f.fileName,f.lab)}}><DeleteIcon/></IconButton></ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                    
                                 </List>
                    </Grid>
                </Grid>

          
            </section>
            <FormDialog 
                open={dupDialog} 
                title={" Document with this name already exists in Handbook and accosiated with the "+lab}
                handleClose={()=>{setDupDialog(false)}}
                handleSubmit={handleForceSubmit}
                confirmButtonText="Upload anyway"
                cancelButtonText="cancel"
                >

            </FormDialog>
            </DialogContent>
            </Dialog>
           
        </article>

    )
}
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadForm)