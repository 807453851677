import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {makeArrayUnique} from 'helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 360,
    height: 250,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function TransferList(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [leftChecked,setLeftChecked] = useState([]);
  const [rightChecked,setRightChecked] =useState([])

  useEffect(()=>{
    setLeftChecked([...intersection(checked, props.userLabs)])
    setRightChecked([...intersection(checked, props.allLabs)])
  },[props.userLabs,props.allLabs])

  const handleToggle = (value)=> () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleLeftToggle = value=>()=>{
    const currentIndex = leftChecked.indexOf(value);
    const newChecked = [...leftChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setLeftChecked(newChecked);
  }

  const handleRightToggle = value=>()=>{
    const currentIndex = rightChecked.indexOf(value);
    const newChecked = [...rightChecked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRightChecked(newChecked);
  }
  

  const numberOfLeftChecked = items => intersection(leftChecked, items).length;

  const numberOfRightChecked = items => intersection(rightChecked, items).length;
  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = title => () => {
    switch(title){
      case "Chosen":
        if (leftChecked.length===props.userLabs.length){
          setLeftChecked([])
        }else{
          setLeftChecked([...props.userLabs])
        }
        break;
      case "Choices":
        if(rightChecked.length === props.allLabs.length){
          setRightChecked([])
        }else{
          setRightChecked([... props.allLabs])
        }
        break;
    
    }
  };
 

  const handleCheckedMoveRight = () => {
    props.setAllLabs(makeArrayUnique(props.allLabs.concat(leftChecked)) );
    props.setUserLabs(makeArrayUnique([... not(props.userLabs, leftChecked)]));
    setChecked(makeArrayUnique([... not(checked,leftChecked)]));
  };

  const handleCheckedMoveLeft = () => {
    props.setUserLabs(makeArrayUnique([... props.userLabs.concat(rightChecked)]));
    props.setAllLabs(makeArrayUnique([... not(props.allLabs, rightChecked)]));
    setChecked(makeArrayUnique([... not(checked, rightChecked) ]));
  };

  const customList = (title, _labs) => {

    let _checkedLabs=[];
    let _toggleFunc;
    let _getNumberOfItems;
    switch(title){
      case "Chosen":
        _checkedLabs=[...leftChecked]
        _toggleFunc=handleLeftToggle
        _getNumberOfItems=numberOfLeftChecked
        break;
      case "Choices":
        _checkedLabs=[...rightChecked]
        _toggleFunc=handleRightToggle
        _getNumberOfItems=numberOfRightChecked
        break;
    }
    
    return (
      <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(title)}
            checked={_getNumberOfItems(_labs) === _labs.length && _labs.length !== 0}
            indeterminate={numberOfChecked(_labs) !== _labs.length && numberOfChecked(_labs) !== 0}
            disabled={_labs.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(_labs)}/${_labs.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {_labs.map(l => {
          const labelId = `transfer-list-all-item-${l}-label`;
          return (
            <ListItem key={l} role="listitem" button onClick={_toggleFunc(l)}>
              <ListItemIcon>
                <Checkbox
                  checked={_checkedLabs.indexOf(l) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={l} primary={`${l}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
    )
  };

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList('Choices', props.allLabs)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedMoveRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >  &lt;
           
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedMoveLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
           &gt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Chosen', props.userLabs)}</Grid>
    </Grid>
  );
}

export default TransferList;
