import React from "react";
import { ReactDOM } from "react-dom";
import { withStyles } from '@material-ui/core/styles';
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";



const SaveFilters = (props) => {
	const { classes } = props;
	return (
		<Button
			variant="contained"
			size="medium"
			color="default"
			onClick={props.onClick}
			disabled={props.disable}>
			<SaveIcon />
			Save Filters
		</Button>
	)
}

export default SaveFilters