import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    AppBar,
    Typography,
    Grid,
    Button,
    Toolbar,
    IconButton,
    Slide,
    TextField
} from '@material-ui/core';
import UserCard from "./userCard"
import CloseIcon from '@material-ui/icons/Close'
import { getAllUsers, deleteUser } from "http_calls/userHttp";
import { getAllLabs } from "http_calls/fetchFuncs";
import {
    loggingUser,
    toggleNotification,
    notificationMessage
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LabsMenu from "../index_components/LabsMenu";
import LabChips from "../index_components/LabChips";
import AddUser from "./AddUser";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        padding: 25,

    }

}));

/**
 * {
 *  App: "Mich_Med"
    Drafts: {}
    FirstName: "Beth"
    LastName: "Gibson"
    MongoID: "5e8b4254aa9b2563b0de0dd8"
    Searchs: {}
    Username: "bethgibs"
    role:{
        clia_approval: false
        edit_page_layout: false
        finalize_request_change: false
        labs: []
        major_request_change: false
        name: "lab_tech"
        request_change: true
        synonym_ops: false
        view_all_lab_tests: false
    }
 }
 */



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AdminPanel(props) {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [labs, setLabs] = useState([])
    const [selectedLabs, setSelectedLabs] = useState([]);

    const fetchAllUsers = async ()=> {
       await getAllUsers().then(res => {
            if (res.data.msg.IsError === false) {
                setUsers([...res.data.msg.Data])
            }
        }).catch(err => console.error(err))
    }


    useEffect(() => {
        if(users.length < 1 ) {
            fetchAllUsers();
        }
        if (labs.length < 1) {
            getAllLabs().then(res => {
                setLabs([...res.data.labs])
            }).catch(err => console.error(err))
        }
        if (selectedLabs.length > 0) {
            setUsers(users.filter(u => {
                let _result = false;
                if (u.role){
                    for(let i in [... u.role.labs]){
                         if(selectedLabs.indexOf(u.role.labs[i])> -1 ){
                             _result = true
                            break;
                         }
                    }
                }
                return _result;
            }))
        }
    }, [props.open, selectedLabs])

    const refreshUsers=()=>{
        getAllUsers().then(res => {
            if (res.data.msg.IsError === false) {
                setUsers([...res.data.msg.Data])
            }
        }).catch(err => console.error(err))
    }


    const handleSearchChange = (e) => {
        let searchStr = e.target.value;
        if(searchStr.length > 2){

            setUsers(users.filter(u => {
                return u.Username.indexOf(searchStr) > -1
            }));
        }else {
            setUsers(users);
        }
        setSearch(searchStr);
        if(searchStr === ""){
            fetchAllUsers();
        }
    }

    const handleDelete = (uname) => {
        deleteUser(uname).then(data => {
            props.notificationMessage(data.msg.Data);
            if (data.msg.IsError === false) {
                fetchAllUsers(); 
            }
        }).catch(err => console.error(err))
        setSearch("")
    }

    const handleToggleLabs = (labs) => {
        let labSet = new Set([...labs])
        setSelectedLabs(Array.from(labSet))
    }
    const removeSelectedLabs = (data) => {
        let _labs = [...selectedLabs];
        let currentIndex = _labs.indexOf(data);
        if (currentIndex > -1) {
            _labs.splice(currentIndex, 1);
            //remove labs on delete
            setSelectedLabs(_labs);
        }
    }



    return (
        <article>
            <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Lab Handbook admin page
                        </Typography>
                        <Button color="inherit" onClick={props.handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <section className={classes.container}>
                    <Grid item container justify="center" alignItems="center" direction="column" spacing={4}>
                        <Grid item>
                            <LabsMenu toggleLabs={handleToggleLabs} labs={labs} checkedlabs={selectedLabs} />
                        </Grid>
                        <Grid item>
                            <LabChips selectedLabs={selectedLabs} removeSelectedLabs={removeSelectedLabs} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} container direction="column" alignItems="center" justify="center" spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Type Uname"
                                    helperText="search by Uname"
                                    label="search by Uname"
                                    value={search}
                                    onChange={handleSearchChange}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h3"> List of All users of Handbook</Typography> 
                            </Grid>
                            <Grid item xs={12}>
                                    <AddUser allLabs={labs}  refreshUsers={refreshUsers}/>
                            </Grid>
                            <Grid item container direction="row" spacing={3}>
                                {users.map(user => (
                                    <Grid item xs={12} key={user.Username} >
                                        <UserCard key={user.Username} user={user} onDelete={handleDelete} allLabs={labs} refreshUsers={refreshUsers}/>
                                    </Grid>
                                ))}
                            </Grid>

                        </Grid>
                    </Grid>

                </section>
            </Dialog>
        </article>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel)
