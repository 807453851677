import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import LabsMenu from "./LabsMenu";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { loadTests, addParams, searchMode, toggleLoading } from "../../actions/TestActions";
import { bindActionCreators } from "redux";
import Button from "@material-ui/core/Button";
import SaveSearchDialog from "./SaveSearchDialog";
import { toggleLabs } from "../../actions/LabActions";
import EditFilters from "./EditSearchDialog";
import { useForm, Controller } from 'react-hook-form'

const useStyles = makeStyles(theme => ({
	root: {
		// flexGrow: 1
	},
	control: {
		padding: theme.spacing(2)
	},
	button: {
		width: "150px",
		margin: theme.spacing(1)
	},
	label: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		backgroundColor: 'transparent'
	}
}))


function AdvancedSearch(props) {
	const { control, handleSubmit, reset, setValue, getValues } = useForm({ ...props.params });

	useEffect(() => {
		// for (let key in props.params) {
		// 	if (props.params[key]){
		// 		setValue(key, props.params[key],{ shouldValidate: true })
		// 	}
		// }
		for (let key in getValues()) {
			setValue(key, props.params[key], { shouldValidate: true });
		}
	}, [props.params])

	const applyFilters = (params) => {
		const _params = cleanParams(params);
		_params["start"] = 0;
		props.addParams({ ...props.params, ..._params });

	};
	const onSubmit = (data) => {

		applyFilters(data);
	};
	const cleanParams = (params) => {
		let _cleanedParams = {};
		Object.keys(params).forEach(k => {
			if (params[k] === "" || params[k] === null || !params.hasOwnProperty(k)) {
				delete params[k];
			} else {
				_cleanedParams[k] = params[k];
			}
		});
		return _cleanedParams;
	};

	const removeStateFields = (params, stateParams) => {
		let _filteredParams = {};
		Object.keys(params).forEach(k => {
			if (props.handbookFilters.includes(k)) {
				delete params[k];
			} else {
				_filteredParams[k] = params[k];
			}
		});
		return _filteredParams;
	};

	const clearFilters = () => {

		let _params = cleanParams(
			removeStateFields(
				{ ...props.params },
				{}
			)
		);
		if (props.search_text === false) {
			_params["start"] = 0;
		}
		let emptyObj = {};
		props.handbookFilters.forEach(f => {
			emptyObj[f] = "";
		})
		reset(emptyObj);
		props.addParams(_params);
		props.toggleLabs([]);
	};

	const handleLabs = (labs) => {
		props.toggleLabs(labs)
		let _params = { ...props.params }
		_params["start"] = 0;
		_params["limit"] = 10;
		props.addParams(_params)
	}

	const getLabel = filter => {
		if (filter == "synonymName") {
			return "Synonym Name";
		}
		return props.test_details_categories.All[filter];
	}

	const classes = useStyles();
	return (
		<Grid
			container
			direction="column"
			justify="space-between"
			alignItems="stretch"
			className={classes.root}>
			<form method="post" onSubmit={handleSubmit(onSubmit)} >
				<Grid
					container
					item
					direction="row"
					justify="center"
					alignItems="center"
					spacing={2}>


					<Grid item xs={2}>
						<LabsMenu toggleLabs={handleLabs} labs={props.labs} checkedlabs={props.checkedlabs} />
					</Grid>
					{props.advanced_search_fields.map(filter => (
						<Grid item xs={2} key={filter}>
							<Grid item xs={2} key={filter}>
								<Controller
									name={filter}
									control={control}
									defaultValue=""
									render={({ field }) => (
										<TextField
											{...field}
											id={`outlined-${filter}-input`}
											label={getLabel(filter)}
											type="text"
											className={classes.label}
											style={{ backgroundColor: 'transparent' }}
											variant="filled"
											helperText={`Filter by ${getLabel(filter)}`}
										/>
									)}
								/>
							</Grid>
						</Grid>
					))}
					<Grid item container xs={3} direction="column" spacing={1} justify="flex-start" alignItems="flex-start">
						<Grid item container spacing={0} direction="row" justify="center" alignItems="baseline">
							<Grid item >
								<Button
									variant="contained"
									type="submit"
									size="medium"
									color="primary"
									className={classes.button}
									onClick={handleSubmit(onSubmit)}>
									Apply Filters
								</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									size="medium"
									color="secondary"
									className={classes.button}
									onClick={clearFilters}>
									Clear Filters
								</Button>
							</Grid>
						</Grid>
						<Grid item container spacing={1} direction="row" justify="center" alignItems="baseline">
							<Grid item>
								<SaveSearchDialog />
							</Grid>
							<Grid item >
								{props.user.searchs ? <EditFilters /> : <span />}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
}


AdvancedSearch.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	user: state.user,
	params: state.params,
	checkedlabs: state.checkedlabs,
	labs: state.labs,
	search_mode: state.search_mode,
	advanced_search_fields: state.advanced_search_fields,
	test_details_categories: state.test_details_categories,
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ loadTests, addParams, toggleLabs, searchMode, toggleLoading }, dispatch);
};



export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdvancedSearch);
