import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge'
import { Container, Grid, NoSsr } from '@material-ui/core'
import propTypes from 'prop-types';
import DraftCard from './DraftPreviewCard';
import { getDrafts } from "http_calls/userHttp";
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { deleteDraft } from "http_calls/userHttp"
import {

    toggleNotification,
    notificationMessage,

} from "actions/TestActions";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }, margin: {
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ViewDrafts(props) {
    const classes = useStyles();

    const [drafts, setDrafts] = useState([])

    const getDraftsFromAPI = () => {
        getDrafts().then(res => {
            if (res.IsError === false) {
                setDrafts(res.Data)
            } else {
                if (props.notifyMsg !== res.Msg) {
                    props.toggleNotification(true);
                    props.notificationMessage(res.Msg);
                }
            }
        })
    }
    useEffect(() => {
        getDraftsFromAPI()
    }, [props.notifyMsg, props.open])

    const handleDeleteDraft = (draftName) => {
        deleteDraft(draftName).then(res => {
            props.toggleNotification(true);
            props.notificationMessage(res.Msg);
        })
    }
    const refresh = () => {
        getDraftsFromAPI()
    }

    return (
        <div>
            <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Saved Drafts
            </Typography>
                        <Button color="secondary" variant={"contained"} onClick={props.onClose}>
                            {"Close & Go back"}
                        </Button>
                    </Toolbar>
                </AppBar>
                <NoSsr>
                    <Container>
                        <RenderDraftsGrid drafts={drafts} handleDeleteDraft={handleDeleteDraft} refreshDrafts={refresh} />
                    </Container>



                </NoSsr>
            </Dialog>
        </div>
    );
}

function RenderDraftsGrid(props) {
    if (props.drafts) {
        if (props.drafts.length >= 1) {
            return (
                <Grid container spacing={3}>
                    {props.drafts.map(draft => (
                        <Grid item xs={6} key={draft.DraftName}>
                            <DraftCard draft={draft} handleDelete={props.handleDeleteDraft} refreshDrafts={props.refreshDrafts} />
                        </Grid>
                    ))}
                </Grid>
            )
        }
    }
    else {
        return (
            <Box fontWeight="fontWeightMedium" m={1}>
                You have No Saved Drafts
            </Box>
        )
    }
}

const mapStateToProps = state => ({
    notificationOpen: state.notificationOpen,
    notifyMsg: state.notificationMessage
})
const mapDispatchtoProps = dispatch => {
    return bindActionCreators(
        {
            toggleNotification,
            notificationMessage,
        }, dispatch
    )
}

export default connect(mapStateToProps, mapDispatchtoProps)(ViewDrafts);
