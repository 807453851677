import React,{useEffect,useState} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import {
	Grid,
	CardContent,
	CardMedia,
	Typography,
	Avatar,
	Link
} from "@material-ui/core";
import { green, red, blue } from "@material-ui/core/colors";
import AssignmentIcon from '@material-ui/icons/Assignment'
import InsertLink from "@material-ui/icons/InsertLink";
import staticLinks from "../StaticLinksArray";
import Card from "../menuLinks/card"

const useStyles = makeStyles(theme=>({
	image:{
		margin: 10,
		width:25,
		height:20,
	},
	content: {},
	cover: {
		width: "50%",
		height: "100%"
	},
	avatar :{
		margin:10,
		backgroundColor:theme.palette.primary.dark
	}
}
))

function SideLinks(props) {
	const classes =  useStyles()

		const getIcon = (obj) =>{
			if (obj.hasOwnProperty("icon")) {
				if (obj.icon.indexOf("/") > -1) {
					return <img src={obj.icon} className={classes.image}/>;
				} 
			}
			else {
					return <Avatar className={classes.avatar} ><AssignmentIcon /></Avatar>
				}
			}
		
		return (
			<section >
				<Grid container spacing={1}>
					<Grid item xs={12} />
					{props.links.map((obj, i) => (
						<Card key={i} url={obj.url} name={obj.name} icon={getIcon(obj)}/>
					))}
				</Grid>
			</section>
		);
}



export default SideLinks
