import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField";
import EditSectionDialog from "../formDialogComponent";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Grid
} from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import { updateSectionsAndLabs , addSectionAPI, addLabAPI } from "http_calls/fetchFuncs"
import NewLab from "./newLab";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  TextField: {
    margin: "10px",
  },
  content: {
    minWidth: "65vw",
    minHeight: "30vh",
  }
}));
function removeUnchangedData(prevSections, focusedSections){
  let oldSec =[]
  let newSec =[]
  for(let i =0;i < prevSections.length ;i ++){
    if(prevSections[i] !== focusedSections[i]){
      oldSec.push(prevSections[i])
      newSec.push(focusedSections[i])
    }
  }
  return[oldSec , newSec]
}

function ControlledExpansionPanels(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [lab, setLab] = React.useState("")
  const [focusedSections, setFocusedSections] = React.useState([])
  const [prevLab,setPrevLab] = React.useState("")
  const [prevSections,setPrevSections] = React.useState([])
  const [addSection, setAddSection] = React.useState(false)
  const [newSection , setNewSection] = React.useState("")

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleEditOpen = (lab, sections) => {
    setOpenEdit(true)
    setLab(lab)
    setPrevLab(lab)
    setFocusedSections(sections)
    setPrevSections([...sections])
  }
  const handleEditClose = () => {
    setOpenEdit(false)
  }
  const handleLabEdit = (e) => {
    setLab(e.target.value)
  }

  const handleNewSection= (e)=>{
    setNewSection(e.target.value)
  }

  const handleSectionEdit = (e) => {

    if (e.target.value.length > 0) {

      let _focusedSections = [...focusedSections]
      let changedLabIndex = _focusedSections.indexOf(e.target.name);
      if (changedLabIndex !== -1) {
        _focusedSections[changedLabIndex] = e.target.value
      }

      setFocusedSections(_focusedSections)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    let data =  {}
     let [oldsec, newSec] = removeUnchangedData(prevSections, focusedSections)
    let current = {
      "Sections": oldsec,
      "Lab": prevLab,
    }
    let updated = {

      "Lab": lab,
      "Sections": newSec,
    }
    data["Current"]=current
    data["Updated"]=updated
    updateSectionsAndLabs(data).then(res => {
      props.setLabs({... res.Data})
      handleEditClose()
    }).catch(err => {
      console.log(err)
    })

  }

  const addNewSection = (lab)=>{
      addSectionAPI(lab,newSection).then(res =>{
        props.setLabs({... res.Data})
        setNewSection("")
      }).catch(err=>{
        console.log(err)
      })
  }

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
        <Grid item xs={12}>
             <NewLab setLabs={props.setLabs}/>
          </Grid>
          <Grid item xs={12}>
          {Object.keys(props.labs).map(lab => (
        <ExpansionPanel expanded={expanded === lab} onChange={handleChange(lab)} key={lab}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div>

              <Typography className={classes.heading}>{lab}</Typography>

            </div>
            <div>
              <Button size="small" color="primary" vairant="outlined" onClick={() => { handleEditOpen(lab, props.labs[lab]) }}>edit</Button>
            </div>
            <div>
              <Button size="small" color="primary" vairant="outlined" onClick={() => { setAddSection(true)}}> Add Section to this Lab</Button>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>


            <ul style={{ "listStyle": "none" }}>
              <Typography className={classes.secondaryHeading}>
                List of Labs
            </Typography>
              {props.labs[lab].map(section => (
                <li key={section}>  <Typography>

                  <br />
                  {section}
                </Typography>
                </li>
              ))}

{addSection ? 
<div>
  <TextField value={newSection} onChange={handleNewSection} variant="standard" placeholder="Add new Section"/>
  <IconButton onClick={()=>{addNewSection(lab)}} color="primary" disabled={newSection.length<2}><SendIcon /></IconButton>
  </div>:<span/>}
            </ul>

          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
          </Grid>
      </Grid>
      
      
      <Dialog open={openEdit} onClose={handleEditClose} maxWidth="lg">

        <form onSubmit={handleSubmit}>
          <DialogTitle > Edit Sections and labs</DialogTitle>
          <DialogContent className={classes.content}>

            <TextField className={classes.TextField} label="lab" variant="outlined" name={"section"} value={lab} onChange={handleLabEdit} fullWidth />

            {focusedSections.map(section => (
              <TextField className={classes.TextField} label="section" variant="outlined" name={section} value={section} onChange={handleSectionEdit} fullWidth />
            ))}
          </DialogContent>
          <DialogActions>
            <Button size="medium" variant={'contained'} color="primary" onClick={handleSubmit}>Submit Changes</Button>
            <Button size="medium" variant="contained" color="secondary" onClick={handleEditClose}>Cancel</Button>
          </DialogActions>

        </form>
      </Dialog>

    </div>
  );
}

export default ControlledExpansionPanels;
