import React from 'react'



const SortComponents = ({ children, by }) => {
    if (!by) {
        return children
    }
    return React.Children.toArray(children).sort((obj1, obj2) => {
        if (obj1.props[by] < obj2.props[by]) {
            return -1
        } else if (obj1.props[by] < obj2.props[by]) {
            return 1
        } else {
            return 0
        }
    })
}

export default SortComponents