import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import PropTypes from "prop-types";
import { isEmpty } from "helperFunctions";


const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);


function SelectMultipleOptions(props) {
    const buildDefaultState = () => {
        let _state = {}
        if (isEmpty(props.options)) {
            props.defaultOptions.forEach(opt => {
                _state[opt] = false
            })
            return _state
        }
        return props.options
    }

    const [performedLocations, setPerformedLocations] = useState(buildDefaultState())

    const handleChange = location => event => {
        setPerformedLocations({ ...performedLocations, [location]: event.target.checked })
        props.onChange(props.field, { ...performedLocations, [location]: event.target.checked })
    }


    return (
        <section>
            <FormGroup>

                {Object.keys(performedLocations).map(location => (
                    <FormControlLabel
                        control={<Checkbox checked={performedLocations[location]} onChange={handleChange(location)} value={performedLocations[location]} />}
                        label={location}
                    />
                ))}

            </FormGroup>
        </section>

    )
}

SelectMultipleOptions.propTypes = {
    field: PropTypes.string,
    options: PropTypes.object,
    defaultOptions: PropTypes.array,
    onChange: PropTypes.func,
}


export default SelectMultipleOptions