import React from "react";
import { ReactDOM } from "react-dom";
import grey from "@material-ui/core/colors/grey";
import { Grid, Typography } from "@material-ui/core";

const sectionFooter = {

	minHeight: "15vh",
	backgroundColor: grey[200],
	marginTop:"2.5%",
	borderRadius: "5px"
};

const footer = () => {
	return (
		<section style={sectionFooter}>
			<Grid container direction="row" justify="space-around"  spacing={10}>
				<Grid item>
					<Typography component={"span"} variant="subtitle1" gutterBottom>
						The University of Michigan Department of Pathology
					</Typography>
					<Typography component={"span"} variant="caption">
						<address>
							NCRC Building 30
							<br/>
							2800 Plymouth Road, Ann Arbor, Michigan 48109-2800, ph: <a href="tel:+18008627284">800-862-7284</a>
						</address>
					</Typography>
				</Grid>
				<Grid item>
					<Typography component={"span"} variant="subtitle1" gutterBottom>
						For Technical Questions or Comments
					</Typography>
					<Typography component={"span"} variant="caption">
						<address>
							email:<a href = "mailto:path-handbook@med.umich.edu" target="_blank">path-handbook@med.umich.edu</a>
						</address>
					</Typography>
				</Grid>
			</Grid>
		</section>
	);
};

export default footer;
