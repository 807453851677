import defaultState from "../defaultStore";
import { notificationMessage } from "../actions/TestActions";
import { withReduxStateSync } from "redux-state-sync";

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case "labsPreLoad":
			return {
				...state,
				labs: action.payload
			};
		case "checkedLabs":
			return {
				...state,
				checkedlabs: action.payload
			};
		case "loadTests":
			return {
				...state,
				tests: action.payload
			};
		case "addParams":
			return {
				...state,
				params: action.payload
			};
		case "toggleLoading":
			return {
				...state,
				loading_bool: action.payload
			};
		case "loggingUser":
			return {
				...state,
				user: action.payload
			};
		case "logoutUser":
			return {
				...state,
				user: action.payload
			};
		case "toggleNotification":
			return {
				...state,
				notificationOpen: action.payload
			};
		case "notificationMessage":
			return {
				...state,
				notificationMessage: action.payload
			};
		case "addDraft":
			return {
				...state,
				draft: action.payload
			};
		case "testDetails":
			return {
				...state,
				test_details: action.payload
			};
		case "numRecords":
			return {
				...state,
				num_records: action.payload
			};
		case "searchMode":
			return {
				...state,
				search_mode: action.payload
			};
		case 'searchText':
			return {
				...state,
				search_text: action.payload
			};
		case "fieldTypes":
			return {
				...state,
				test_field_types: action.payload
			}
		case "setPage" :
			return {
				...state,
				page: action.payload
			}
		case "setRowsPerPage" :
			return {
				...state,
				rowsPerPage: action.payload
			}

		default:
			return state;
	}
};
export default withReduxStateSync(reducer);
