import React,{useState,useEffect} from 'react';

import {Select, MenuItem,FilledInput} from "@material-ui/core";

function DropDown(props){

    const [type,setType] = useState(props.type);


    const handleChange=(e)=>{
        setType(e.target.value)
        let _fieldObj= {...props.fieldObj}
        _fieldObj["type"]= e.target.value
        props.handleFieldEdits(props.fieldName, _fieldObj)
    }

    return (
        <Select
          value={type}
          onChange={handleChange}
          input={<FilledInput fullWidth color="primary" name="Type" id="filled-type-dropdown" />}
        >
          <MenuItem value="">
            <em>default</em>
          </MenuItem>
          <MenuItem value={"WYSIWYIG"}>EDITOR</MenuItem>
          <MenuItem value={"SWITCH"}>SWITCH (Yes or No)</MenuItem>
          <MenuItem value={"NUMBER"}>NUMBER</MenuItem>
          <MenuItem value={"TAT_SELECT"}>TIME SELECT</MenuItem>
          <MenuItem value={"RADIO"}>Radio Field</MenuItem>
        </Select>
    )
}

export default DropDown