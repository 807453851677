import { takeLatest, call, put, all, fork , select} from 'redux-saga/effects';
import { fetchTests, SearchTests } from '../../httpCalls/fetchFuncs';

// Action Types
const ADD_PARAMS = 'addParams';
const CHECKED_LABS = 'checkedLabs';
const SET_TESTS = 'loadTests';
const SET_NUM_RECORDS = 'numRecords';
const SET_LOADING = 'toggleLoading';
const SEARCH_TEXT = 'searchText';

const getParams = state => {
    return state.params; 
};

const getCheckedLabs = state => {
    return state.checkedlabs;
};

const getSearchVal = state =>{
    return state.search_text;
}

// Saga to fetch tests and dispatch an action with the fetched tests
function* getTests(action) {
    yield put({ type: SET_LOADING, payload: true });
    let tests = [];
    let num_records = 0; 

    try {
        const params = yield select(getParams);
        const checkedLabs = yield select(getCheckedLabs);
        const searchVal = yield select(getSearchVal);
        if (searchVal.length !== 0 ) {
            const _data = yield SearchTests(params, searchVal).catch(error => console.error('Error fetching tests based on search :', error));
            tests = _data.tests;
            num_records = _data.num_records
        }else {
            const _data = yield fetchTests(params, checkedLabs).catch(error => console.error('Error fetching tests:', error));
            tests = _data.tests;
            num_records = _data.num_records;
        }
        yield put({ type: SET_TESTS, payload: tests });
        yield put({ type: SET_NUM_RECORDS, payload: num_records});
    } catch (error) {
        console.error('Failed to fetch tests', error);
    } finally{
        yield put({ type: SET_LOADING, payload: false });
    }
}


export default function* watchActionsAndFetchTests() {
    yield takeLatest([ADD_PARAMS, CHECKED_LABS, SEARCH_TEXT], getTests);
}


