import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { FetchSectionForLab } from "http_calls/fetchFuncs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const useStyles = makeStyles(theme => ({
    labSelect: {
        width: "50%",
        height: "200%",
        padding: "1% 5% 1% 5%"
    }
}));
const ITEM_HEIGHT = 108;
const ITEM_PADDING_TOP = 8;

function SelectAutocompleteComponent(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [options, setOptions] = useState([props.options])
    const [selectedOption, setSelectedOption] = useState(props.selectedValue);
    useEffect(() => {
        if (props.dependsOn === "Lab" && props.draft.Lab) {
            FetchSectionForLab(props.draft.Lab).then(res => {
                setOptions(res.Data)
            }).catch(err => console.error(err))
        }
    }, [props.draft.Lab])
    function handleChange(event) {
        setSelectedOption(event.target.value);
        props.onChange(props.field, event.target.value)
    }
    return (
        <Select
            value={selectedOption}
            className={classes.labSelect}
            onChange={handleChange}
            variant="outlined"
            inputProps={{
                name: "lab",
                id: "lab-name"
            }}>
            {options.map(opt => (
                <MenuItem value={opt}>{opt}</MenuItem>
            ))}
        </Select>
    );
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );
const mapStateToProps = state => ({
    draft: state.draft
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectAutocompleteComponent);
