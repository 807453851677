import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Typography,
    Input,
    Select,
    MenuItem,
    LinearProgress,
} from "@material-ui/core";
import { useForm, Controller } from 'react-hook-form';
import plusIcon from '@material-ui/icons/Add';
import { AddUser, searchLdap } from 'http_calls/userHttp'
import EditPermissionsForm from './editPermissionForm';
import {
    toggleNotification,
    notificationMessage
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EditPermissions from "./editPermissionsDialog";
import TypeAhead from "./unameTypeAhead";
import {isNullOrUndefined} from 'helperFunctions';

const useStyles = makeStyles(theme => ({
    TextField: {
        minWidth: '25vw',
    },
    progressRoot:{
        flexGrow:1
    }
}));


function setKeyToImmutable(obj,key,value){
     obj[key] =value
     return obj
}

function getDefaultUserState(){
    return {
        uname: '',
        first_name: '',
        last_name: '',
        role: {
            "name": "lab_tech",
            "labs": [],
            "finalize_request_change": false,
            "request_change": false,
            "view_all_lab_tests": false,
            "synonym_ops": false,
            "major_request_change": false,
            "edit_page_layout": false,
            "clia_approval": false
        },
        drafts: {},
        searchs: {},
        app: ""
    }
}

function AddUserForm(props) {
    let lastTriggeredTime;
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [user, setUser] = useState(getDefaultUserState())
    const [showPermissions, setShowPermissions] = useState(false)
    const [unameSearchs, setUnameSearchs] = useState([])
    const [showLoading,setShowLoading] = useState(false)
    const [errors,setErrors] = useState({
        uname:false,
        first_name:false,
        last_name:false,
    })
    const classes = useStyles()

    const handleClose = () => {
        setUser(getDefaultUserState)
        setShowPermissions(false);
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)

    }
    const handleSubmit = () => {
        event.preventDefault();
        let _newUser = { ...user }

        let _errors ={...errors}
        if (_newUser.first_name.length <1) {
            _errors.first_name=true
        }
        if(_newUser.last_name.length <1 ){
            _errors.last_name = true
        }
        setErrors(_errors)
        AddUser(_newUser).then(res => {
            if (res.msg.IsError === false) {
                setShowPermissions(true)
                props.refreshUsers()
                props.toggleNotification(true)
                props.notificationMessage(res.msg.Data);
            } else {
                setErrors(setKeyToImmutable({...errors}, "uname", true))
            }
        }).catch(err => console.error(err))
    }

    // const handleFirstName = (e)=>{
    //     let _user = {... user}
    //     _user.first_name = e.target.value
    //     setUser(_user)
    // }
    // const handleLastName =(e)=>{
    //     let _user = {... user}
    //     _user.last_name = e.target.value
    //     setUser(_user)
    // }

    const handleUnameChange = (e) => {
        if (e.target){
        let value = e.target.value
        let _user = { ...user }
        _user.uname = value
        setUser(_user)
        if (value.length > 3) {
            setShowLoading(true)
            let _currentTime = new Date()
            if (_currentTime-lastTriggeredTime > 5000 || isNullOrUndefined(lastTriggeredTime) ){
                searchLDAP(value)
            }
        }else{
            setShowLoading(false)
            
        }
        }
    }

    const searchLDAP=(uname)=>{
        lastTriggeredTime = new Date()
        searchLdap(uname).then(res => {
            setUnameSearchs([...res.msg.Data])
        }).catch(err => console.error(err))
    }
    const handleOnUnameSelect = (selectedItem)=>{
        setErrors(setKeyToImmutable({...errors}, "uname", false))
        let _user = { ...user }
        _user.uname = selectedItem.Uname
        _user.first_name=selectedItem.FirstName
        _user.last_name= selectedItem.LastName
        console.log( "selected username is ", _user)
        setUser(_user)
        setUnameSearchs([])
        setShowLoading(false)
    }
    const closeProgress =()=>{
        setShowLoading(false)
    }

    useEffect(() => {
        if (unameSearchs > 0){
            showLoading(false)
        }   
        // return () => {
        //     setShowPermissions(false);
        //     setOpen(false);
        //     setUnameSearchs([]);
        //     setUser(getDefaultUserState());
        // }
    }, [unameSearchs])
    return (
        <div>
            <Button id="add-new-user" variant="contained" size="medium" color="primary" onClick={handleOpen}>
                <plusIcon /> Add New User
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                {showLoading? <LinearProgress/>:<span/>}
                <form method="post" onSubmit={handleSubmit} >
                    <DialogTitle>
                        Add New User
                </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            item
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={2}>
                            <Grid item xs={12}>
                                <TypeAhead uname={user.uname} 
                                    handleUnameChange={handleUnameChange} 
                                    suggestions={unameSearchs} 
                                    handleOnUnameSelect={handleOnUnameSelect} 
                                    stopProgress={closeProgress}
                                    error={errors.uname}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                        <TextField
                                            id="outlined-first-name-input"
                                            label="First Name"
                                            type="text"
                                            name="first_name"
                                            value={user.first_name}
                                            className={classes.TextField}
                                            variant="outlined"
                                            helperText=
                                            "Please enter First Name."
                                            error={errors.first_name}
                                        />

                            </Grid>
                            <Grid item xs={12}>
                                        <TextField
                                            id="outlined-first-name-input"
                                            label="Last Name"
                                            type="text"
                                            name="last_name"
                                            value={user.last_name}
                                            className={classes.TextField}
                                            variant="outlined"
                                            helperText=
                                            "Please enter Last Name."
                                            error={errors.last_name}
                                        />

                            </Grid>
                        </Grid>
                          {showPermissions ?
                            <div>
                                <br />
                                <Typography>
                                    Edit permissions for {user.last_name}, {user.first_name}
                                </Typography>
                                <EditPermissions user={user} allLabs={props.allLabs} refreshUsers={props.refreshUsers} />
                            </div> : <span />}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" type="submit">Save</Button>
                        <Button color="secondary" onClick={handleClose}>  Cancel </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);