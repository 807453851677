import axios from "axios";

const jsonConfig = {
    headers: {
        "Content-Type": "application/json"
    }
}

const mimeJsonConfig = {
    headers: {
        "Content-Type": "multipart/form-data"
    }
}


const fetchAllPendingTests = async (userName) => {
    let result;
    await axios.get(`/pending/tests/${userName}`).then(res => {
        result = res.data;
    }).catch(err => {
        console.error(err)
    })
    return result;
}

const fetchAllPendingTestsBasedOnLabs = async (userName) => {
    let result;
    await axios.get(`/pending/tests/labs/${userName}`).then(res => {
        result = res.data
    }).catch(err => {
        console.error(err)
    })
    return result;
}

export { fetchAllPendingTests, fetchAllPendingTestsBasedOnLabs };