import React, { useState, useEffect } from "react";
import { withStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import NavBar from "./header";
import MobileNavBar from "./MobileNavBar";
import Grid from "@material-ui/core/Grid";
import Hero from "./Hero";
import DisplayTestResults from "./DisplayTestResults";
import MobileFilters from "./MobileFilters";
import LabChips from "./LabChips";
import Footer from "./footer";
import blue from "@material-ui/core/colors/blue";
import SearchTabs from "./tabs";
import NotificationSnackbar from "../Notification";
import { connect } from "react-redux";
import Loading from "../loading";

const styles = theme => ({
    root: {
        backgroundColor: "whitesmoke"
    },
    sectionLayout: {
        minHeight: "75vh",
        // backgroundImage: "url('/background_lab.jpg')"
    }
});

const Layout = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [openLabsList, setOpenLabsList] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    // other state variables can be declared similarly

    const handleOpenLabsList = () => setOpenLabsList(true);
    const handleLabsListClose = () => setOpenLabsList(false);
    const openModalHandler = () => setOpenModal(true);
    const closeModalHandler = () => setOpenModal(false);

    const { classes } = props;

    // If there are any useEffects needed, you can add them here.

    return (
        <div className={classes.root}>
            <header>
                {isMobile ? <MobileNavBar /> : <NavBar showSearch={true} />}
            </header>
            <section className={classes.sectionLayout}>
                <div>
                    <div>
                        {isMobile ? <MobileFilters openLabsList={openLabsList}
                            handleLabsListClose={handleLabsListClose}
                            handleOpenLabsList={handleOpenLabsList}
                            checked={[]} />
                            :
                            <Hero
                            openLabsList={openLabsList}
                            handleLabsListClose={handleLabsListClose}
                            handleOpenLabsList={handleOpenLabsList}
                            checked={[]} // Update accordingly if needed
                            />
                        }
                        
                        <LabChips />
                    </div>
                    <Grid container direction="row" justify="center" spacing={4}>
                            <Grid item xs={3}>
								{/* <SideLinks css={cssProps} gridValue={6}/> */}
							</Grid>
							<Grid item xs={6}>								
								<DisplayTestResults />
							</Grid>
							<Grid item xs={3} />

                    </Grid>
                </div>
                <NotificationSnackbar />
            </section>
            <footer>
            <Loading/>
                <Footer />
               
            </footer>
        </div>
    );
};

const LayoutPage = withStyles(styles)(Layout);

export default connect(state => state)(LayoutPage);