import React,{useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Typography,
    Input,
    Select,
    MenuItem,
    LinearProgress,
} from "@material-ui/core";
import { useForm, Controller } from 'react-hook-form';
import plusIcon from '@material-ui/icons/Add';

import { addNewCLIADirector } from '../../../httpCalls/fetchLocations';
import {
    toggleNotification,
    notificationMessage
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TypeAhead from "./unameTypeAhead";
import { isNullOrUndefined } from 'helperFunctions';

const useStyles = makeStyles(theme => ({
    TextField: {
        minWidth: '25vw',
    },
    progressRoot: {
        flexGrow: 1
    }
}));





function AddNewCLIADirector(props) {
    const [open, setOpen] = useState(false);
    const [cliaDirector, setCliaDirector] = useState("");
    const [location, setLocation] = useState("");
    const [unameSearchs, setUnameSearchs] = useState([]);
    const [errors,setErrors] = useState({
        location:false,
        cliaDirector:false,
    });

    const classes = useStyles()

    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        let _obj ={
            clia_director : cliaDirector,
            location,
        }
        let _errors = {...errors}
        if(_obj.clia_director.length < 6 ){
        _errors.cliaDirector  = true;
        return;
        }
        if(_obj.location.length < 1) {
            _errors.location = true;
            return;
        }
        console.log("submitting ", _obj );

        addNewCLIADirector(_obj).then(res => {
            props.toggleNotification(true);
            props.notificationMessage(res);
            props.refreshData();
            setOpen(false);
        }).catch(err => console.error(err))
    }




    return (
        <div>
            <Button id="add-new-clia-director" variant="contained" size="medium" color="primary" onClick={handleOpen}>
                <plusIcon /> Add New CLIA Director
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                
                <form method="post" onSubmit={handleSubmit} >
                    <DialogTitle>
                        Add New CLIA Director
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-clia-director"
                                    item
                                    label="clia director"
                                    type="text"
                                    name="clia_director"
                                    value={cliaDirector}
                                    onChange={e=>{setCliaDirector(e.target.value)}}
                                    className={classes.TextField}
                                    variant="outlined"
                                    helperText=
                                    "Please enter CLIA Director's uname."
                                    error={errors.clia_director}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-Clia-location"
                                    item
                                    label="clia location"
                                    type="text"
                                    name="location"
                                    value={location}
                                    onChange={e=>{setLocation(e.target.value)}}
                                    className={classes.TextField}
                                    variant="outlined"
                                    helperText=
                                    "Please enter Location"
                                    error={errors.location}
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" type="submit">Save</Button>
                        <Button color="secondary" onClick={handleClose}>  Cancel </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCLIADirector);