import axios from "axios";
import 'regenerator-runtime/runtime.js';

const formConfig = {
	headers: {
		"Content-Type": "application/x-www-form-urlencoded"
	}
};
const jsonConfig = {
	headers: {
		"Content-Type": "application/json"
	}
}

const submitUserCredentials = async userCredentials => {
	let result = "";
	await axios
		.post("/user/auth", userCredentials, formConfig)
		.then(res => {
			result = res.data;
		})
		.catch(err => {
			return err;
		});
	return result;
};

const submitUserUpdate = async search => {
	let result = {}
	await axios.post("/user/search", search, jsonConfig)
		.then(res => {
			console.log("inside userhttp",res.data)
			result = res.data.user
		})
		.catch(err => {
			return err;
		})
	return result
};

const logoutUser = async () => {
	let msg = ""
	await axios.get("/user/logout", jsonConfig).then(res => {
		msg = res.data.msg;
	}).catch(err => {
		msg = err;
	})
	return msg
}

const submitDraft = async (username, test, changeType) => {
	let result;
	await axios.post(`/user/draft/${test.TestName}/${username}/submit/${changeType}`, test, jsonConfig).then(res => {
		result = res.data.msg
	}).catch(err => {
		if (err.response) {
			if (__isBrowser__) {
				console.error(err)
				// window.location.pathname = "/"
			} else {
				console.log(err)
			}
		} else {
			console.error(err, ` while Submitting Draft`)
		}
	})
	return result;
}


const postDraft = async (username, test, draftname) => {
	let draftNameParam = draftname
	if (draftNameParam === undefined) {
		draftNameParam = test.TestName
	}
	let result;
	await axios.post(`/user/draft/${draftNameParam}`, test, jsonConfig).then(res => {
		result = res.data
	}).catch(err => {
		if (err.response) {
			if (__isBrowser__) {
				window.location.pathname = "/"
			}
		} else {
			console.error(err, ` while Adding Drafts to user obj.`)
		}

	})
	return result;
};

const getAccess = async (lab, flag) => {
	let result;
	let arg_str = `lab=${lab}&flag=${flag}`
	await axios.get("/permissions?" + arg_str, jsonConfig).then(res => {
		result = res
	}).catch(err => {
		console.error(err, ` error occured while getting access, please Re-Login .`)
	})
	return result;
}

const checkAdminAccess = async() =>{
	let result={};
	await axios.get("/access/admin/permission").then(res=>{
		result=res
	}).catch(err=>{
		console.error(err)
	})
	return result;
}

const checkAccessForAllLabs = async () => {
	let result;
	await axios.get("/access/approval/permissions").then(res => {
		result = res
	}).catch(err => {
		console.error(err)
	})
	return result

}

const getAllUsers = async ()=>{
	let result;
	await axios.get("/user/all").then(res=>{
		result =res
	}).catch(err=>{
		console.error(err)
	})
	return result;
}

const deleteUser = async (username)=>{
	let result;
	await axios.delete(`/user/edit/${username}`).then(res=>{
		result=res.data;
	}).catch(err=>{
		console.error(err)
	})
	return result
}

const editPermissions = async ( role,username)=>{
	let result;
	await axios.post(`/user/edit/${username}`,role,jsonConfig).then(res=>{
		result= res.data
	}).catch(err=>{
		console.error(err)
	})
	return result
}

const checkAccessForLayoutEdit = async () =>{
	let result;
	await axios.get("/access/layout/edit").then(res => {
		result = res
	}).catch(err => {
		console.error(err)
	})
	return result
}

const getUserLabs = async () => {
	let result;
	await axios.get("/user/labs").then(res => {
		result = res
	}).catch(err => {
		console.error(err, 'Error Occured while getting user allowed Labs')
	})
	return result;
}

async function checkUserIsLoggedIn() {
	let result;
	result = await axios.get("/user/verify").then(res => {
		return res.data
	}).catch(err => {
		return err
		// console.error(err,'Error occured while getting user details')
	})
	return result;
}

async function getNumberOfDrafts() {
	let result;
	result = await axios.get(`/user/drafts/number`).then(res => {
		return res.data
	}).catch(err => {
		return err;
	})
	return result;
}

async function getDrafts() {
	let result;
	result = await axios.get(`/user/drafts`).then(res => {
		return res.data
	}).catch(err => {
		return err;
	})
	return result;
}

async function deleteDraft(draftname) {
	let result;
	result = await axios.delete(`/user/draft/${draftname}`).then(res => {
		return res.data
	}).catch(err => {
		return err;
	})
	return result;
}

async function CliaLocationsOfUser(username) {
	let result;
	result = await axios.get(`/clia/access/locations/${username}`).then(res => {
		return res.data.msg;
	}).catch(err => {
		return err;
	})
	return result;
}


async function DeleteSaveSearch(username, filterName) {
	let result;
	result = await axios.delete(`/user/search/${username}/${filterName}`).then(res => {
		console.log(res.data);
		return res.data
		
	}).catch(err => {
		return err
	})
	return result
}

async function AddUser(user){
	let result;
	result = await axios.post('/user/new',user,jsonConfig).then(res=>res.data).catch(err=>err)
	return result 
}

async function getAllPermissions(){
	let result;
	result =await axios.get("/user/permissions/all").then(res=>res.data).catch(err=>err)
	return result;
}

async function getPermissionOfRole(role){
	let result;
	result =await axios.get(`/user/permissions/role/${role}`).then(res=>res.data).catch(err=>err)
	return result;
}

async function searchLdap(uname){
	let result ;
	await axios.get(`/user/ldap/search/${uname}`).then(res=>{result = res.data}).catch(err=>err);
	console.log("search Ldap ", result)
	return result;
}

export {
	submitUserCredentials,
	submitUserUpdate,
	logoutUser,
	postDraft,
	getAccess,
	checkAdminAccess,
	checkAccessForLayoutEdit,
	getUserLabs,
	getAllUsers,
	AddUser,
	checkUserIsLoggedIn,
	getNumberOfDrafts,
	getDrafts,
	deleteDraft,
	deleteUser,
	editPermissions,
	submitDraft,
	checkAccessForAllLabs,
	CliaLocationsOfUser,
	DeleteSaveSearch,
	getAllPermissions,
	getPermissionOfRole,
	searchLdap,
};