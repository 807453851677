import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/lab/Slider';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
}));



function valuetext(value) {
    return `${value}°C`;
}

export default function SliderComponent(props) {
    const classes = useStyles();
    const [value, setValue] = useState(props.min)
    const handleOnclick = () => {
        if (value === props.min) {
            setValue(props.max)
        } else {
            setValue(props.min)
        }

    }
    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Grid item xs={4}>
                <Typography  >
                    Continuos
      </Typography>
            </Grid>
            <Grid item xs={4}>
                <Slider
                    value={value}
                    getAriaValueText={valuetext}
                    aria-labelledby="coutinous-batched-slider"
                    onClick={handleOnclick}
                    marks
                    min={props.min}
                    max={props.max}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography  >
                    Batched
      </Typography>
            </Grid>
        </Grid>
    );
}
