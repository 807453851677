import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";

export default function CLIAStatusIndicator(props) {
    const ApprovedIcon = (loc) => {
        return <Tooltip title={"CLIA Approved" + " for " + loc} ><CheckCircleIcon color="primary" /></Tooltip>
    }
    const waitingIcon = (loc) => {
        return <Tooltip title={"Waiting for CLIA Approval " + " for " + loc} ><HourglassFullIcon color="secondary" /></Tooltip>
    }


    return (
        <List component="ul" aria-label="Clia status">
            {Object.keys(props.testPerformedLocations).map((location) => (
                <ListItem >
                    <ListItemIcon>
                        {props.testPerformedLocations[location] === true ? ApprovedIcon(location) : waitingIcon(location)}
                    </ListItemIcon>
                    <ListItemText primary={location} />
                </ListItem>
            ))}

        </List>
    )
}