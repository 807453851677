import React, { useEffect, useState, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid'
import paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { fetchAllPendingTests, fetchAllPendingTestsBasedOnLabs } from 'http_calls/fetchPendingTests';
import { checkAccessForAllLabs, CliaLocationsOfUser } from "http_calls/userHttp";
import { connect } from "react-redux";
import DraftCard from '../DraftPreviewCard';
import { bindActionCreators } from "redux";
import { notEmpty, makeArrayUnique } from "helperFunctions";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TestPreviewCard from './TestPreviewCard'
import FilterPendingTestsComponent from './filterPendingTests';
import { Card, CardContent } from '@material-ui/core'
import { PlayCircleFilledWhite } from '@material-ui/icons';
import { updatePendingTests, approveTest, rejectTest } from "http_calls/fetchFuncs";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    listHeader: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,

    }

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PendingChanges(props) {
    const classes = useStyles();
    const [pendingTests, setPendingTests] = useState({})
    const [pendingTestsAllLabs, setPendingTestsAllLabs] = useState({})
    const [showLabFilters, setShowLabFilters] = useState(true)
    const [selectedLabs, setSelectedLabs] = useState([])
    const [searchTestName, setSearchTestName] = useState("")
    const [labApprovalAccess, setlabApprovalAccess] = useState(false)
    const [userApprovalLocations, setUserApprovalLocations] = useState([])

    useEffect(() => {
        getAccessOfUser();
        refreshData()
    }, [props.open, showLabFilters])



    const refreshData = () => {
        if (showLabFilters) {
            getAllPendingTestsBasedOnLabs();
        } else {
            getAllPendingTests();
        }
    }

    const getAccessOfUser = () => {
        checkAccessForAllLabs().then(res => {
            setlabApprovalAccess(res.data.finalize_request_change)
            if (res.data.clia_approval === true) {
                CliaLocationsOfUser(props.user.uname).then(response => {
                    if (response.Data) {
                        setUserApprovalLocations(response.Data)
                    }
                })
            }
        }).catch(err => console.error(err))
    }

    const getAllPendingTests = () => {
        fetchAllPendingTests(props.user.uname).then(res => {
            if (res.IsError == false) {

                setPendingTests(res.Data)
            }
        }).catch(err => { console.error(err) })
    }

    const getAllPendingTestsBasedOnLabs = () => {
        fetchAllPendingTestsBasedOnLabs(props.user.uname).then(res => {
            if (res) {
                if (res.IsError == false) {
                    setPendingTestsAllLabs(res.Data)
                }
            }
        }).catch(err => { console.error(err) })
    }

    const handleToggle = (e) => {
        setShowLabFilters(e.target.checked)
    }
    const handleSelectedLabs = (picked, labs) => {
        setSelectedLabs([...labs])
    }
    const handleToggleLabs = (labs) =>{
        let labSet = new Set([...labs])
        setSelectedLabs(Array.from(labSet))
    }

    const handleSearchChange = (e) => {
        setSearchTestName(e.target.value)
    }
    const removeSelectedLabs = (data)=>{
        let _labs = [...selectedLabs];
		let currentIndex = _labs.indexOf(data);
		if (currentIndex > -1) {
			_labs.splice(currentIndex, 1);
            //remove labs on delete
			setSelectedLabs(_labs);
		}
    }


    return (
        <article>
            <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Pending Changes
                        </Typography>
                        <Button color="inherit" onClick={props.handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <section>
                    <Card>
                        <CardContent>
                            <FilterPendingTestsComponent
                                showToggle={true}
                                display={showLabFilters}
                                onToggleDisplayLabs={handleToggle}
                                selectedLabs={selectedLabs}
                                handleSelectedLabs={handleSelectedLabs}
                                handleToggleLabs={handleToggleLabs}
                                onSearchChange={handleSearchChange}
                                removeSelectedLabs={removeSelectedLabs}
                                searchValue={searchTestName}
                                showSearch={true} />
                        </CardContent>
                    </Card>
                </section>
                <section>
                    <center>
                        <Typography variant="h4">
                            Tests waiting for
                        </Typography>
                    </center>
                    <Card>
                        <CardContent>
                            <RenderPendingTestsGrid
                                classes={classes}
                                showLabs={showLabFilters}
                                selectedLabs={selectedLabs}
                                pendingTests={pendingTests}
                                pendingTestsWithLabs={pendingTestsAllLabs}
                                searchTestName={searchTestName}
                                labApprovalAccess={labApprovalAccess}
                                userApprovalLocations={userApprovalLocations}
                                refreshData={refreshData} />
                        </CardContent>
                    </Card>

                </section>
            </Dialog>
        </article>
    );
}

function RenderPendingTestsGrid(props) {
    function getTests() {
        let _tests;
        if (props.showLabs) {
            _tests = props.pendingTestsWithLabs
            if (props.selectedLabs.length > 0) {
                _tests = filterPendingTestsWithLabs(props.selectedLabs, props.pendingTestsWithLabs)
            }
        } else {
            _tests = props.pendingTests
        }
        if (props.searchTestName.length > 2) {
            _tests = filerPendingTestsWithTestName(props.searchTestName, _tests)
        }
        return _tests
    }


    if (notEmpty(getTests())) {
        return (
            <Grid container direction="row" justify='space-evenly' alignItems='stretch' spacing={1}>
                {Object.keys(getTests()).map(key => (
                    <Grid container item xs={3} direction="column" justify="flex-start" spacing={1} alignItems="stretch">
                        <List>
                            <ListItem className={props.classes.listHeader}><ListItemText primary={key} primaryTypographyProps={{ variant: "h6" }} />  </ListItem>
                            <Divider fullwidth={true} />
                            {
                                getTests()[key].map(test => (
                                    <ListItem key={test.TestId}>
                                        <TestPreviewCard
                                            test={test}
                                            category={key}
                                            labApprovalAccess={props.labApprovalAccess}
                                            userApprovalLocations={props.userApprovalLocations}
                                            refresh={props.refreshData} />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid>
                ))}
            </Grid >
        )
    }
    return <h1>No pending Tests</h1>
}


function filterPendingTestsWithLabs(labsArr, obj) {
    let _filteredObj = {};
    Object.keys(obj).forEach(k => {
        _filteredObj[k] = obj[k].filter(test => {
            return (labsArr.indexOf(test.Lab) > -1)
        })
    })
    return _filteredObj
}

function filerPendingTestsWithTestName(testName, obj) {
    let _filteredObj = {};
    Object.keys(obj).forEach(k => {
        _filteredObj[k] = obj[k].filter(test => {
            return (test.TestName.toUpperCase().indexOf(testName.toUpperCase()) > -1)
        })
    })
    return _filteredObj
}


function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(PendingChanges);
