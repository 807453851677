import React, { useState,useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from './menuCard'
import update from 'immutability-helper';
import {postLinks} from "http_calls/fetchLinks";
import SortComponents from "../SortComponents";
import FormDialog from "../formDialogComponent";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles(theme => {
  return {
    textField:{
      margin:"4% 1%"
    },
    container: {
      maxWidth: "15vw",
  },
  }

});
function DraggableMenu(props) {
  const classes= useStyles()
    const [links, setLinks] = useState(props.links)
    const [openAddForm,setOpenAddForm] = useState(false)
    const [newLink,setNewLink] = useState({
      name:'',
      url:'',
      pos:links.length +1,

    })
    //use Effect hook
    useEffect(()=>{
        let _links=[]
        //revisit this logic .. 
        links.forEach(l => {
            let _l = {...l}
            if(links.indexOf(l) >=0){
              _l.pos = links.indexOf(l)+1;
            }
          
            _links.push(_l)
        })
       
        postLinks(props.name,_links).then(res=>{
            console.log(res.msg)
        }).catch(err =>{console.error(err)})
    },[links])

    //moveLink  using callback hook
    const moveLink = useCallback(
      (dragIndex, hoverIndex) => {
        const draglink = links[dragIndex]
        setLinks(
          update(links, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, draglink],
            ],
          }),
        )
      },
      [links],
    )

    const handleLinkEdit = (link) =>{
      let _links = [...links]
      _links.map((l)=>{
        if(l.pos === link.pos){
          l.name = link.name
          l.url = link.name
        }
      })
      setLinks(_links)
    }

    const handleLinkDelete =(link) => {
        setLinks(links.filter(l=>l.pos!==link.pos))
    }

    const handleLinkAdd = () => {
      let _links = [...links]
      _links.push(newLink)
        setLinks(_links)
        setOpenAddForm(false)
    }
    const handleNameChange =(e)=>{
      setNewLink({...newLink, name : e.target.value})
    }
    const handleUrlChange = (e)=>{
      setNewLink({ ... newLink, url:e.target.value})
    }
    const handlePosChange = (e) => {
   
        setNewLink({ ... newLink, pos:parseInt(e.target.value)})
      
    }

    const renderlink = (link, index) => {
      return (
        <Card
          key={link.pos}
          index={index}
          id={link.pos}
          text={link.name}
          moveLink={moveLink}
          url={link.url}
          handleLinkEdit={handleLinkEdit}
          handleLinkAdd={handleLinkAdd}
          handleLinkDelete={handleLinkDelete}
        />
      )
    }
    return (
        <div className={classes.container}>
            <Button variant="contained" color="primary" size="small" onClick={()=>setOpenAddForm(true)}> <AddIcon/> Add new Link</Button>
            
            {links.map((link, i) => renderlink(link, i))}
            <FormDialog
              open={openAddForm}
              title='Add new Link'
              handleClose={()=>setOpenAddForm(false)}
              handleSubmit={handleLinkAdd}
              confirmButtonText="Add"
              cancelButtonText="cancel"
            >

<TextField
          id="link-edit-form-name"
          label="Link Display Name"
          helperText="Enter Name of the Link, this will be displayed to users"
          fullWidth={true}
          className={classes.textField}
          type="text"
          value={newLink.name}
          onChange={handleNameChange}
        />

        <TextField
          id="link-edit-form-url"
          label="Link URL"
          helperText="Please enter or paste full URL here."
          fullWidth={true}
          className={classes.textField}
          type="text"
          value={newLink.url}
          onChange={handleUrlChange}
        />
        <TextField
          id="link-edit-form-url"
          label="Link postion in List view"
          helperText="By default it will take last place , please mention a number to change that."
          fullWidth={true}
          className={classes.textField}
          type="number"
          value={newLink.pos}
          onChange={handlePosChange}
        />
            </FormDialog>
        </div>
      
    )
  
}
export default DraggableMenu