import React from "react";
// const window={}
class PostEditor extends React.Component {
	state={
		content:""
	}
	handleEditorChange = content => {
		this.props.handleEditorChange(this.props.field, content)
	};
	

	
	componentDidMount() {
		if (__isBrowser__) {
			require.ensure(["tinymce"], require => {
				require("tinymce");
				require('tinymce/plugins/table')
				require('tinymce/plugins/link')
				require('tinymce/plugins/lists')
				require('tinymce/plugins/image')
				require('tinymce/plugins/imagetools')
				require('tinymce/plugins/save')
				require('tinymce/plugins/wordcount')
				require('tinymce/plugins/paste')

				this.TinyMCELoaded = true;

				tinymce.init({
					selector: '.'+this.props.container,
					plugins: [ 'link', 'table', 'lists', 'image', 'imagetools', 'save', 'wordcount','paste' ],
					toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | save media | codesample help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
					menubar: false,
					save_onsavecallback: () => this.handleSubmit(),
					setup: editor => {
						this.Editor = editor;
						editor.on("keyup change undo redo", () => {
							const content = editor.getContent();
							this.Saved = false;
							this.handleEditorChange(content);
						});
					}
				});
			});
		}
	}

	componentWillUnmount(){
		if(__isBrowser__){
			require.ensure(["tinymce"], require => {
				require("tinymce");	
				tinymce.remove(this.Editor)	
		})
	}
	}

	render() {
		return <span/>;
	}
}

export default PostEditor;