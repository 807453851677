import axios from 'axios';
import React,{useState,useEffect} from 'react'

// if you need  parameters for this URL path , you can send params as an object into 2nd parameter of the function.
export default function useFetch(url,params) {
    const [data,setData] = useState([]);
    useEffect(()=>{
        const fetchData = async ()=>{
            await axios.get(`${url}`).then(res=>{
                setData(res.data.Data);
            }).catch(err=>{
                console.error(err);
            })
        }

        fetchData();

    },[])
    return data;
}
