import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap"
	},
	chip: {
		margin: theme.spacing(1)
	}
}));

//chips
function LabChips(props) {
	const classes = useStyles();



	function renderChip(data, classes) {
		return (
			<Chip
				avatar={<Avatar>LB</Avatar>}
				key={data}
				label={data}
				color={"primary"}
				onDelete={()=>{props.removeSelectedLabs(data)}}
				className={classes.chip}
				data-chip={data}

			/>
		);
	}
	return (
		<div className={classes.root}>
			{props.labs.map(data => {
				return renderChip(data, classes);
			})}
		</div>
	);
}
export default LabChips;
