

export const toggleLabs = (payload)=>({
    type :'checkedLabs',
    payload:payload,
})

export const getLabs =(payload)=>({
    type:'labsPreLoad',
    payload:payload
})