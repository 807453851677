import React from 'react';
import { TextField } from '@material-ui/core'


const ReasonField = () => {
    return
}


export default function CustomTextField(props) {


    const [val, setVal] = React.useState("");

    React.useEffect(() => {
        props.callback(val)
    }, [val])

    const handleChange = (e) => {
        setVal(e.target.value)
    }

    return (
        <TextField
            error={props.error}
            id="reason-full-width"
            label={props.label}
            style={{ margin: 8 }}
            placeholder={props.placeholder}
            fullWidth
            margin="normal"
            multiline={props.multiline}
            value={val}
            rows={props.rows}
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={handleChange}
        />
    )
}