import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "@material-ui/core";

function HomeButton(props) {
	return <Link href={"/"}>{props.elem}</Link>;
}

function handleHomeClick() {
	window.location = "/";
}

export default HomeButton;
