import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { addParams, loadTests, setPage, setRowsPerPage, toggleLoading } from "../../actions/TestActions";

const useStyles = makeStyles(theme => ({
	root: {
		flexShrink: 0,
		color: theme.palette.text.secondary,
		marginLeft: theme.spacing(2.5)
	},
	fullWidth: {
		width: "100%"
	}
}));

function TestPagination(props) {
	const classes = useStyles();

	React.useEffect(() => {
		if (props.params.start === 0) {
			props.setPage(0);
		}
	}, [props.params]);

	function handleChangePage(event, newPage) {
		setGlobalPaginationParams(newPage * props.rowsPerPage, props.rowsPerPage);
		props.setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setGlobalPaginationParams(props.page * props.rowsPerPage, parseInt(event.target.value));
		props.setRowsPerPage(parseInt(event.target.value));
	}

	function setGlobalPaginationParams(start, limit) {
		let _params = props.params;
		_params["start"] = start;
		_params["limit"] = limit;
		props.addParams({ ..._params });
	}

	function getRowsPerPageOptions() {
		if (props.num_records / 50.0 > 1) {
			return [10, 25, 50];
		} else if (props.num_records / 25.0 > 1) {
			return [10, 25, props.num_records];
		} else if (props.num_records / 10.0 > 1) {
			return [10, props.num_records];
		} else {
			return [props.num_records];
		}
	}
	if(props.tests.length == 0 || props.tests === undefined ) {
		return <></>
	}

	return (
		<TablePagination
			rowsPerPageOptions={getRowsPerPageOptions()}
			colSpan={3}
			count={props.num_records}
			rowsPerPage={props.rowsPerPage}
			page={props.page}
			SelectProps={{
				inputProps: { "aria-label": "Rows per page" },
				native: true
			}}
			className={classes.fullWidth}
			onChangePage={handleChangePage}
			onChangeRowsPerPage={handleChangeRowsPerPage}
			ActionsComponent={TablePaginationActions}
		/>
	);
}

function TablePaginationActions(props) {
	const classes = useStyles();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	function handleFirstPageButtonClick(event) {
		onChangePage(event, 0);
	}

	function handleBackButtonClick(event) {
		onChangePage(event, page - 1);
	}

	function handleNextButtonClick(event) {
		onChangePage(event, page + 1);
	}

	function handleLastPageButtonClick(event) {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	}

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="First Page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="Previous Page">
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="Next Page">
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="Last Page">
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

const mapStateToProps = state => ({
	tests: state.tests,
	num_records: state.num_records,
	params: state.params,
	page: state.page,
	rowsPerPage: state.rowsPerPage,
});

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ addParams, loadTests, toggleLoading, setPage, setRowsPerPage }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TestPagination);
