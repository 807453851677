import React, { useState, useEffect,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
    inputTag: {
        background: "white",
        border: "1px solid #d6d6d6",
        borderRadius: "2px",
        display: "flex",
        flexWrap: "wrap",
        padding: "5px 5px 0",
    },
    closeButton: {
        alignItems: "center",
        appearance: "none",
        background: "#333333",
        border: "none",
        borderRadius: "50%",
        color: theme.palette.common.white,
        cursor: "pointer",
        display: "inline-flex",
        fontSize: " 12px",
        height: "15px",
        justifyContent: "center",
        lineHeight: "0",
        marginLeft: "8px",
        transform: "rotate(45deg)",
        width: "15px",
    },

}));
function InputTag(props) {
    const classes = useStyles()
    const inputField = useRef();

    const [tagValue,setTagValue] = useState("");

    const removeTag = (i) => {
        const newTags = [...props.tags];
        newTags.splice(i, 1);
        props.setTags(newTags);
    }
    const handleChange = (e)=>{
        if (e.target.value !== ',' && e.target.value !== ";") {
            setTagValue(e.target.value)
        }
        
    }

    const inputKeyDown = (e) => {
        const val = e.target.value;
        if (e.key === ',' || e.key === ";") {
            if (val) {
                if (props.tags.some(tag => tag.toLowerCase() === val.toLowerCase())) {
                    return;
                }
                props.setTags([...props.tags, val]);
              setTagValue("")
            }
        } else if (e.key === 'Backspace' && !val) {
            removeTag(tags.length - 1);
        } else {
            props.setInputFieldVal(e.target.value)
        }
    }

    return (
        <div className="input-tag">
            <ul className="input-tag__tags">
                {props.tags.map((tag, i) => (
                    <li key={tag}>
                        {tag}
                        <IconButton size="small" onClick={() => { removeTag(i); }}><Close fontSize="small"/></IconButton>
                    </li>
                ))}
                <li className="input-tag__tags__input"><input type="text"  ref={inputField} onKeyDown={inputKeyDown} value={tagValue} onChange={handleChange}/></li>
            </ul>
        </div>
    );

}

export default InputTag