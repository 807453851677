import React,{useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import axios from "axios";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function OfflineModeDialog(props){
    const [open, setOpen] = useState(false)

useEffect(()=>{
     axios.get(`/online/status/check`).then(res=>{
		setOpen(!res.data.online)
	}).catch(err =>{
		console.error(err)

	})
},[])


    return (
      <div>
        <Dialog
          onClose={()=>{setOpen(false)}}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={()=>{setOpen(false)}}>
            Offline Mode
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
             Sorry, for the inconvience.
            </Typography>
            <Typography gutterBottom>
             Due to technical issues, Handbook can used only in a limited way. 
            </Typography>
            <Typography gutterBottom>
                For further Questions
                <br/>
                please contact: <address>
							e:<a href = "mailto:path-handbook@med.umich.edu" target="_blank">path-handbook@med.umich.edu</a>
						</address>
                       <br/>
                       telephone Number : <a href="tel:+18008627284">800-862-7284</a> 
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    );
  }


export default OfflineModeDialog;
