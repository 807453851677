import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Modal, Button, TextField, Grid } from '@material-ui/core';
import SendIcon from "@material-ui/icons/SendSharp"

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 350,
    },
}));

function EnterDraftNameModal(props) {
    const [open, setOpen] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [draftNameParam, setDraftNameParam] = React.useState("")
    const [isDisabled, setDisabled] = React.useState(true);
    const handleOpen = () => {
        setOpen(true);
    };
    React.useEffect(() => {
        if (draftNameParam.length > 1) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [draftNameParam])

    const classes = useStyles();

    function handleDraftNameChange(e) {
        setDraftNameParam(e.target.value)
    }

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={props.open}
                onClose={props.handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    {/*:TODO create new test based on existing test*/}
                    {/* <Grid container direction={"row"}>

                    </Grid> */}
                    <Typography variant="h6" id="modal-title">
                        Please Enter Draft Name
          </Typography>
                    <Grid container direction={"row"}  >
                        <Grid item xs={9}>
                            <TextField
                                id="draft-name-input"
                                placeholder="Draft Name"
                                className={classes.textField}
                                margin="dense"
                                onChange={handleDraftNameChange}
                                value={draftNameParam}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant={"contained"} size="small" color={"primary"} href={"/new/test/" + draftNameParam} disabled={isDisabled}>
                                <SendIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Button variant={"contained"} size="small" color={"secondary"} onClick={props.handleClose}>cancel</Button>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
}

export default EnterDraftNameModal;
