import React, { useState, useEffect } from 'react'
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import AddSynDialog from "../synonyms/AddSynonym";
import { NoSsr } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton, Fab, Button } from "@material-ui/core";
import { Paper, Grid, Link, Typography } from "@material-ui/core/";
import AddIcon from "@material-ui/icons/AddSharp";
import { isEmpty } from "helperFunctions"

const useStyles = makeStyles(theme => ({
    article: {
        padding: "2% 3% 1% 3%"
    },
    div: {
        border: "1px solid #000",
        padding: "1% 1% 1% 1%"
    },
    chip: {
        margin: theme.spacing(1)
    },
    fullwidth: {
        width: "100%"
    }
}));

export default function NewSynonymsComponent(props) {
    const classes = useStyles()
    const [columns, setColumns] = useState([
        { title: "Synonym Name", field: "SYNONYM_NAME" },
        { title: "Mnemonic", field: "MNEMONIC" },
        { title: "Publish", field: "PUBLISH", type: "boolean" },
    ]);
    const [openAddSynDialog, setOpenAddSynDialog] = useState(false);
    function closeAddSynDialog() {
        setOpenAddSynDialog(false);
    }
    function handleAddIcon() {
        setOpenAddSynDialog(true);
    }


    function tableActions() {
        return {
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                        props.updateSynonym(newData, oldData.SYNONYM_NAME)
                        resolve();
                    }, 600)
                }),
            onRowDelete: oldData =>
                new Promise(resolve => {
                    props.removeSynoynm(oldData.SYNONYM_NAME)
                    resolve();
                })
        };

    }

    function editSynonymButtons() {
        return (
            <div>
                <NoSsr>
                    <AddSynDialog
                        open={openAddSynDialog}
                        onClose={closeAddSynDialog}
                        testId={props.testId}
                        addSynonym={props.addSynonym}
                    />
                </NoSsr>
                <Tooltip title="Add New Synonym" placement="top">
                    <Fab
                        color="primary"
                        variant="extended"
                        onClick={handleAddIcon}
                        aria-label="add"
                        size="small">
                        <AddIcon />
                        Add new Synonyms
                    </Fab>
                </Tooltip>
            </div>
        );
    }

    return (

        <Paper component="article" className={classes.article}>
            <Paper component="div" className={classes.div}>
                <Grid container spacing={1}>
                    <MaterialTable title={editSynonymButtons()}
                        columns={columns}
                        style={{ width: "100%" }}
                        data={isEmpty(props.test.Synonyms) ? [] : props.test.Synonyms}
                        editable={tableActions()}
                    >
                    </MaterialTable>
                </Grid>
            </Paper>
        </Paper>
    )
}

