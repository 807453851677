import axios from "axios";

const SearchTests = async (params, str) => {
	let data = {};
	let arg_str = `?limit=${params.limit}&start=${params.start}&query=${str}`;
	let url = "/api/v1/q/search";
	let response = await axios.get(url + arg_str).then(res => {
		data.num_records = res.headers.num_records;
		data.tests = [...res.data.tests];
	})
	return data;
};

const fetchTests = async (params, checkedLabs) => {
	let arg_str = "";
	let num_records = 0;
	let tests = [];

	if (checkedLabs) {
		arg_str += checkedLabs.map(lab => "Lab=" + lab).join("&");;
	}
	if (!params.hasOwnProperty("limit")) {
		params["limit"] = 10;
	}
	if (!params.hasOwnProperty("start")) {
		params["start"] = 0;
	}
	arg_str += "&" + Object.keys(params).filter(key => params[key] !== undefined)
		.map(key => key + "=" + params[key])
		.join("&");
	let url = "/api/v1/internal/tests?" + arg_str;

	await axios.get(url).then(res => {
		num_records = res.headers.num_records;
		if (res.data.IsError === false) {
			tests = res.data.Data;
		}
	}).catch(err => console.error(err));

	return {tests, num_records};
}


const jsonConfig = {
	headers: {
		"Content-Type": "application/json"
	}
}

const mimeJsonConfig = {
	headers: {
		"Content-Type": "multipart/form-data"
	}
}

const getAllLabs = async () => {
	let result;
	await axios.get("/api/v1/labs").then(res => {
		result = res;
	}).catch(err => {
		console.error(err)
	})
	return result;
}

const postSynonym = async (testId, synonym) => {
	let result;
	await axios.post(`/api/v1/synonym/${testId}`, synonym, jsonConfig).then(res => {
		result = res
	}).catch(err => {
		console.error(err)
	})
	return result;
};

const patchSynonym = async (testId, synonymName, synonym) => {
	let result;
	await axios.patch(`/api/v1/synonym/${testId}/${synonymName}`, synonym, jsonConfig).then(res => {
		result = res
	}).catch(err => {
		console.error(err)
	})
	return result;
}


const deleteSynonym = async (testId, synonymName) => {
	let result;
	await axios.delete(`/api/v1/synonym/${testId}/${synonymName}`).then(res => {
		result = res
	}).catch(err => {
		console.error(err)
	})
	return result
}

const FetchSectionForLab = async (lab) => {
	let section;
	await axios.get(`/api/v1/labs/${lab}/section`).then(res => {
		section = res.data.sections
	}).catch(err => {
		console.error(err)
	})
	return section

}

const fetchCategories = async () => {
	let obj;
	await axios.get().then(res => {
		obj = res.data
	}).catch(err => {
		console.error(err)
	})
	return obj
}

const addTest = async (testData) => {
	let result;
	await axios.post(`/api/v1/tests/add`, testData, jsonConfig).then(res => {
		result = res
	}).catch(err => {
		console.error(err)
	})
	return result;
}

const takeDown = async (testID, altTest) => {
	console.log(testID, altTest)
	let result;
	await axios.post(`/test/takedown/${testID}`, altTest, mimeJsonConfig).then(res => {
		result = res
	}).catch(err => {
		console.error(err)
		result = err
	})
	return result
}

const testUp = async (testID, data) => {
	let result;
	await axios.patch(`/test/up/${testID}`, data, jsonConfig).then(res => {
		result = res
	}).catch(
		err => {
			result = err
		}
	)
	return result
}

const updatePendingTests = async (url, test) => {
	let result;
	await axios.patch(url, test, jsonConfig).then(res => {
		result = res
	}).catch(err => {
		console.error(err)
		result = err
	})
	return result;
}

const approveTest = async (url) => {
	let result;
	await axios.patch(url).then(res => {
		result = res;

	}).catch(err => {
		console.error(err)
	})
	return result;
}

const rejectTest = async (url, data) => {
	let result;
	await axios.patch(url, data, jsonConfig).then(res => {
		result = res;

	}).catch(err => {
		console.error(err)
	})
	return result
}

const editFieldTypesCall = async (url, data) => {
	let result;
	await axios.put(url, data, jsonConfig).then(res => {
		result = res.data;
	}).catch(err => {
		console.error(err)
	})
	return result;
}

const docUpload = async (formData) => {
	let result;
	await axios.post(`/api/v1/document/upload`, formData, mimeJsonConfig).then(res => {
		result = { ...res.data }
	}).catch(err => {
		console.error(err)
		result = err
	})
	return result
}

const getAllUploadedDocuments = async (url) => {
	let result;
	await axios.get(url).then(res => {
		result = res
	}).catch(err => {
		console.error(err)
		result = err
	})
	return result;
}


const deleteDocument = async (fileName, lab) => {
	let result;
	await axios.delete(`/api/v1/document/delete?fileName=${fileName}&lab=${lab}`).then(res => {
		result = res.data
	}).catch(err => {
		console.error(err)
		result = err
	})
	return result;
}


const getLabSectionsJson = async () => {
	let result;
	await axios.get('/api/v1/labs/section/all/json').then(res => {
		result = res.data
	}).catch(err => {
		console.error(err)
		result = err
	})
	return result
}

const updateSectionsAndLabs = async (formData) => {
	let result;

	await axios.post('/api/v1/labs/section/rename', formData, jsonConfig).then(res => {
		result = res.data
	}).catch(err => {
		console.error(err)

	})
	return result
}

const addSectionAPI = async (lab, section) => {
	let result;

	await axios.post(`/api/v1/labs/section/new/${lab}/${section}`, jsonConfig).then(res => {
		result = res.data
	}).catch(err => {
		console.error(err)

	})
	return result
}

const addLabAPI = async (lab) => {
	let result;

	await axios.post(`/api/v1/labs/new/${lab}`, jsonConfig).then(res => {
		result = res.data
	}).catch(err => {
		console.error(err)
	})
	return result
}

const deleteTest = async (testId, uname) => {
	let result;
	  await axios.delete(`/api/v1/test/delete/${uname}/${testId}`).then(res => {
		result = res.data
	}).catch(err => {
		console.error(err)
	})
	console.log(result)
	return result;
}

const getReviewPenidingTests = async (labs, params) => {
	let url = "/review/pending/tests"
	if (labs) {
		url += "?"
		labs.forEach(l => {
			url += `lab=${l}&`
		})
	}
	url += `start=${params.start}&`
	url += `limit=${params.limit}`
	let result = {};
	await axios.get(url).then(res => {
		result.data = res.data
		result.num_records = res.headers.num_records
	}).catch(err => {
		console.error(err)
	})
	return result;
}

const completeReview = async (tests, uname) => {
	let result;
	result = await axios.post(`/review/complete/${uname}`, tests, jsonConfig).then(res => {
		return res;
	}).catch(err => { console.error(err) })
	return result;
}

/*

*/
// const getBriefDetails= async() =>{
// 	let result ;
// 	result = await axios.get("/test/brief/fields").then(res=>{
// 		return res;
// 	}).catch(err=>{console.error(err)})
// 	return result;
// }

export {
	getAllLabs,
	SearchTests,
	fetchTests,
	postSynonym,
	patchSynonym,
	deleteSynonym,
	editFieldTypesCall,
	FetchSectionForLab,
	fetchCategories,
	addTest,
	takeDown,
	deleteTest,
	getReviewPenidingTests,
	docUpload,
	getAllUploadedDocuments,
	deleteDocument,
	getLabSectionsJson,
	addSectionAPI,
	addLabAPI,
	updateSectionsAndLabs,
	testUp,
	updatePendingTests,
	completeReview,
	approveTest,
	rejectTest,
};
