import React, { useEffect, useState } from "react";
import {
    DialogActions,
    Grid,
    Button,
    FormControlLabel,
    Checkbox,
    TextField,
} from '@material-ui/core'
import LabTransferList from "./labTransferList";
import {
    toggleNotification,
    notificationMessage
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EditIcon from "@material-ui/icons/EditSharp"
import { editPermissions,CliaLocationsOfUser } from "../../../httpCalls/userHttp";
import RoleDropdown from  "./selectRole";
import {getPermissionOfRole} from "http_calls/userHttp";
import InputTags from "../inputTags/inputTags";


const defaultState = {
    clia_approval: false,
    edit_page_layout: false,
    finalize_request_change: false,
    labs: [],
    major_request_change: false,
    name: "lab_tech",
    request_change: false,
    synonym_ops: false,
    view_all_lab_tests: false,
}

function EditPermissionsForm(props) {
    const [userLabs, setUserLabs] = useState(props.user.role.labs);
    const [allLabs, setAllLabs] = useState(props.allLabs);
    const [role, setRole] = useState(props.user.role);
    const [locations,setLocations] = useState([])
    const [showLocField, setShowLocField] = useState(false);

    useEffect(()=>{
        if(role["clia_approval"]===true){
            CliaLocationsOfUser(props.user.Username).then(res=>{
                setLocations(res.Data)
            }).catch(err=>console.error(err))
        }
        
    },[role])
    const resetState = () => {
        setRole({ ...props.user.role })
    }
    const minimumState = () => {
        setRole({ ...defaultState })
    }

    const handleChange = name => e => {
        let _role ={...role}
        _role[name] = !role[name]
        if(name === "clia_approval" && _role[name]===true){
                setUserLabs([... allLabs])
                Object.keys(_role).forEach(k=>{
                    if (k !== "clia_location"){

                    _role[k] =true
                    }
                })
            setShowLocField(true)
        } else if(name === "clia_approval" && _role[name]===false){
            setUserLabs([... props.user.role.labs])
            setShowLocField(false)
        }

        setRole(_role)
    };
    const handleLocationChange=e=>{
        let _role ={...role}
        _role["clia_location"]=e.target.value
        setRole(_role)
    }

    const handleSubmit = () =>{
        let _role={...role}
        _role.labs=userLabs
        if (_role.clia_approval===true){
            _role.clia_location=locations.join(",")
        }
        if (_role.clia_approval === true && _role.clia_location.length < 1 ){
            props.toggleNotification(true)
            props.notificationMessage("Please Enter Location for CLIA Approval");
            return ;
        }
        editPermissions(_role,props.user.Username?props.user.Username:props.user.uname).then(data=>{
            props.toggleNotification(true)
            props.notificationMessage(data.msg.Data);
            props.refreshUsers()
           props.handleClose()
        }).catch(err=>{
            console.log(err)
        })
    }

    const handleRoleChange=(e)=>{
        let _roleName = e.target.value
        let _role = {... role}
        _role.name = _roleName
        console.log(e)
        getPermissionOfRole(_roleName).then(res=>{
            // console.log("getPermissionorole ---- > ",res)
            if(res.msg.IsError === false){
                let newRole = {... res.msg.Data}
                delete newRole._id
                delete newRole.labs
                if (_role.name === "admin"){
                    setUserLabs([... allLabs])
                }else{
                    setUserLabs([])
                }
                Object.keys(newRole).forEach(k=>{
                    _role[k]= newRole[k]
                })
                setRole(_role)
            }
        }).catch(err=> console.error(err))
    }

    return (
        <div style={{ "minWidth": "40vw" }}>
        <RoleDropdown type={ role.name} handleChange={handleRoleChange} />
        <LabTransferList userLabs={userLabs} allLabs={allLabs} setAllLabs={setAllLabs} setUserLabs={setUserLabs}/>
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid container item xs={6} direction="column">
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={role.clia_approval}
                                onChange={handleChange("clia_approval")}
                                value="clia_approval"
                                color="primary"
                            />
                        }
                        label="CLIA Approval"
                    />
                </Grid>
                    {showLocField ?
                        <Grid item>
                            {/* <TextField
                                value={role.clia_location}
                                onChange={handleLocationChange}
                                label={"CLIA location"}
                            ></TextField> */}
                            <InputTags tags={locations} setTags={setLocations}/>
                        </Grid> :
                        <span />}
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={role.finalize_request_change}
                                onChange={handleChange("finalize_request_change")}
                                value="finalize_request_change"
                                color="primary"
                            />
                        }
                        label="Finalize Request Change"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={role.major_request_change}
                                onChange={handleChange("major_request_change")}
                                value="major_request_change"
                                color="primary"
                            />
                        }
                        label="Major Request Change"
                    />
                </Grid>
            </Grid>
            <Grid item xs={6} direction="column">
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={role.edit_page_layout}
                                onChange={handleChange("edit_page_layout")}
                                value="edit_page_layout"
                                color="primary"
                            />
                        }
                        label="Edit Page Layout"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={role.request_change}
                                onChange={handleChange("request_change")}
                                value="request_change"
                                color="primary"
                            />
                        }
                        label="Request Change"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={role.synonym_ops}
                                onChange={handleChange("synonym_ops")}
                                value="synonym_ops"
                                color="primary"
                            />
                        }
                        label="Synonym Operations"
                    />  </Grid>
                <Grid item>
                     <FormControlLabel
                        control={
                            <Checkbox
                                checked={role.view_all_lab_tests}
                                onChange={handleChange("view_all_lab_tests")}
                                value="view_all_lab_tests"
                                color="primary"
                            />
                        }
                        label="View All Tests (Including tests which are not finalized)"
                    />  
                </Grid>
            </Grid>
        </Grid>
        <Button color="secondary" variant="contained" onClick={resetState}>Reset Permissions</Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button color="primary" variant="contained" onClick={minimumState}>Minimum Permissions</Button>

        <DialogActions>
                     <Button onClick={handleSubmit} color="primary">
                        {"submit"}
                    </Button>
                    <Button onClick={props.handleClose} color="secondary">
                        {"cancel"}
                    </Button>
                </DialogActions>
    </div>
    )

}

function mapStateToProps(state) {
    return {
       
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPermissionsForm)