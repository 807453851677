import React,{useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';



const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth:"700px"
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));

function InputTextField(props) {
  const classes = useStyles();
  const [value,setValue]= useState(props.defaultValue)
  function handleChange(e){
    setValue(e.target.value)
    props.onChange(props.field,e.target.value)
  }
  return (
    
      <TextField
        id="outlined-bare"
        className={classes.textField}
      onChange={handleChange}
        margin="normal"
        variant="outlined"
        value={value}
		fullWidth={true}
        inputProps={{ 'aria-label': 'bare' }}
      />
  );
}

export default InputTextField;
