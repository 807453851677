import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';


function renderSuggestion(suggestionProps, stopProgress) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.Uname) > -1;
    return (
        <MenuItem
            {...itemProps}
            key={suggestion.Uname}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.Uname + " -- " + suggestion.FirstName + " " + suggestion.LastName}
        </MenuItem>
    );
}
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        // zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    TextField: {
        minWidth: '25vw',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    divider: {
        height: theme.spacing(2),
    },
}));



function IntegrationDownshift(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Downshift id="downshift-simple" selectedItem={props.uname} onChange={props.handleUnameChange} onSelect={props.handleOnUnameSelect}>
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    highlightedIndex,
                    selectedItem,
                }) => {
                    const { onBlur, onFocus, onChange, ...inputProps } = getInputProps({
                        placeholder: 'Start Typing Uname',
                    });

                    return (
                        <div className={classes.container}>
                            <TextField
                                variant="outlined"
                                label="Uname"
                                placeholder="start typing uname"
                                className={classes.TextField}
                                value={typeof props.uname ==="string"?props.uname:""}
                                onChange={props.handleUnameChange}
                                fullWidth
                                error={props.error}
                                helperText={props.error? "This User already exists in Lab Handbook.":""}
                                InputProps={{
                                    inputRef: inputProps.ref,
                                    classes: {
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    },
                                    ...inputProps,
                                }}
                            />

                            <div {...getMenuProps()}>
                                {props.suggestions.length > 0 ? (
                                    <Paper className={classes.paper} component="div" square>
                                        {props.suggestions.map((suggestion, index) =>
                                            renderSuggestion({
                                                suggestion,
                                                index,
                                                itemProps: getItemProps({ item: suggestion }),
                                                highlightedIndex,
                                                selectedItem,
                                            }, props.stopProgress),
                                        )}
                                    </Paper>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            </Downshift>


        </div>
    );
}

export default IntegrationDownshift;
